import dayjs from 'dayjs';
import { MDBBtn } from 'mdb-react-ui-kit';
import { ITransmision } from '../interfaces';
import './../css/style.css';

export const TransmissionDetail: React.FC<{
    transmission: ITransmision,
    onReturn: () => void;
}> = (props) => {
    const { transmission, onReturn } = props;
    const bitacora = JSON.parse(transmission.jsonBitacora!);

    const getRegisterType = (type: string) => {
        const types = {
            "F": "Fijo",
            "V": "Variable",
            "": "No informado",
            'unknown': `${type} - desconocido`
        };

        return (types[type as keyof typeof types] || types["unknown"]);
    }

    const getRegisterFormat = (format: string) => {
        const types = {
            "A": "ASCII",
            "E": "EBCDIC",
            "": "No informado",
            'unknown': format + " - desconocido"
        };

        return (types[format as keyof typeof types] || types["unknown"]);
    }

    const getTraduction = (value: string) => {
        const types = {
            "N": "No",
            "S": "Si",
            "": "No informado",
            'unknown': value + " - desconocido"
        };

        return (types[value as keyof typeof types] || types["unknown"]);
    }

    const WebChannelDetail = (props: any) => {
        const { bitacora } = props;
        return <>
            <div className="title-grilla">
                <h1>Información Archivo CTR</h1>
            </div>
            <div className="row">
                <div className="col">Nombre</div>
                <div className="col file-status">{transmission.archivoCtr}</div>
            </div>
            <div className="row">
                <div className="col">Contenido</div>
                <div className="col">
                    {bitacora.Archivos?.CTR?.Contenido}
                </div>
            </div>
            <div className="row">
                <div className="col">Registros</div>
                <div className="col">{bitacora.Archivos?.CTR?.Registros}</div>
            </div>
            <div className="row">
                <div className="col">Tipo - Registro</div>
                <div className="col">
                    {getRegisterType(bitacora.Archivos?.CTR["Tipo-Registro"] ?? "")}
                </div>
            </div>
            <div className="row">
                <div className="col">Num - Bytes</div>
                <div className="col">{bitacora.Archivos?.CTR["Num-Bytes"]}</div>
            </div>
            <div className="row">
                <div className="col">Flag - Formato</div>
                <div className="col">
                    {getRegisterFormat(bitacora.Archivos?.CTR["Flag-Formato"] ?? "")}
                </div>
            </div>
            <div className="row">
                <div className="col">Flag - Traduccion</div>
                <div className="col">
                    {getTraduction(bitacora.Archivos?.CTR["Flag-Traduccion"] ?? "")}
                </div>
            </div>
        </>
    }

    const SFTPChannelDetail = (props: any) => {
        const { bitacora } = props;
        return <>
            <div className="title-grilla">
                <h1>Información Archivo CTW</h1>
            </div>
            <div className="row">
                <div className="col">Nombre</div>
                <div className="col file-status">{transmission.archivoCtr}</div>
            </div>
            <div className="row">
                <div className="col">Origen</div>
                <div className="col">{bitacora.Archivos?.CTW?.Origen}</div>
            </div>
            <div className="row">
                <div className="col">Destino</div>
                <div className="col">{bitacora.Archivos?.CTW?.Destino}</div>
            </div>
            <div className="row">
                <div className="col">Solicitante</div>
                <div className="col">{bitacora.Archivos?.CTW?.Solicitante}</div>
            </div>
            <div className="row">
                <div className="col">Fecha Solicitud</div>
                <div className="col">{bitacora.Archivos?.CTW["Fecha-Solicitud"]}</div>
            </div>
            <div className="row">
                <div className="col">Observación</div>
                <div className="col">{bitacora.Archivos?.CTW?.Observacion}</div>
            </div>
            <div className="row">
                <div className="col">Autorizador</div>
                <div className="col">{bitacora.Archivos?.CTW?.Autorizador}</div>
            </div>
            <div className="row">
                <div className="col">Fecha Autorización</div>
                <div className="col">{bitacora.Archivos?.CTW["Fecha-Autorizacion"]}</div>
            </div>
        </>
    }

    const fileSize = transmission.tamanoArchivo === undefined
        ? "Sin información"
        : transmission.tamanoArchivo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " bytes";

    const estadoTransmision =
        transmission.estadoTransmision === 0
            ? "exitoso"
            : "fallido";

    return (
        <>
            <div className="title-grilla">
                <h1>Detalle de Transmisión</h1>
            </div>
            <div>
                <div className="row">
                    <div className="col col-details">
                        <div className="row">
                            <div className="col">Canal</div>
                            <div className="col">{transmission.idCanal}</div>
                        </div>
                        <div className="row">
                            <div className="col">Nombre archivo</div>
                            <div className="col file-status">{transmission.archivoDato}</div>
                        </div>
                        <div className="row">
                            <div className="col">Resultado</div>
                            <div className="col">{transmission.glosaEstado}</div>
                        </div>
                        <div className="row">
                            <div className="col">Estado</div>
                            <div className="col file-status">{estadoTransmision.toUpperCase()}</div>
                        </div>
                        <div className="row">
                            <div className="col">Fecha Transmisión</div>
                            <div className="col">
                                {transmission.fechaInicio
                                    ? dayjs(transmission.fechaInicio).format('DD/MM/YYYY HH:mm:ss')
                                    : "Sin información"
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">Tamaño</div>
                            <div className="col">{fileSize}</div>
                        </div>

                        {
                            bitacora.Archivos["CTR"]
                                ? <WebChannelDetail bitacora={bitacora} />
                                : <SFTPChannelDetail bitacora={bitacora} />
                        }

                        <>
                            <div className="title-grilla">
                                <h1>Revisión de Virus</h1>
                            </div>
                            {bitacora.Scan ?
                                <>
                                    <div className="row">
                                        <div className="col">Acción</div>
                                        <div className="col">{bitacora.Scan.Accion || ""}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Fecha inicio</div>
                                        <div className="col">{bitacora.Scan.Inicio || ""}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Fecha fin</div>
                                        <div className="col">{bitacora.Scan.Fin || ""}</div>
                                    </div>
                                </>
                                :
                                <div className="row">
                                    <div className="col">Sin información</div>
                                </div>}

                        </>


                    </div>
                    <div className="col">
                        <table className="table" style={{ width: 710 }}>
                            <thead>
                                <tr>
                                    <th scope="col">Casilla Origen</th>
                                    <th scope="col">Casilla Destino</th>
                                    <th scope="col">Archivo RES</th>
                                    <th scope="col">Archivo AVI</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">{transmission.idCasillaOrigen}</th>
                                    <td>{transmission.idCasillaDestino}</td>
                                    <td>{transmission.archivoRes}</td>
                                    <td>{transmission.archivoAvi}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table" style={{ width: 910 }}>
                            <thead>
                                <tr>
                                    <th scope="col">Fecha Expiración</th>
                                    <th scope="col">Fecha Eliminación</th>
                                    <th scope="col">Glosa Eliminación</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        {transmission.fechaExpiracion
                                            ? dayjs(transmission.fechaExpiracion).format('DD/MM/YYYY HH:mm:ss')
                                            : ""
                                        }
                                    </th>
                                    <td>
                                        {transmission.fechaEliminacion
                                            ? dayjs(transmission.fechaEliminacion).format('DD/MM/YYYY HH:mm:ss')
                                            : ""
                                        }
                                    </td>
                                    <td>{transmission.glosaEliminacion}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <MDBBtn type='button' onClick={onReturn} className='secondary px-5'>
                            Volver
                        </MDBBtn>
                    </div>
                </div>
            </div>
        </>
    );
}