import { useRef, useState } from 'react';

interface DigitInputProps {
    onChange: (value: string) => void;
    digitIndex: number;
}

export const DigitInput = ({ onChange, digitIndex }: DigitInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setTIsFocused] = useState(false);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        onChange(value);
        if (value && inputRef.current) {
            const nextSibling = inputRef.current.nextSibling;
            if (nextSibling instanceof HTMLInputElement) {
                nextSibling.focus();
            }
        }
    };

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key, target } = event;
        const value = (target as HTMLInputElement).value;
        const prevSibling = (target as HTMLInputElement).previousSibling as HTMLInputElement;

        const regExpIsOk = /^\d$|Backspace|Delete|Tab|ArrowLeft|ArrowRight/g.test(key);

        if (!regExpIsOk) {
            event.preventDefault();
        }

        if (key === 'Backspace' && !value && prevSibling) {
            prevSibling.focus();
        }
    };

    return (
        <input
            ref={inputRef}
            type="text"
            id={`input-id-${digitIndex}`}
            maxLength={1}
            onFocus={() => setTIsFocused(true)}
            onBlur={() => setTIsFocused(false)}
            style={{                
                paddingLeft: "0px",
                width: "35px",
                height: "35px",
                textAlign: "center",
                marginRight: "5px",
                fontSize: "16px",
                border: `1px solid ${isFocused ? 'green' : '#ccc'}`,
                borderRadius: "5px",
            }}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
        />
    );
};