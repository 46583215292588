// import './../css/style.css';
import { Container, Grid, Paper } from '@mantine/core';
import 'dayjs/locale/es';
import { MDBBtn, MDBCol, MDBIcon, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { FormInput } from '../components/FormInput';
import Loading from '../components/Loading';
import { ModalConfirm } from '../components/ModalConfirm';
import { IBoxData, IEditUserData, IKeyValueData, IModalConfirm, IUserData, IUserFormInput } from '../interfaces';
import { getStatusEnabled } from '../utils/checkValues';
import { Config } from '../utils/config';
import { PROFILE_REDBANC, createUser, editUser, getBoxsByInstitution, getBoxsByUser, getProfiles } from '../utils/middleware';
import { notify } from '../utils/notifications';
import { useLocalstorageState } from 'rooks';
import { Chip } from '@mui/material';

const AUTHORIZED_BOX_PROFILES = ["CONSULTA", "OPERADOR", "AUTORIZADOR", "SEGURIDAD"];
export const PROHIBITED_WORDS_IN_EMAIL = Config.PROHIBITED_WORDS_IN_EMAIL.split(",").map(word => word.trim());
export const REGEX = new RegExp(`^(?!.*\\b\\w*(?:${PROHIBITED_WORDS_IN_EMAIL.join("|")})\\w*\\b).+$`, "i");

export const AdminUser: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { actionPage } = useParams();
    const [profilesValues, setProfilesValues] = useState<IKeyValueData[]>([]);
    const [boxsValues, setBoxsValues] = useState<IBoxData[]>([]);
    const [user] = useLocalstorageState<IUserData>("user", {});

    const [currentTab, setCurrentTab] = useState("information");
    const [loading, setLoading] = useState(false);

    const state = location.state as { institution: IKeyValueData, userData: IEditUserData };
    const [userData, setUserData] = useState<IEditUserData>(state?.userData);

    const [userBoxs, setUserBoxs] = useState<IBoxData[]>([]);
    const [modalConfirm, setModalConfirm] = useState<IModalConfirm | null>();

    const institution = state?.institution;

    const { handleSubmit, errors, formState: { isSubmitting }, control, reset, watch } = useForm({
        defaultValues: {
            name: userData?.nombre || "",
            userName: userData?.userName || "",
            correo: userData?.correo || "",
            profile: userData?.perfil || "",
            casillas: userData?.casillas || []
        }
    });

    const onSubmit = async (formData: IUserFormInput) => {
        if (formData !== null) {
            const profile = formData!.profile;
            const correo = formData!.correo;
            const name = formData!.name;
            const userName = actionPage === "editar" ? userData?.userName : formData!.userName;
            const jsonCasillas = { "casillas": formData?.casillas?.map((box: string, index: number) => ({ "id_casilla": box, "default": index === 0 })) };

            let response;
            if (actionPage === "editar")
                response = await editUser({ ...formData, userName: userName }, jsonCasillas);
            else
                response = await createUser(formData, institution.id, jsonCasillas);


            if (response.isOk) {
                if (actionPage === "crear") {
                    notify({
                        message: <>Usuario <b>{userName}</b> creado exitosamente.</>,
                        autoClose: 5000
                    })
                    const newUser = {
                        perfil: profile,
                        correo,
                        nombre: name,
                        userName,
                        casillas: JSON.stringify(formData?.casillas?.map((box: string, index: number) => ({ "idCasilla": box, "default": index === 0 }))),
                        habilitado: -1 // Pendiente
                    };
                    setUserData(newUser);

                    navigate(
                        "/admin/usuarios/editar",
                        { state: { "institution": institution, "userData": newUser } }
                    )
                }
                else {

                    let response = await getBoxsByUser(userData.userName);
                    if (response === null) {
                        notify({
                            message: <>Usuario <b>{userName}</b> no se ha podido modificar.</>
                        })
                    } else {
                        const userBoxs = response.isOk && response?.responseData && JSON.parse(response.responseData);

                        const editData = {
                            ...userData,
                            nombre: name,
                            perfil: profile,
                            correo,
                            casillas: JSON.stringify(userBoxs)
                        };
                        setUserData(editData);
                        notify({
                            message: <>Usuario <b>{userName}</b> actualizado exitosamente.</>
                        })
                    }

                }
            }
            else {
                if (!response.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: response.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
    };

    const getProfilesData = useCallback(async () => {
        setLoading(true);
        const response = await getProfiles();
        if (response.isOk) {
            const profileList = response.responseData ? JSON.parse(response.responseData) : [];
            const profiles = profileList.map((profile: IKeyValueData) => ({ id: profile.nombre, nombre: profile.nombre }));
            setProfilesValues(profiles);
        }
        else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        setLoading(false);

    }, [])

    const getBoxsValuesData = useCallback(async () => {
        const responseBoxs = await getBoxsByInstitution(institution?.id);
        if (responseBoxs === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (responseBoxs.isOk) {
                const boxsValues = responseBoxs?.responseData ? JSON.parse(responseBoxs?.responseData) : [];
                const filterBoxValues = boxsValues.filter((box: IBoxData) => !userBoxs?.some((element: IBoxData) => element.idCasilla === box.idCasilla));
                setBoxsValues(filterBoxValues);
            } else {
                if (!responseBoxs.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: responseBoxs.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }

    }, [userBoxs, institution])

    const goToBack = () => {
        navigate(
            user.profile! !== PROFILE_REDBANC
                ? "/admin/usuarios"
                : "/consulta/usuarios"
            , { state: { "institution": institution } });
    }

    const FormRowValue = (props: { label: string, value: string | [], customClass?: string }) => {
        return <div className="row" style={{ padding: "5px 0px" }}>
            <div className="col">{props.label}</div>
            <div className={`col ${props.customClass || ""} `}>
                {Array.isArray(props.value)
                    ? props.value.map((val: string) => {
                        return <span key={val}><Chip size='small' sx={{ marginY: "5px", marginRight: "5px" }} label={val} /></span>
                    })
                    :
                    props.value

                }
            </div>
        </div>
    }

    const getUserBoxs = async () => {
        const response = await getBoxsByUser(userData.userName);
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                setUserBoxs(response?.responseData ? JSON.parse(response?.responseData) : []);

            } else {
                if (!response.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: response.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
        //RMM
        return { isOk: true }
    }

    const handleSelectTab = async (tabName: string) => {
        if (tabName === "authorizedBoxes") {
            if (userData.habilitado === -1) {
                notify({
                    title: "No puede administrar casillas",
                    message: <>Esta sección estará disponible cuando el usuario se encuentre <b>habilitado</b>.</>,
                    notificationType: "warning"
                })
                return;
            }
            else {
                setLoading(true);
                await getUserBoxs();

            }
        }
        setCurrentTab(tabName);
        setLoading(false);

    }

    useEffect(() => {
        if (!institution)
            navigate("/admin/usuarios", { state: null });
        else
            getProfilesData();
    }, [navigate, institution, getProfilesData])


    useEffect(() => {
        if (userData) {
            const boxsObj = userData?.casillas && JSON.parse(userData?.casillas);
            const boxs = boxsObj?.length ? boxsObj.sort((a: any, b: any) => Number(b.defaultCasilla) - Number(a.defaultCasilla)).map((box: any) => (box.idCasilla)) : null;

            reset({
                name: userData?.nombre,
                correo: userData?.correo,
                profile: userData?.perfil,
                userName: userData?.userName,
                casillas: boxs
            })
        }

    }, [reset, userData]);

    useEffect(() => {
        // Hook que se llama cada vez que se actualiza la data del usuario 
        // por ejemplo al agregar una casilla
        getBoxsValuesData();
    }, [userData, getBoxsValuesData])

    const FormButtons = (props: { isSubmitting?: boolean, onlyBack?: boolean }) =>
        <MDBRow className='my-5 d-flex align-items' >
            {!props!.onlyBack &&
                <MDBCol size='6' style={{ textAlign: 'end' }}>
                    <MDBBtn
                        color='success'
                        style={{ display: 'inline' }}
                        className='px-5 btn-prim-download'
                        rounded
                        type='submit'
                        disabled={props!.isSubmitting}>
                        {props!.isSubmitting ? <Loading color='#fff' size='sm' /> : <><MDBIcon fas icon="check" /> Aceptar</>}
                    </MDBBtn>


                </MDBCol>
            }
            <MDBCol size='6' style={{ textAlign: props.onlyBack ? 'end' : 'start' }}>
                <MDBBtn onClick={goToBack}
                    className='px-5'
                    color='success'
                    outline
                    type="button"
                >
                    <MDBIcon fas icon="arrow-left" className='px-1' />
                    Volver
                </MDBBtn>
            </MDBCol>
        </MDBRow>



    return (
        <div className="bg-white">
            <div className="container-fluid">
                <div className="grilla-content">
                    <div className="title-grilla">
                        {actionPage === "crear"
                            ? <>
                                <h1>Crear Usuario</h1>
                                <Container size="sm">
                                    <Paper withBorder className='info-header' p="lg" radius="lg">
                                        <Grid>
                                            <Grid.Col xs={2}><b>Institución</b></Grid.Col>
                                            <Grid.Col xs={8}>{institution.nombre}</Grid.Col>
                                        </Grid>
                                    </Paper>
                                </Container>
                            </>
                            : <>
                                <h1>Editar Usuario</h1>
                                <Container>
                                    <Paper withBorder className='info-header' p="lg" radius="lg">
                                        <Grid>
                                            <Grid.Col xs={2}><b>Username</b></Grid.Col>
                                            <Grid.Col xs={8}>{userData.userName}</Grid.Col>
                                        </Grid><Grid>
                                            <Grid.Col xs={2}><b>Institución</b></Grid.Col>
                                            <Grid.Col xs={8}>{institution.nombre}</Grid.Col>
                                        </Grid>
                                    </Paper>
                                </Container>

                            </>}
                    </div>

                    {modalConfirm &&
                        <ModalConfirm
                            title={modalConfirm.title}
                            body={modalConfirm.body}
                            showConfirm={modalConfirm.show}
                            onClose={() => setModalConfirm(null)}
                            onConfirm={modalConfirm.onConfirm}
                        />
                    }
                    <MDBTabs className='mb-3'>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleSelectTab('information')} active={currentTab === 'information'}>
                                Información
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>
                    {loading ? <Loading /> :
                        <MDBTabsContent>
                            <MDBTabsPane show={currentTab === 'information'}>
                                {user.profile! === PROFILE_REDBANC
                                    ?
                                    <MDBTabsContent className='mt-3'>
                                        <MDBRow className='my-5 d-flex align-items' >
                                            <MDBCol size='8'>
                                                <div className="row">
                                                    <div className="col col-details">
                                                        <FormRowValue label="Email" value={userData?.correo} />
                                                        <FormRowValue label="Nombre" value={userData?.nombre} />
                                                        <FormRowValue label="Estado" value={getStatusEnabled(userData?.habilitado?.toString() || "-1")} />
                                                        {userData?.fechaActivacion && <FormRowValue label="Fecha Activación" value={userData.fechaActivacion} />}
                                                        {userData?.fechaDesactivacion && userData.habilitado === 0 && <FormRowValue label="Fecha Desactivación" value={userData.fechaDesactivacion} />}
                                                        <FormRowValue label="Perfil" value={userData?.perfil} />
                                                        <FormRowValue label="Casillas autorizadas" value={watch('casillas')} />
                                                    </div>
                                                </div>

                                            </MDBCol>
                                        </MDBRow>
                                        <FormButtons onlyBack />
                                    </MDBTabsContent>
                                    : <form onSubmit={handleSubmit(onSubmit)}>
                                        <MDBTabsContent className='mt-3'>
                                            <MDBRow className='my-5 d-flex align-items' >
                                                <MDBCol size='8'>
                                                    <div className="row">
                                                        <div className="col col-details">
                                                            <FormInput
                                                                errors={errors}
                                                                control={control}
                                                                name='correo'
                                                                message='Email es requerido'
                                                                label='Email'
                                                                rules={{
                                                                    pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                        message: "Email es inválido"
                                                                    },
                                                                    validate: {
                                                                        words: (value) => REGEX.test(value) || 'Existen palabras no permitidas'
                                                                    }

                                                                }}
                                                            />

                                                            <FormInput
                                                                errors={errors}
                                                                control={control}
                                                                name='name'
                                                                message='Nombre es requerido'
                                                                label='Nombre'
                                                                maxLength={100}
                                                                rules={{
                                                                    validate: {
                                                                        max: (value) => /^.{0,100}$/.test(value) || 'Deben ser máximo 100 caracteres',
                                                                        min: (value) => /^.{8,}$/.test(value) || 'Deben ser mínimo 8 caracteres'
                                                                    }
                                                                }}
                                                            />

                                                            {actionPage === "crear" &&
                                                                <FormInput
                                                                    errors={errors}
                                                                    control={control}
                                                                    name='userName'
                                                                    message='Username es requerido'
                                                                    label='Username'
                                                                    maxLength={32}
                                                                    rules={{
                                                                        validate: {
                                                                            max: (value) => /^.{0,32}$/.test(value) || 'Deben ser máximo 32 caracteres',
                                                                            min: (value) => /^.{8,}$/.test(value) || 'Deben ser mínimo 8 caracteres',
                                                                            f3: (value) => /[a-zA-Z]/.test(value) || 'Debe incluir una letra',
                                                                            numberAndText: (value) => /^[a-zA-Z0-9]+$/.test(value) || 'Debe incluir solo números y letras',
                                                                        }
                                                                    }}
                                                                />
                                                            }


                                                            {actionPage === "editar" &&
                                                                <>
                                                                    <FormRowValue label="Estado" value={getStatusEnabled(userData?.habilitado?.toString() || "-1")} />
                                                                    {userData?.fechaActivacion && <FormRowValue label="Fecha Activación" value={userData.fechaActivacion} />}
                                                                    {userData?.fechaDesactivacion && userData.habilitado === 0 && <FormRowValue label="Fecha Desactivación" value={userData.fechaDesactivacion} />}
                                                                </>
                                                            }

                                                            <FormInput
                                                                errors={errors}
                                                                control={control}
                                                                name='profile'
                                                                message='Perfil es requerido'
                                                                label='Perfil'
                                                                type='select'
                                                                listValues={profilesValues}
                                                            />
                                                            {AUTHORIZED_BOX_PROFILES.includes(watch('profile')) &&
                                                                <FormInput
                                                                    errors={errors}
                                                                    control={control}
                                                                    name='casillas'
                                                                    label='Casillas autorizadas'
                                                                    type='multiple'
                                                                    placeholder="Seleccione una casilla"
                                                                    rules={{
                                                                        validate: (value) => {
                                                                            return (!value || value?.length < 1) ? "Debe agregar al menos una casilla" : true;
                                                                        }
                                                                    }}
                                                                    listValues={
                                                                        boxsValues &&
                                                                        boxsValues
                                                                            .filter((box: IBoxData) => box.tipoCasilla === 'S')
                                                                            .map((box: IBoxData) => ({ id: box.idCasilla, nombre: box.idCasilla }))
                                                                    }
                                                                />

                                                            }

                                                        </div>
                                                    </div>

                                                </MDBCol>
                                            </MDBRow>
                                            <FormButtons isSubmitting={isSubmitting} />
                                        </MDBTabsContent>
                                    </form>
                                }
                            </MDBTabsPane>
                        </MDBTabsContent>
                    }
                </div>
            </div>
        </div>
    );
}