import {
    MDBBtn,
    MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader,
    MDBModalTitle,
    MDBPopover,
    MDBPopoverBody,
    MDBPopoverHeader,
    MDBRow,
    MDBTabsContent
} from 'mdb-react-ui-kit';
import { ReactElement, useState } from 'react';
import Loading from './Loading';
import { Typography } from '@mui/material';
import { generateToken } from '../utils/middleware';
import { notify } from '../utils/notifications';

export const ModalFormToken: React.FC<{
    title?: ReactElement | string,
    data?: any,
    showConfirm: boolean,
    subTitle?: ReactElement | string,
    onClose: () => void;
    onSubmit: () => void;
}> = (props) => {
    const { onClose, onSubmit, title, showConfirm, data } = props;
    const [loadingToken, setLoadingToken] = useState(false);
    const [token, setToken] = useState(JSON.parse(data.datos_casilla.toString())?.tokenAcceso || "");

    const regenerateToken = async (idCasilla: string) => {
        setLoadingToken(true)
        const response = await generateToken(idCasilla);
        if (response.isOk) {
            notify({
                message: <>Token actualizado correctamente</>
            });

            setToken(response.responseData);

        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        setLoadingToken(false);
        onSubmit();
        
        //RMM
        return { isOk: true }
    }

    return (
        <>
            <MDBModal show={showConfirm} staticBackdrop>
                <MDBModalDialog size='xl'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                {title}
                            </MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody className="text-center mt-1">
                            <MDBTabsContent className='mt-1'>
                                <MDBRow className='d-flex align-items'>
                                    <MDBCol size={12}>
                                        <Typography variant="subtitle2" component="span">
                                            {token}
                                        </Typography>
                                    </MDBCol>
                                </MDBRow>

                            </MDBTabsContent>

                        </MDBModalBody>

                        <MDBModalFooter style={{ justifyContent: 'center' }}>
                            {loadingToken
                                ? <div className='d-flex align-items'>
                                    <Loading size='sm' color='#18BA95' />
                                </div>
                                :
                                <MDBPopover id='btn-popover' placement='left-start' style={{ color: 'white', background: '#18BA95' }} btnChildren={<><MDBIcon fas icon="redo" />{' '}Nuevo Token</>}>
                                    <MDBPopoverHeader>Generar nuevo token</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                        <MDBRow>
                                            <MDBCol size='12' className='mb-3'>
                                                Recuerde que al actualizar el token debe actualizar los programas que se conectan con el sti-plus a través de las APIS
                                                <Typography variant="subtitle2" className='text-center pt-2'>
                                                    ¿Desea generar un nuevo token?
                                                </Typography>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className='text-center'>
                                            <MDBCol size='12' style={{ marginBottom: 0 }}>
                                                <MDBBtn
                                                    className='mx-1'
                                                    size='sm'
                                                    color='success'
                                                    onClick={async () => await regenerateToken(data.idCasilla)}
                                                >Aceptar
                                                </MDBBtn>
                                                <MDBBtn
                                                    className='mx-1'
                                                    color='white'
                                                    size='sm'
                                                    onClick={() => document.getElementById('btn-popover')?.click()}
                                                >
                                                    Cancelar
                                                </MDBBtn>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBPopoverBody>
                                </MDBPopover>
                            }
                            <MDBBtn color='white' onClick={onClose}>
                                Cerrar
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal >
        </>
    );
}

