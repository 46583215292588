import { Logout } from '@mui/icons-material';
import LockResetIcon from '@mui/icons-material/LockReset';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { Chip, Divider, ListItemAvatar, ListItemIcon, MenuList, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLocalstorageState } from "rooks";
import { IUserData } from '../interfaces';
import { IPage, logout } from '../utils/middleware';
import greenImage from './../img/logo-header.svg';

const Header: React.FC<{ userAuthorizedPages: IPage[] }> = (props) => {
    const navigate = useNavigate();
    const { userAuthorizedPages } = props;
    const [user, setUser] = useLocalstorageState<IUserData>("user", {});
    const mailBoxs = JSON.parse(user?.mailBoxs!);
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [anchorBoxEl, setAnchorBoxEl] = useState<null | HTMLElement>(null);
    const openBox = Boolean(anchorBoxEl);

    const handleClickBox = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorBoxEl(event.currentTarget);
    };
    const handleCloseBox = () => {
        setAnchorBoxEl(null);
    };

    const handleCurrentBox = (selectedBox: string) => {
        setUser({ ...user, csll: selectedBox });
        handleCloseBox();
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout();
        window.location.replace("/");
    };

    const handleGotoResetPasswordPage = () => {
        navigate('/update-user-password');
    };


    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <img src={greenImage} alt="logo" />
                    </Box>
                    <Box sx={{ flexGrow: 6, display: 'flex' }}>
                        {userAuthorizedPages && userAuthorizedPages.map((page, key) => (
                            <Box
                                key={key}
                                sx={{
                                    '& a.active > button': {
                                        bgcolor: theme.palette.action.active,
                                    },
                                }}>
                                <NavLink to={`/${page.pag}`}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            m: 2,
                                            color: 'white',
                                            display: 'block',
                                            boxShadow: 'none',
                                        }}
                                    >
                                        {page.titulo}
                                    </Button>
                                </NavLink>
                            </Box>

                        ))}
                    </Box>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorBoxEl}
                        open={openBox}
                        onClose={handleCloseBox}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {[...mailBoxs].map((box: string, key) => {
                            return box !== user?.csll && (
                                <MenuItem
                                    key={key}
                                    onClick={() => handleCurrentBox(box)}

                                >
                                    <MailOutlineIcon color='primary' className='mx-2' />
                                    {box}
                                </MenuItem>
                            )
                        })}
                    </Menu>
                    {userAuthorizedPages[0].muestraMenuCasilla &&
                        <Box sx={{ flexGrow: 1, display: 'flex', color: 'white' }}>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClickBox}
                            >
                                <Chip
                                    label={user?.csll}
                                    sx={{
                                        bgcolor: theme.palette.common.white,
                                        px: 2,
                                        "&:hover": {
                                            color: theme.palette.common.white,
                                            border: `1px solid ${theme.palette.common.white}`
                                        }
                                    }}
                                    color='primary'
                                    onClick={() => { }}
                                    icon={<MarkEmailReadIcon />}
                                    variant="outlined"
                                />
                            </Button>
                        </Box>
                    }
                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{
                                color: theme.palette.primary.dark,
                                bgcolor: theme.palette.common.white,
                                border: `2px solid ${theme.palette.primary.dark}`
                            }}>{user?.name?.charAt(0)}
                            </Avatar>
                        </IconButton>

                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuList sx={{ px: 3 }}>
                            <Stack direction="row" alignItems="center">
                                <ListItemAvatar>
                                    <AccountCircleIcon sx={{ fontSize: 45 }} htmlColor={theme.palette.primary.main} />
                                </ListItemAvatar>
                                <Stack sx={{ mr: 5, ml: 2 }}>
                                    <Chip label={user.profile?.toUpperCase()} variant="outlined" color="primary" size='small' />
                                    <Typography variant="h6">{user.name}</Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {user.username}
                                    </Typography>
                                    {/* <Typography variant="caption" color="textSecondary">
                                                    Ult. conexión: 18/09/2022 18:23
                                                </Typography> */}
                                </Stack>
                            </Stack>

                        </MenuList>
                        <Divider component="li" />
                        <MenuItem onClick={handleGotoResetPasswordPage}>
                            <ListItemIcon>
                                <LockResetIcon fontSize="small" htmlColor={theme.palette.primary.main} />
                            </ListItemIcon>
                            Actualizar Contraseña
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout fontSize="small" htmlColor={theme.palette.primary.main} />
                            </ListItemIcon>
                            Cerrar sesión
                        </MenuItem>
                    </Menu>


                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header;