import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CardLogoSti from '../components/CardLogoSti';
import Loading from '../components/Loading';
import { validateTempToken, unlockUser } from '../utils/middleware';
import './../css/style.css';

const UnlockUser: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const userToken = searchParams.get('_tkn');
    const userName = searchParams.get('_ce');

    const [loading, setLoading] = useState<boolean>(true);
    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);


    const goToLogin = () => {
        navigate('/');
    }

    useEffect(() => {
        (async () => {
            if (!userToken || !userName)
                setShowError(true);
            else {
                const isValidToken = await validateTempToken(userName, userToken);

                if (!isValidToken.isOk)
                    setShowError(!isValidToken.isOk);
                else {
                    const response = await unlockUser(userName!, userToken!);
                    if (response.isOk) setShowSuccess(response.isOk);
                    else setShowError(true);
                }
            }
            setLoading(false);
        })();
    }, [userToken, userName])


    return (
        <div className='bg-degree1'>
            <div className="login-content">
                <div className="block bg-white">
                    {loading
                        ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Loading />
                        </div>

                        : showError
                            ? <div className="title">
                                <MDBIcon fas icon="user-lock" size='5x' className='py-5' />
                                <p />
                                No pudimos validar tu acceso, debes ponerte en contacto con el Administrador.
                            </div>
                            :
                            showSuccess
                                ? <>
                                    <div className="title">
                                        <MDBIcon fas icon="check" size='5x' className='py-3' />
                                        <p />
                                        ¡Usuario ha sido desbloqueado con éxito!

                                        <div className="content-int" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <MDBBtn color='none' onClick={goToLogin} className='btn-prim-index px-5'>
                                                Iniciar Sesión
                                            </MDBBtn>
                                        </div>
                                        {/* Boton para hacer login */}
                                    </div>
                                </>
                                :
                                <div className="title">
                                    <MDBIcon fas icon="user-lock" size='5x' className='py-5' />
                                    <p />
                                    No pudimos validar tu acceso, debes ponerte en contacto con el Administrador.
                                </div>
                    }
                </div>
                <CardLogoSti />
            </div>
        </div>
    );
}

export default UnlockUser;