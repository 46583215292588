import { MDBBtn, MDBCol } from 'mdb-react-ui-kit';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useLocalstorageState } from 'rooks';
import CardLogoSti from '../components/CardLogoSti';
import InputGoogleAuthenticator from '../components/InputGoogleAutheticator';
import Loading from '../components/Loading';
import { IUserData } from '../interfaces';
import { checkGoogleAuthCode, getUserAuthorizedPages } from '../utils/middleware';
import { notify } from '../utils/notifications';
import './../css/style.css';
import { useLocation } from 'react-router';

type FormInput = {
    code: string[];
};

interface IMailBox {
    idCasilla: string;
    username: string;
    habilitado: boolean;
};


const LoginAuthenticator: React.FC = () => {
    const { handleSubmit, errors, formState, setValue, control } = useForm<FormInput>({
        defaultValues: {
            code: []
        }
    });
    const [user, setUser] = useLocalstorageState<IUserData>("user", {});
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const location = useLocation();

    const onSubmit = async (data: FormInput) => {
        const code = data.code.join("");
        const pass = location.state?.pass;

        const response = await checkGoogleAuthCode(user.username!, code, pass)

        if (response.isOk) {
            const data = JSON.parse(response.responseData);
            const userAuthorizedPages = data[2];            

            if (!userAuthorizedPages.length) {
                notify({
                    title: "Ha ocurrido un error",
                    message: "No existen páginas configuradas para su perfil",
                    notificationType: "error"
                });

                goToHome();
                return;
            }


            const userData = data[0];
            const mailBoxs = data[3];
            const sesionData = data[4];
            const mailBoxsEnabled = mailBoxs?.length ? mailBoxs.sort((a: any, b: any) => Number(b.defaultCasilla) - Number(a.defaultCasilla)).map((mailBox: IMailBox) => mailBox.habilitado === true && mailBox.idCasilla) : [];
            const name = userData.nombre;

            setUser({
                username: userData.userName,
                userAuthorizedPages,
                token: sesionData.token,
                csll: mailBoxsEnabled[0],
                mailBoxs: JSON.stringify(mailBoxsEnabled),
                name,
                password: userData.passwd,
                profile: userData.perfil
            })
        }
        else {
            if (response.responseStatus === 510) {
                navigate("/");
                return;
            }
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
    };

    const goToHome = () => {
        localStorage.clear();
        navigate('/', { replace: true })
    }

    useEffect(() => {
        const pass = location.state?.pass
        if(!pass){
            setUser({})
            navigate('/');
        }        

        const pages = getUserAuthorizedPages(user);
        const path = pages?.length ? `/${pages[0].pag}` : "/";

        if(pass && path === '/login') return

        navigate(path);
    }, [location.state, navigate, setUser, user])

    return (

        <div className='bg-degree1'>
            <div className="login-content">
                <div className="block bg-white">
                    <div className="title pb-3">
                        <h1>Bienvenido</h1>
                        <h6>{user?.username}</h6>
                    </div>
                    <div className="form-content">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-content">
                                <label className="txt-bajada">
                                    Solamente falta una comprobación más.
                                </label>
                                <label className="txt-bajada">
                                    Ahora debes ingresar el código que se encuentra dentro de tu aplicación.
                                </label>
                            </div>

                            <MDBCol size='12' className="col-details" >
                                <div className='row d-flex align-items' style={{ alignItems: 'baseline' }}>
                                    <MDBCol size='12' className='pt-3'>
                                        <InputGoogleAuthenticator
                                            setValue={setValue}
                                            errors={errors}
                                            control={control} />
                                    </MDBCol>
                                </div>
                            </MDBCol>

                            <div className="content-int" style={{ display: 'flex', justifyContent: 'center' }}>
                                <MDBBtn color='none' type='submit' className='btn-prim-index px-5' disabled={isSubmitting}>
                                    {isSubmitting ? <Loading color='#fff' size='sm' /> : <>Ingresar</>}
                                </MDBBtn>

                                {/* {isSubmitting ?
                                    <div className='pt-3'><Loading /></div>
                                    :
                                    <MDBBtn color='none' type='submit' disabled={isSubmitting} className='btn-prim-index px-5'>
                                        Ingresar
                                    </MDBBtn>
                                } */}
                            </div>
                            <div className="input-content mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <MDBBtn onClick={goToHome} color='none' tag={'a'} className='forget'>
                                    Volver
                                </MDBBtn>
                            </div>
                        </form>
                    </div>
                </div>

                <CardLogoSti />
            </div>
        </div>
    );
}

export default LoginAuthenticator;