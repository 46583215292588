import logo from './../img/logo-STI-1.svg';

const CardLogoSti: React.FC = () => {

    return (
        <div className="block bg-green-degree">
            <div className="input-content" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={logo} alt={"logo"} />
            </div>
        </div>
    )
}


export default CardLogoSti;