// import './../css/style.css';
import { DatePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { MDBBtn, MDBCol, MDBDropdown, MDBDropdownToggle, MDBIcon, MDBRow, MDBTabsContent } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Loading from '../components/Loading';
import Paginator from '../components/Paginator';
import { usePagination } from '../hooks/usePagination';
import { getPricing } from '../utils/middleware';
import { notify } from '../utils/notifications';

const pricingHeader = [
    "Casilla origen",
    "Casilla Destino",
    "Totales",
    "Migración Origen",
    "Migración Destino"
];

const INIT_COUNT_PER_PAGE = 100;

export const Pricing: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [pricingData, setPricingData] = useState([]);
    const [startDate, setStartDate] = useState<Date | null>(dayjs().startOf('month').toDate());
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [countPerPage, setCountPerPage] = useState<number>(INIT_COUNT_PER_PAGE);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [dataPaginate, setDataPaginate] = useState([]);


    const getPricingData = async (startDate: Date, endDate: Date) => {
        setLoading(true);
        const pricingData = await getPricing(startDate, endDate);
        if (pricingData === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (pricingData.isOk) {
                setPricingData(pricingData?.responseData ? JSON.parse(pricingData?.responseData) : []);
            }
            else {
                if (!pricingData.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: pricingData.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
        setLoading(false);
        //RMM
        return { isOk: true }
    }


    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(pricingData.length / countPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginationRange = usePagination({
        currentPage,
        totalCount: pricingData.length,
        pageSize: countPerPage
    });

    const handleStartDate = (date: Date) => {
        setStartDate(date);
        if (endDate && (endDate < date))
            setEndDate(null);
    }

    const handleSearch = async () => {
        if (!startDate || !endDate)
            return;

        const retorno = await getPricingData(startDate, endDate);
        if (retorno === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
    }

    useEffect(() => {
        const indexOfLastData = currentPage * countPerPage;
        const indexOfFirstData = indexOfLastData - countPerPage;

        if (indexOfFirstData > pricingData.length) {
            setDataPaginate(pricingData);
            setCurrentPage(1);
        }
        else {
            const currentData = pricingData.slice(indexOfFirstData, indexOfLastData);
            setDataPaginate(currentData);
        }

    }, [currentPage, pricingData, countPerPage, setCurrentPage])

    const DatePickerPricing = (props: { id: string, value: Date, onChange: (date: Date) => void }) => {
        return <DatePicker
            minDate={props.id === "endDate" && startDate ? dayjs(startDate).toDate() : undefined}
            inputFormat="DD/MM/YYYY"
            labelFormat="DD/MM/YYYY"
            value={props.value}
            onChange={props.onChange}
            icon={<MDBIcon far icon="calendar-alt" />}
            locale="es"
            styles={() => ({
                day: { width: "38px !important" },
            })}
            label={props.id === "endDate" ? "Fecha hasta" : "Fecha desde"} />
    }

    return (
        <div className="bg-white">
            <div className="container-fluid">
                {/* <Header /> */}
                {
                    <div className="grilla-content">
                        <div className="title-grilla">
                            <h1>Consulta de Transmisiones</h1>
                        </div>

                        <MDBRow className='my-5 d-flex align-items-end' >
                            <MDBCol size='2'>
                                <DatePickerPricing
                                    id="startDate"
                                    onChange={handleStartDate}
                                    value={startDate!} />
                            </MDBCol>
                            <MDBCol size='2'>
                                <DatePickerPricing
                                    id="endDate"
                                    onChange={setEndDate}
                                    value={endDate!} />
                            </MDBCol>
                            <MDBCol size='2'>
                                <MDBBtn color='white' className='px-3' onClick={handleSearch} disabled={!startDate || !endDate}>
                                    Buscar <MDBIcon fas icon="search" />
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>


                        <div className="text-center">
                            {loading ? <Loading /> :
                                <MDBTabsContent className='mt-3'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {pricingHeader.map((header, key) => <th key={key} scope="col">{header}</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataPaginate.length > 0 ?
                                                    dataPaginate.map((pricing: any, index: number) => {
                                                        const {
                                                            casillaOrigen,
                                                            casillaDestino,
                                                            totales,
                                                            migracionOrigen,
                                                            migracionDestino
                                                        } = pricing;

                                                        return <tr key={index}>
                                                            <th scope="row">{casillaOrigen}</th>
                                                            <td>{casillaDestino}</td>
                                                            <td>{totales}</td>
                                                            <td>{migracionOrigen}</td>
                                                            <td>{migracionDestino}</td>
                                                        </tr>
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <div className="text-center mt-5">Sin información</div>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>

                                    <MDBRow className='my-5 d-flex align-items' >
                                        <MDBCol size='10'>
                                            {
                                                pricingData.length > 0 &&
                                                dataPaginate && <Paginator
                                                    countPerPageOptions={[100, 200, 300, 500]}
                                                    onChangeCounterPerPage={setCountPerPage}
                                                    onChangePage={setCurrentPage}
                                                    currentPage={currentPage}
                                                    pageNumbers={pageNumbers}
                                                    countPerPage={countPerPage}
                                                    allData={pricingData}
                                                    paginationRange={paginationRange!} />
                                            }
                                        </MDBCol>


                                        {pricingData.length > 0 &&
                                            <MDBCol size='2'>
                                                <div className="paginador">
                                                    <nav>
                                                        <ul className="pagination">
                                                            <MDBDropdown style={{ background: 'none' }} className="dropdown-remove-arrow">
                                                                <MDBDropdownToggle className="px-3 " style={{ minWidth: "max-content" }} color='none'>
                                                                    <CsvDownloader
                                                                        filename="facturacion_sti"
                                                                        extension=".csv"
                                                                        separator=";"
                                                                        columns={pricingHeader.map((header, index) => ({ id: Object.keys(pricingData[0])[index], displayName: header }))}
                                                                        datas={pricingData}>
                                                                        <span className='px-1'>Descargar</span> <MDBIcon size='1x' fas icon="file-csv" />
                                                                    </CsvDownloader>
                                                                </MDBDropdownToggle>
                                                            </MDBDropdown>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </MDBCol>
                                        }
                                    </MDBRow>
                                </MDBTabsContent>
                            }
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}