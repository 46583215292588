export const getBoxStatus = (statusCode: number) => {
    const status = {
        0: "Inactiva",
        1: "Activa",
        "": "No informado",
        'unknown': statusCode + " - desconocido"
    };

    return (status[statusCode as keyof typeof status] || status["unknown"]);
}

export const getBoxType = (type: string) => {
    const types = {
        "S": "Simple",
        "G": "Grupo",
        "": "No informado",
        'unknown': type + " - desconocido"
    };

    return (types[type as keyof typeof types] || types["unknown"]);

}
export const getYesOrNoValue = (value: string | undefined) => {
    if (value === undefined) return "";
    const types = {
        "1": "Si",
        "0": "No",
        'unknown': value + " - desconocido"
    };

    return (types[value as keyof typeof types] || types["unknown"]);

}

export const getRegisterFormat = (format: string) => {
    const types = {
        "A": "ASCII",
        "E": "EBCDIC",
        "": "No informado",
        'unknown': format + " - desconocido"
    };

    return (types[format as keyof typeof types] || types["unknown"]);

}

export const getDelimiterInfo = (delimiter: string) => {
    const types = {
        ".": ". (punto)",
        "-": "- (guión medio)",
        "_": "_ (guión bajo)",
        "": "No informado",
        'unknown': delimiter + " - desconocido"
    };

    return (types[delimiter as keyof typeof types] || types["unknown"]);

}

export const getMigrateStatus = (format: string) => {
    const types = {
        "S": "Migrada",
        "N": "No Migrada",
        "-": "Nueva",
        'unknown': format + " - desconocido"
    };

    return (types[format as keyof typeof types] || types["unknown"]);

}

export const getStatusEnabled = (status: string) => {
    const types = {
        "-1": "Pendiente",
        "0": "No Habilitado",
        "1": "Habilitado",
        'unknown': status + " - desconocido"
    };

    return (types[status as keyof typeof types] || types["unknown"]);

}