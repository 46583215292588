import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CardLogoSti from '../components/CardLogoSti';
import { FormInput, PASSWORD_RULES } from '../components/FormInput';
import InputGoogleAuthenticator from '../components/InputGoogleAutheticator';
import Loading from '../components/Loading';
import { createPasswordRules, REGEX, validateUserOnText } from '../utils/functions';
import { validateTempToken, recoveryUserPassword } from '../utils/middleware';
import { notify } from '../utils/notifications';
import './../css/style.css';

type FormInputData = {
    password: string;
    passwordConfirm: string;
    code: string[];
};

const RecoveryPassword: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const userToken = searchParams.get('_tkn');
    const userName = searchParams.get('_ce');

    const [loading, setLoading] = useState<boolean>(true);
    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const { handleSubmit, errors, formState: { isSubmitting }, control, watch, setValue } = useForm({
        defaultValues: {
            password: "",
            passwordConfirm: "",
            code: []
        }
    });

    const onSubmit = async (data: FormInputData) => {
        const codeGA = data.code.join("");

        const response = await recoveryUserPassword(userName!, userToken!, data.password, codeGA);
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede realizar la operación",
                notificationType: "error"
            })
        } else {
			if (!response.isOk) {
				if (!response.notify)
					notify({
						title: "Ha ocurrido un error",
						message: response?.errorMessage ?? "",
						notificationType: "error"
					})
			}
			else setShowSuccess(true);
		}
    }

    const goToLogin = () => {
        navigate('/');
    }

    useEffect(() => {
        (async () => {
            if (!userToken || !userName)
                setShowError(true);
            else {
                const canSetPass = await validateTempToken(userName, userToken);
                setShowError(!canSetPass.isOk);
            }
            setLoading(false);
        })();
    }, [userToken, userName])


    return (
        <div className='bg-degree1'>
            <div className="login-content">
                <div className="block bg-white">
                    {loading
                        ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Loading />
                        </div>

                        : showError
                            ? <div className="title">
                                <MDBIcon fas icon="user-lock" size='5x' className='py-5' />
                                <p />
                                No pudimos validar tu acceso, debes ponerte en contacto con el Administrador.
                            </div>
                            :
                            !showSuccess
                                ? <form onSubmit={handleSubmit(onSubmit)}>
                                    <>
                                        <div className="mg-bttn16" >
                                            <h4 style={{ textAlign: 'center' }}>Actualización de contraseña </h4>
                                            <label className="txt-bajada pt-3" style={{ marginBottom: "2px" }}>
                                                Favor ingresa y confirma tu nueva contraseña.
                                            </label>
                                        </div>

                                        <div className="row">
                                            <div className="col col-details" style={{ textAlign: 'start' }}>
                                                <FormInput
                                                    useWrapper={false}
                                                    size={12}
                                                    errors={errors}
                                                    control={control}
                                                    name='password'
                                                    message='Contraseña es requerida'
                                                    placeholder='Contraseña'
                                                    type='password'
                                                    maxLength={16}
                                                    extraPasswordRules={PASSWORD_RULES}
                                                    rules={{
                                                        validate: {
                                                            ...createPasswordRules(PASSWORD_RULES),
                                                            words: (value) => REGEX.test(value) || 'Existen palabras no permitidas',
                                                            username: validateUserOnText(userName!)
                                                        }
                                                    }}
                                                />
                                                <FormInput
                                                    useWrapper={false}
                                                    size={12}
                                                    errors={errors}
                                                    control={control}
                                                    name='passwordConfirm'
                                                    message='Repetir Contraseña es requerida'
                                                    placeholder='Repetir Contraseña'
                                                    type='password'
                                                    maxLength={16}
                                                    extraPasswordRules={PASSWORD_RULES}
                                                    rules={{
                                                        validate: {
                                                            ...createPasswordRules(PASSWORD_RULES),
                                                            words: (value) => REGEX.test(value) || 'Existen palabras no permitidas',
                                                            username: validateUserOnText(userName!),
                                                            samePass: value => watch('password') === value || "Las contraseñas deben ser iguales"
                                                        }
                                                    }}
                                                />
                                                <div className='pt-2'>
                                                    <InputGoogleAuthenticator
                                                        setValue={setValue}
                                                        errors={errors}
                                                        control={control} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="content-int" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <MDBBtn color='none' type='submit' className='btn-prim-index px-5' disabled={isSubmitting}>
                                                {isSubmitting ? <Loading color='#fff' size='sm' /> : <>Continuar</>}
                                            </MDBBtn>
                                        </div>
                                    </>
                                </form>
                                : <>
                                    <div className="title">
                                        <MDBIcon fas icon="check" size='5x' className='py-3' />
                                        <p />
                                        ¡Tu contraseña ha sido cambiada con éxito!

                                        <div className="content-int" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <MDBBtn color='none' onClick={goToLogin} className='btn-prim-index px-5'>
                                                Iniciar Sesión
                                            </MDBBtn>
                                        </div>
                                    </div></>
                    }
                </div>
                <CardLogoSti />
            </div>
        </div>
    );
}

export default RecoveryPassword;