import LockResetIcon from '@mui/icons-material/LockReset';
import { Typography } from '@mui/material';
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import {
    GoogleReCaptcha, GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useLocalstorageState } from 'rooks';
import CardLogoSti from '../components/CardLogoSti';
import { FormInput } from '../components/FormInput';
import Loading from '../components/Loading';
import { IUserData } from '../interfaces';
import { Config } from '../utils/config';
import { getUserAuthorizedPages, login } from '../utils/middleware';
import { notify } from '../utils/notifications';
import './../css/style.css';

type FormInputData = {
    username: string;
    password: string;
};

const SITE_KEY = Config.CAPTCHA_SITE_KEY;
const USE_CAPTCHA: boolean = Config.USE_CAPTCHA.toUpperCase() === 'TRUE';

const Welcome: React.FC = () => {
    const navigate = useNavigate();
    const { handleSubmit, errors, formState, control, getValues } = useForm<FormInputData>({
        defaultValues: {
            username: "",
            password: "",
        }
    });
    const [user, setUser] = useLocalstorageState<IUserData>("user", {});
    const [userNameToUpdatePassword, setUserNameToUpdatePassword] = useState<string>("");
    const [showPage, setShowPage] = useState<boolean>(false);
    const [userMustUpdatePassword, setUserMustUpdatePassword] = useState<boolean>(false);
    const [userIsLock, setUserIsLock] = useState<boolean>(false);
    const [token, setToken] = useState<string>();
    const [userEmail, setUserEmail] = useState<string>("");

    
    const { isSubmitting } = formState;

    const onSubmit = async (data: FormInputData) => {
        const { username, password } = data;
        const response = await login(username, password, token);
		if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede realizar la verificacion",
                notificationType: "error"
            })
        } else {
			if (response.isOk) {
				localStorage.clear();
				setUser({ username })
			}
			else {
				if (response?.responseStatus === 509) {
					setUserMustUpdatePassword(true);
					setUserNameToUpdatePassword(data.username);
				}
				else if (response?.responseStatus === 510) {
					setUserEmail(response.responseData);
					setUserIsLock(true);
				}
				else {
					if (!response.notify)
						notify({
							title: "Ha ocurrido un error",
							message: response.errorMessage ?? "",
							notificationType: "error"
						})
				}
			}
		}
    };

    const onVerifyRecaptcha = useCallback((token) => {
        setToken(token);
    }, []);

    const goToForgotPassword = () => {
        navigate('/forgot-password')
    }

    const goToRecoveryPassword = () => {
        navigate("/update-password", { state: { "userName": userNameToUpdatePassword } });
    }

    useEffect(() => {
        const pages = getUserAuthorizedPages(user);
        if (pages?.length) {
            setShowPage(false)
            const page = pages[0].pag;
            const password = getValues('password')
            if(page === 'login' && password) {
                navigate(page, { state: {pass: password}});
            }
            else{
                navigate(page);
            }
        }
        else setShowPage(true);
    }, [getValues, navigate, user])


    return (
        <>
            {showPage &&
                <div className='bg-degree1'>
                    <div className="login-content">
                        <div className="block bg-white">
                            {!userMustUpdatePassword && !userIsLock &&
                                <div className="title pb-3">
                                    <h1>Bienvenido</h1>
                                </div>
                            }
                            <div className="form-content">
                                {userMustUpdatePassword
                                    ?
                                    <div className="title">
                                        <LockResetIcon sx={{ fontSize: '5em' }} />
                                        <p />
                                        ¡Bienvenido {' '}
                                        <Typography
                                            fontWeight={"bold"}
                                            component={'span'}>
                                            {userNameToUpdatePassword}
                                        </Typography>
                                        !, tu contraseña ha expirado, por motivos de seguridad, sigue los siguientes pasos para actualizar.

                                        <div className="content-int" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <MDBBtn color='none' onClick={goToRecoveryPassword} className='btn-prim-index px-5'>
                                                Continuar
                                            </MDBBtn>
                                        </div>
                                    </div>
                                    : userIsLock
                                        ? <>
                                            <div className="title">
                                                <MDBIcon far icon="envelope-open" size='5x' className='py-3' />
                                                <p />
                                                Tu usuario ha sido bloqueado, hemos enviado un mensaje de correo electrónico a:
                                                <p />
                                                <h6>{userEmail}</h6>
                                                <p />
                                                Comprueba tu bandeja de entrada y sigue las instrucciones para continuar.
                                            </div></>
                                        : <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="input-content mg-bttn16">
                                                <label className="txt-bajada pt-3" style={{ marginBottom: "2px" }}>
                                                    Ingresa tus credenciales para acceder al sistema.
                                                </label>
                                            </div>
                                            <MDBRow>
                                                <MDBCol size='10' className="col-details">
                                                    <FormInput
                                                        useWrapper={false}
                                                        errors={errors}
                                                        control={control}
                                                        placeholder="Usuario"
                                                        name='username'
                                                        message='Debe ingresar su usuario'
                                                    />
                                                </MDBCol>
                                                <MDBCol size='12' className="col-details" >
                                                    <FormInput
                                                        useWrapper={false}
                                                        errors={errors}
                                                        control={control}
                                                        name='password'
                                                        message='Debe ingresar su contraseña'
                                                        placeholder='Contraseña'
                                                        type='password'
                                                        usePasswordPopover={false}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                            <div className="input-content">
                                                <MDBBtn onClick={goToForgotPassword} color='none' tag={'a'} className='forget'>
                                                    ¿Olvidaste tu contraseña?
                                                </MDBBtn>
                                            </div>

                                            <div className="content-int" style={{ display: 'flex', justifyContent: 'center' }}>
                                                {isSubmitting ?
                                                    <div className='pt-3 text-center'><Loading /></div>
                                                    :
                                                    <MDBBtn color='none' type='submit' className='btn-prim-index px-5'>
                                                        Continuar
                                                    </MDBBtn>
                                                }
                                            </div>

                                        </form>
                                }
                                {USE_CAPTCHA &&
                                    <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY} >
                                        <GoogleReCaptcha
                                            action='STIPLUS'
                                            onVerify={onVerifyRecaptcha}
                                        />
                                    </GoogleReCaptchaProvider>
                                }
                            </div>
                        </div>
                        <CardLogoSti />
                    </div>
                </div>
            }
        </>
    );
}

export default Welcome;