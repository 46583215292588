// import './../css/style.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Select } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Box, Chip, IconButton, Link, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { MDBBtn, MDBCol, MDBDropdown, MDBDropdownToggle, MDBIcon, MDBRow, MDBTabs, MDBTabsItem, MDBTabsLink } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useLocalstorageState } from 'rooks';
import { DataTable } from '../components/DataTable';
import Loading from '../components/Loading';
import { ModalConfirm } from '../components/ModalConfirm';
import { IEditUserData, IKeyValueData, IModalConfirm, IUserData } from '../interfaces';
import { getStatusEnabled } from '../utils/checkValues';
import { PROFILE_REDBANC, deleteUser, getInstitutions, getUsersByInstitution, sendUserEmail, updateUserStatus } from '../utils/middleware';
import { notify } from '../utils/notifications';
import { ModalFormInstitution } from '../components/ModalFormInstitution';

export const AdminListUsers: React.FC = () => {
    const [user] = useLocalstorageState<IUserData>("user", {});
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as { institution: IKeyValueData };
    window.history.replaceState(null, '')

    const [loading, setLoading] = useState(false);
    const [institutionSelected, setInstitutionSelected] = useState<IKeyValueData | null>(null);
    const [institutionsValues, setInstitutionsValues] = useState<IKeyValueData[]>([]);
    const [userData, setUserData] = useState<any>([]);
    const [modalConfirm, setModalConfirm] = useState<IModalConfirm | null>();
    const theme = useTheme();
    const [showModal, setShowModal] = useState<boolean>(false);

    const getDownloadData = (transmissions: any[]) => {
        return transmissions.map((t: any) => {
            const fechaActivacionFormat = t.fechaActivacion && dayjs(t.fechaActivacion).format('DD/MM/YYYY HH:mm:ss');
            const fechaDesactivacionFormat = t.fechaDesactivacion && t.habilitado === 0 ? dayjs(t.fechaDesactivacion).format('DD/MM/YYYY HH:mm:ss') : ""
            const estado = getStatusEnabled(t.habilitado!.toString());

            return {
                'Nombre': t.nombre,
                'Username': t.userName,
                'Fecha Activación': fechaActivacionFormat,
                'Fecha Desactivación': fechaDesactivacionFormat,
                'Estado': estado,
                'Perfil': t.perfil,
                // 'Casillas autorizadas': formatBoxs.join(' | ')
            }
        })
    }

    const handleSearch = async (institutionSelected: IKeyValueData) => {
        setLoading(true);
        if (institutionSelected !== null) {
            const response = await getUsersByInstitution(institutionSelected.id);

            if (response === null) {
                notify({
                    title: "Ha ocurrido un error",
                    message: "No se puede acceder al Servicio",
                    notificationType: "error"
                })
            }
            else {
                if (response.isOk) {
                    const userData = response?.responseData ? JSON.parse(response?.responseData) : [];
                    if (!(userData === null)) {
                        setUserData(userData);
                    }
                }
                else {
                    if (!response.notify)
                        notify({
                            title: "Ha ocurrido un error",
                            message: response.errorMessage ?? "",
                            notificationType: "error"
                        })
                }
            }
        }
        setLoading(false);
        //RMM
        return { isOk: true }

    }

    const handleSelectInstitution = async (selectedLabel: string) => {
        if (selectedLabel) {
            const instSelectedIndex = institutionsValues.findIndex((inst: IKeyValueData) => inst.nombre === selectedLabel)
            const institutionSelected = institutionsValues[instSelectedIndex]
            setInstitutionSelected(institutionSelected);
            await handleSearch(institutionSelected)
        }
        else {
            setInstitutionSelected(null)
            setUserData([]);
        }
    }

    const handleSuccessSaveUser = () => {
        setShowModal(false)
        getInstitutionsData();
    }

    const handleCreateUser = () => {
        navigate(
            "/admin/usuarios/crear",
            { state: { "institution": institutionSelected } }
        )
    }

    const handleEditUser = (userData: any) => {
        navigate(
            user.profile! !== PROFILE_REDBANC
                ? "/admin/usuarios/editar"
                : "/consulta/usuarios/editar",
            { state: { "institution": institutionSelected, "userData": userData } }
        )
    }

    const getInstitutionsData = useCallback(async () => {
        const institutions = await getInstitutions();
        if (institutions.isOk) {
            let institutionList = institutions.responseData ? JSON.parse(institutions.responseData) : [];
            setInstitutionsValues(institutionList.map((data: any) => { return { id: data.id, nombre: data.nombre } }));
            if (state?.institution) {
                setInstitutionSelected(state?.institution);
                await handleSearch(state?.institution)
            }
        }
        else {
            if (!institutions.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: institutions.errorMessage ?? "",
                    notificationType: "error"
                })
        }

    }, [state])

    const handleDeleteUser = async (data: any) => {
        const response = await deleteUser(data.userName);
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                await handleSearch(institutionSelected!);
                notify({
                    message: <>Usuario <b>{data.userName}</b> eliminado exitosamente.</>,
                    // title: `${data.userName}`,
                    autoClose: 3000
                })
            }
            else {
                if (!response.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: response.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
        //RMM
        return { isOk: true }
    }

    const handleUpdateUserStatus = async (data: any) => {
        const status = data.habilitado === 0 ? 1 : 0;
        const response = await updateUserStatus(data.userName, status);
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                await handleSearch(institutionSelected!);
                notify({
                    message: `Usuario actualizado exitosamente.`,
                    autoClose: 3000,
                })
            }
            else {
                if (!response.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: response.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
        //RMM
        return { isOk: true }
    }

    const handleSendEmail = async (data: any) => {
        const response = await sendUserEmail(data.userName);
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                notify({
                    message: `Correo enviado exitosamente.`,
                    autoClose: 3000,
                })
            }
            else {
                if (!response.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: response.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
        //RMM
        return { isOk: true }
    }

    const handleShowConfirm = (modalType: string, data: any) => {
        let modalProps: any = { show: true };

        if (modalType === "deleteUser") {
            modalProps = {
                ...modalProps,
                show: true,
                title: `Eliminar Usuario`,
                body: <>¿Desea eliminar al usuario <b>{data.userName}</b>?</>,
                // onConfirm: () => handleDeleteUser(data)
                onConfirm: async () => {
                    await handleDeleteUser(data);
                    setModalConfirm(null);
                }
            }
        }
        else if (modalType === "sendEmail") {
            modalProps = {
                ...modalProps,
                show: true,
                title: `Enviar Correo`,
                body: <>
                    ¿Desea enviar el correo al usuario <b>{data.userName}</b>?
                    <Typography variant="subtitle2" fontWeight={'bold'} mt={2}>
                        <InfoOutlinedIcon fontSize='small' /> El Usuario permanecerá en estado pendiente hasta que complete el proceso de activación
                    </Typography>
                </>,
                onConfirm: async () => {
                    await handleSendEmail(data);
                    setModalConfirm(null);
                }
            }
        }
        else if (modalType === "updateStatus") {
            if (data.userName === user.username) {
                notify({
                    message: "No es posible deshabilitar al Usuario actual.",
                    notificationType: "warning"
                })
                return;
            }
            modalProps = {
                ...modalProps,
                show: true,
                title: <>Actualizar Estado</>,
                body: <>¿Desea <b>{data.habilitado === 0 ? "habilitar" : "deshabilitar"} </b> al usuario <b>{data.userName}</b>?</>,
                onConfirm: async () => {
                    await handleUpdateUserStatus(data);
                    setModalConfirm(null);
                }
            }
        }
        else {
            console.error(modalType);
            notify({
                title: "Ha ocurrido un error",
                message: "Acción no implementada",
                notificationType: "error"
            })
        }

        setModalConfirm(modalProps);

    }

    useEffect(() => {
        getInstitutionsData();
    }, [getInstitutionsData])

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    return (
        <div className="bg-white">
            <div className="container-fluid">
                {
                    <div className="grilla-content">
                        <div className="title-grilla">
                            <h1>{`${user.profile! !== PROFILE_REDBANC ? 'Administrador' : 'Consulta'} de Usuarios`}</h1>
                        </div>

                        {showModal && (
                            <ModalFormInstitution
                                title={'Crear Institución'}
                                onSuccess={handleSuccessSaveUser}
                                show={showModal}
                                onClose={() => setShowModal(false)}
                            />
                        )}

                        {modalConfirm &&
                            <ModalConfirm
                                title={modalConfirm.title}
                                body={modalConfirm.body}
                                showConfirm={modalConfirm.show}
                                onClose={() => setModalConfirm({ ...modalConfirm, show: false })}
                                onConfirm={modalConfirm.onConfirm}
                            />
                        }
                        <MDBRow className='my-5 d-flex align-items-end' >
                            <MDBCol size='8'>
                                <Select
                                    radius={8}
                                    label="Institución"
                                    onChange={handleSelectInstitution}
                                    data={institutionsValues.length ? institutionsValues.map(i => i.nombre) : []}
                                    value={institutionSelected?.nombre}
                                    searchable
                                    nothingFound="Sin información"
                                />
                            </MDBCol>
                            {user.profile! !== PROFILE_REDBANC &&
                                <>
                                    <MDBCol size='auto'>
                                        <MDBBtn
                                            color='success'
                                            className='btn-prim-download'
                                            rounded
                                            onClick={() => setShowModal(true)}>
                                            <MDBIcon fas icon="university" className='mx-2' />
                                            Crear Institución
                                        </MDBBtn>
                                    </MDBCol>
                                    <MDBCol size='auto'>
                                        <MDBBtn
                                            color='success'
                                            className='px-4 btn-prim-download'
                                            rounded
                                            disabled={!institutionSelected}
                                            onClick={handleCreateUser}>
                                            <MDBIcon fas icon="user-plus" className='mx-2' />
                                            Crear Usuario
                                        </MDBBtn>
                                    </MDBCol>
                                </>
                            }
                        </MDBRow>

                        <div className="text-center">
                            {loading ? <Loading /> :
                                <>
                                    <MDBTabs className='nav-item pb-3'>
                                        <MDBTabsItem>
                                            <MDBTabsLink active={true}>
                                                LISTADO DE USUARIOS {institutionSelected && `- ${institutionSelected.nombre.toLocaleUpperCase()}`}
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                    </MDBTabs>

                                    <DataTable
                                        rows={userData.map((user: IEditUserData) => {
                                            const estado = getStatusEnabled(user.habilitado!.toString());
                                            const fechaActivacion = user.fechaActivacion && dayjs(user.fechaActivacion).format('DD/MM/YYYY HH:mm:ss')
                                            const fechaDesactivacion = user.fechaDesactivacion && user.habilitado === 0 ? dayjs(user.fechaDesactivacion).format('DD/MM/YYYY HH:mm:ss') : ""

                                            return { ...user, id: user.userName, estado, fechaDesactivacion, fechaActivacion }
                                        })}


                                        columns={[
                                            {
                                                field: 'nombre', headerName: 'Nombre Usuario', minWidth: 300,
                                                renderCell: (params) => (
                                                    <Link
                                                        color={theme.palette.primary.main}
                                                        component="button"
                                                        variant="body2"
                                                        onClick={() => handleEditUser(params.row)}
                                                    >
                                                        {params.row.nombre}
                                                    </Link>
                                                )
                                            },
                                            {
                                                field: 'userName',
                                                headerName: 'Username',
                                                minWidth: 300
                                            },

                                            { field: 'fechaActivacion', headerName: 'Fecha Activación', minWidth: 200 },
                                            {
                                                field: 'estado', align: "center", headerAlign: 'center', headerName: 'Estado', minWidth: 150,
                                                renderCell: (params) => {
                                                    const estado = params.row.estado;
                                                    return (
                                                        <>
                                                            <Chip
                                                                size='small'
                                                                label={estado}
                                                                color={
                                                                    params.row.habilitado === 1
                                                                        ? 'success'
                                                                        : params.row.habilitado === 0
                                                                            ? 'default'
                                                                            : 'warning'} variant="outlined" />
                                                        </>
                                                    );
                                                }


                                            },
                                            { field: 'fechaDesactivacion', headerName: 'Fecha Desactivación', minWidth: 200 },
                                            { field: 'perfil', headerName: 'Perfil', minWidth: 150 },
                                            {
                                                field: 'acciones',
                                                hide: user.profile! === PROFILE_REDBANC,
                                                align: "center",
                                                headerAlign: 'center',
                                                sortable: false,
                                                headerName: 'Acciones',
                                                minWidth: 200,
                                                renderCell: (params) => {
                                                    const habilitado = params.row.habilitado;
                                                    return (
                                                        <>
                                                            <Box className='px-1'>
                                                                <LightTooltip title="Eliminar Usuario" placement="bottom">
                                                                    <span>
                                                                        <IconButton
                                                                            sx={{ border: `1px solid ${habilitado === -1 ? theme.palette.primary.main : theme.palette.text.disabled}`, color: theme.palette.primary.main, bgcolor: theme.palette.common.white }}
                                                                            aria-label="delete"
                                                                            size="small"
                                                                            disabled={habilitado !== -1}
                                                                            onClick={() => habilitado === -1 && handleShowConfirm("deleteUser", params.row)}>
                                                                            <PersonOffIcon sx={{ color: habilitado === -1 ? theme.palette.primary.main : theme.palette.text.disabled }} />
                                                                        </IconButton>
                                                                    </span>
                                                                </LightTooltip>
                                                            </Box>
                                                            <Box className='px-1'>
                                                                <LightTooltip title="Reenviar correo" placement="bottom">
                                                                    <span>
                                                                        <IconButton
                                                                            sx={{ border: `1px solid ${theme.palette.primary.main}`, color: theme.palette.primary.main, bgcolor: theme.palette.common.white }}
                                                                            aria-label="delete"
                                                                            size="small"
                                                                            onClick={() => handleShowConfirm("sendEmail", params.row)}>
                                                                            <ForwardToInboxIcon sx={{ color: theme.palette.primary.main }} />
                                                                        </IconButton>
                                                                    </span>
                                                                </LightTooltip>
                                                            </Box>
                                                            <Box className='px-1'>
                                                                <LightTooltip title="Cambiar estado" placement="bottom">
                                                                    <span>
                                                                        <IconButton
                                                                            sx={{ border: `1px solid ${habilitado !== -1 ? theme.palette.primary.main : theme.palette.text.disabled}`, color: theme.palette.primary.main, bgcolor: theme.palette.common.white }}
                                                                            aria-label="delete"
                                                                            size="small"
                                                                            disabled={habilitado === -1}
                                                                            onClick={() => habilitado !== -1 && handleShowConfirm("updateStatus", params.row)}>
                                                                            <ManageAccountsIcon sx={{ color: habilitado !== -1 ? theme.palette.primary.main : theme.palette.text.disabled }} />
                                                                        </IconButton>
                                                                    </span>
                                                                </LightTooltip>
                                                            </Box>
                                                        </>
                                                    );
                                                }
                                            },

                                        ]} />
                                    <MDBRow className='d-flex align-items' >
                                        {userData.length > 0 &&
                                            <MDBCol size='2'>
                                                <div className="paginador">
                                                    <nav>
                                                        <ul className="pagination">
                                                            <MDBDropdown style={{ background: 'none' }} className="dropdown-remove-arrow">
                                                                <MDBDropdownToggle className="px-3 " style={{ minWidth: "max-content" }} color='none'>
                                                                    <CsvDownloader
                                                                        filename={`usuarios_${institutionSelected?.nombre.toLowerCase()}`}
                                                                        extension=".csv"
                                                                        separator=","
                                                                        datas={getDownloadData(userData) as any}>
                                                                        <span className='px-1'>Descargar</span> <MDBIcon size='1x' fas icon="file-csv" />
                                                                    </CsvDownloader>
                                                                </MDBDropdownToggle>
                                                            </MDBDropdown>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </MDBCol>
                                        }
                                    </MDBRow>
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}