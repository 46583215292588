import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { ReactElement, useEffect, useState } from "react";
import Loading from "./Loading";

export const ModalConfirm: React.FC<{
  showConfirm: boolean;
  buttonType?: "button" | "submit" | "reset" | undefined;
  title?: ReactElement | string;
  body?: ReactElement;
  onClose: () => void;
  onConfirm?: () => Promise<void> | void;
  cancelButtonName?: string;
  confirmButtonName?: string;
  zIndex?: number | string;
}> = (props) => {
  const {
    showConfirm,
    onClose,
    onConfirm,
    title,
    body,
    zIndex = "auto",
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    if (onConfirm) {
      await onConfirm();
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsSubmitting(false);
    };
  }, []);

  return (
    <div style={{ position: "absolute", zIndex: zIndex }}>
      <MDBModal show={showConfirm} staticBackdrop>
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{title}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody className="text-center mt-1">{body}</MDBModalBody>
            <MDBModalFooter>
              {isSubmitting ? (
                <MDBBtn color="muted" disabled>
                  <Loading size="sm" />
                </MDBBtn>
              ) : (
                <>
                  <MDBBtn color="white" onClick={onClose}>
                    {props.cancelButtonName || "Cancelar"}
                  </MDBBtn>
                  {onConfirm && (
                    <MDBBtn color="success" onClick={handleConfirm}>
                      {props.confirmButtonName || "Aceptar"}
                    </MDBBtn>
                  )}
                </>
              )}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};
