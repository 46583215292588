import { NotificationProps, showNotification } from "@mantine/notifications";

type colorsOptions = {
    [key: string]: string
}

type NotificationTypes = 'success' | 'warning' | 'error';

export interface NotificationPropsApp extends NotificationProps {
    notificationType?: NotificationTypes;
}

export const notify = (props: NotificationPropsApp) => {

    const { notificationType = "success", ...others } = props;

    const notificationColors: colorsOptions = {
        'success': 'green',
        'warning': 'yellow',
        'error': 'red'
    }

    const config = {
        autoClose: others.autoClose || true,
        color: notificationColors[notificationType],
        ...others
    };

    return showNotification(config);
}
