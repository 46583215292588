import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { MDBCardImage, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Control, DeepMap, FieldError, useForm } from "react-hook-form";
import imageIngoGoogleAuth from "../img/info-google-auth.webp";
import { DigitInput } from "./DigitInput";
import { FormInput } from "./FormInput";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '2px solid #dadde9',
    },
}));

const InputGoogleAuthenticator: React.FC<{
    control?: Control;
    errors: DeepMap<Record<string, any>, FieldError>;
    setValue: ReturnType<typeof useForm>['setValue'];
}> = (props) => {
    const { control, errors, setValue } = props;
    const [digits, setDigits] = useState(["", "", "", "", "", ""]);

    const handleDigitChange = (index: number, value: string) => {
        const newDigits = [...digits];
        newDigits[index] = value;
        setDigits(newDigits);
    };

    useEffect(() => {
        setValue("code", digits);
    }, [digits, setValue])

    return <>
        <Grid container item>
            <Typography variant="caption" color="textSecondary">
                Código de Google Authenticator {' '}
                <HtmlTooltip
                    placement="right"
                    title={
                        <MDBRow>
                            <MDBCol size={'12'}>
                                <MDBCardImage src={imageIngoGoogleAuth} alt='...' fluid />
                            </MDBCol>
                            <MDBCol size={12}>
                                Recuerda que este código se encuentra dentro de la aplicación Google Authenticator
                            </MDBCol>
                        </MDBRow>
                    }
                >
                    <InfoOutlinedIcon fontSize='small' />
                </HtmlTooltip>
            </Typography>
            <div style={{ display: "flex", paddingTop: '8px' }}>
                {digits.map((_, index) => (
                    <DigitInput
                        key={index}
                        digitIndex={index}
                        onChange={(value: string) => handleDigitChange(index, value)}
                    />
                ))}
            </div>
            <FormInput
                useWrapper={false}
                size={12}
                errors={errors}
                control={control}
                name='code'
                message='Código es requerido'
                type='hidden'
                rules={{
                    validate: {
                        f1: (value) => /^\d{6}$/.test(value.join('')) || `Debe ingresar el código correctamente`,
                    }
                }}
            />
        </Grid>
        <div className="input-content">
            <label>
                Si ya no tienes acceso a tu aplicación contacta a tu Administrador
            </label>
        </div>
    </>

}

export default InputGoogleAuthenticator;