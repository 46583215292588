import { useState } from "react";
import { useLocalstorageState } from "rooks";
import { IUserData } from "../interfaces";
import { downloadFile } from "../utils/middleware";
import { notify } from "../utils/notifications";
import Loading from "./Loading";

const PROFILES_WHIT_DOWNLOAD_ACCESS = ["OPERADOR", "AUTORIZADOR"];

export const DirectoriesTable: React.FC<{ title: string, directoriesData: [{ archivo: string }] }> = (props) => {
    const { directoriesData, title } = props;
    const [downloadingFile, setDownloadingFile] = useState<string>("");
    const [user] = useLocalstorageState<IUserData>("user", {});


    const handleDownload = async (name: string) => {
        setDownloadingFile(name);
        const directory = title.replace("/", "").toLowerCase();
        const response = await downloadFile(name, directory);

        if (!response?.isOk) {
            notify({
                title: "Ha ocurrido un error",
                message: response?.errorMessage ?? "",
                notificationType: "error"
            })
        }
        else response.responseData?.click();

        setDownloadingFile("");
    }

    return (
        <div className="col">
            <h3>{title ?? ""}</h3>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="row">Archivo</th>
                        <th scope="col">Tamaño</th>
                        <th scope="col">Fecha</th>
                        {title === "/Buzon" && PROFILES_WHIT_DOWNLOAD_ACCESS.includes(user.profile!) &&
                            <th scope="col">Acciones</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {directoriesData?.map((data: { archivo: string }, key: number) => {

                        let [size, date, time, ...restString] = data.archivo.split(" ");
                        const name = restString.join(" ");

                        size = size === undefined
                            ? "Sin información"
                            : size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " bytes";

                        return <tr key={key}>
                            <th scope="row">{name}</th>
                            <td>{size} </td>
                            <td>{`${date} ${time}`}</td>
                            {title === "/Buzon" && PROFILES_WHIT_DOWNLOAD_ACCESS.includes(user.profile!) &&
                                <td>
                                    <div className="actions">
                                        <div className="ico-action">
                                            {downloadingFile === name ?
                                                <Loading />
                                                :
                                                <a href="#/" onClick={() => handleDownload(name)} className="descarga-on" title="Descargar">&nbsp;</a>
                                            }
                                        </div>
                                    </div>
                                </td>
                            }
                        </tr>
                    }
                    )}
                </tbody>
            </table>
        </div>
    );
}