import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import InboxIcon from '@mui/icons-material/Inbox';
import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import * as React from 'react';


const QuickSearchToolbar = () => {
    return (
        <Box
            sx={{
                textAlign: 'end',
                // p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter placeholder="Buscar..." />
        </Box>
    );
}

const NoRowsOverlay = (text: string, isInputSearch: boolean = false) => {
    return (
        <Box sx={{ m: 2 }}>
            {isInputSearch ?
                <ContentPasteSearchIcon sx={{ fontSize: 36, color: 'lightgray' }} />
                : <InboxIcon sx={{ fontSize: 36, color: 'lightgray' }} />
            }
            <p />
            {text}
        </Box>
    );
}

export const DataTable: React.FC<{ columns: GridColDef[], rows: any, initPageSize?: number }> = (props) => {
    const { rows, columns, initPageSize = 20 } = props;
    const [pageSize, setPageSize] = React.useState<number>(initPageSize);
    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>

                <DataGrid
                    sx={{
                        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                            outline: "none !important"
                        },
                        "&.MuiDataGrid-root": {
                            border: 'none'
                        },
                        "&.MuiDataGrid-root .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
                            margin: 0
                        },
                        '.MuiDataGrid-columnSeparator': {
                            display: 'none',
                        }
                    }}
                    disableColumnMenu
                    isRowSelectable={() => { return false }}
                    autoHeight
                    rows={rows}
                    getRowId={(row: any) => row.id}
                    columns={columns}
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: 'Registros por página',
                            labelDisplayedRows: (data: any) => `${data.from}–${data.to} de ${data.count !== -1 ? data.count : `more than ${data.to}`}`,

                        }
                    }}
                    components={{
                        Toolbar: rows.length > 0 ? QuickSearchToolbar : undefined,
                        NoRowsOverlay: () => NoRowsOverlay("No hay datos que mostrar"),
                        NoResultsOverlay: () => NoRowsOverlay("No hemos encontrado información", true)
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                />
            </div>
        </div>
    );
}
