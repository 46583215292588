import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import ActivateUser from './pages/ActivateUser';
import { AdminBoxs } from './pages/AdminBoxs';
import { AdminListBoxs } from './pages/AdminListBoxs';
import { AdminListUsers } from './pages/AdminListUsers';
import { AdminUser } from './pages/AdminUsers';
import Box from './pages/Box';
import ForgotPassword from './pages/ForgotPassword';
import LoginAuthenticator from './pages/LoginAuthenticator';
import NotFound from './pages/NotFound';
import { Pricing } from './pages/Pricing';
import RecoveryPassword from './pages/RecoveryPassword';
import { Security } from './pages/Security';
import { Transmission } from './pages/Transmission';
import Unauthorized from './pages/Unauthorized';
import ForceUpdatePassword from './pages/ForceUpdatePassword';
import UpdatePassword  from './pages/UpdatePassword';
import Upload from './pages/Upload';
import Welcome from './pages/Welcome';
import { PrivateRoute } from './PrivateRoute';
import EnabledUnlockUser from './pages/EnabledUnlockUser';
import UnlockUser from './pages/UnlockUser';
import { RedbancSearch } from './pages/RedbancSearch';

export const AppRouter: React.FC = () => {
	
	return (
		<Routes>
			<Route path="/" element={<Welcome />} />
			<Route path="/login" element={<LoginAuthenticator />} />
			<Route path="/not-found" element={<NotFound />} />

			{/* Private Routes */}
			<Route path='/casillas' element={<PrivateRoute><Box /></PrivateRoute>} />
			<Route path='/transmisiones' element={<PrivateRoute ><Transmission /></PrivateRoute>} />
			<Route path='/subir-archivos' element={<PrivateRoute><Upload /></PrivateRoute>} />
			<Route path='/tarificaciones' element={<PrivateRoute><Pricing /></PrivateRoute>} />
			<Route path='/seguridad' element={<PrivateRoute><Security /></PrivateRoute>} />
			<Route path='/transmisiones/consultar' element={<PrivateRoute ><RedbancSearch /></PrivateRoute>} />            
			<Route path='/admin/usuarios' element={<PrivateRoute><AdminListUsers /></PrivateRoute>} />
			<Route path='/admin/usuarios/:actionPage' element={<PrivateRoute><AdminUser /></PrivateRoute>} />
			<Route path='/admin/casillas' element={<PrivateRoute><AdminListBoxs /></PrivateRoute>} />
			<Route path='/admin/casillas/:actionPage' element={<PrivateRoute><AdminBoxs /></PrivateRoute>} />
            <Route path='/consulta/usuarios' element={<PrivateRoute><AdminListUsers /></PrivateRoute>} />
            <Route path='/consulta/usuarios/:actionPage' element={<PrivateRoute><AdminUser /></PrivateRoute>} />
			<Route path="/activar-usuario" element={<ActivateUser />} />
			<Route path="/unauthorized" element={<Unauthorized />} />
			<Route path="*" element={<Navigate to="/not-found" replace />} />

            <Route path="/update-user-password" element={<PrivateRoute><UpdatePassword /></PrivateRoute>} />
			<Route path="/desbloquear-usuario" element={<UnlockUser />} />
			<Route path="/habilitar-usuario" element={<EnabledUnlockUser />} />
            <Route path="/recovery-password" element={<RecoveryPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/update-password" element={<ForceUpdatePassword />} />
		</Routes>
	);
};
