import { ErrorMessage } from "@hookform/error-message";
import { MultiSelect, Progress, Select, Text } from "@mantine/core";
import { Box, Popover } from "@mui/material";
import { MDBCol, MDBIcon, MDBRadio, MDBRow } from "mdb-react-ui-kit";
import React, { ReactElement, useState } from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  RegisterOptions,
} from "react-hook-form";
import { IKeyValueData } from "../interfaces";
import { ErrorMessageForm } from "./ErrorMessageForm";

function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text
      color={meets ? "#18BA95" : "#C33149"}
      sx={{ display: "flex", alignItems: "center", margin: 5 }}
      mt={7}
      size="sm"
    >
      {meets ? <MDBIcon fas icon="check" /> : <MDBIcon fas icon="times" />}
      <div className="px-3">{label}</div>
    </Text>
  );
}

export const PASSWORD_RULES = [
  { re: /^.{8,16}$/, label: "Debe ingresar entre 8 y 16 caracteres" },
  { re: /[0-9]/, label: "Debe incluir un número" },
  { re: /[a-z]/, label: "Debe incluir letra minúscula" },
  { re: /[A-Z]/, label: "Debe incluir letra mayúscula" },
  {
    re: /[$&+,:;=?@#|'<>.^*()%!-]/,
    label: "Debe incluir un caracter especial",
  },
];

export const FormInput: React.FC<{
  useWrapper?: boolean;
  control?: Control;
  listValues?: IKeyValueData[];
  label?: string;
  placeholder?: string;
  searchable?: boolean;
  message?: string;
  extraMessage?: ReactElement;
  maxLength?: number;
  size?: number;
  name: string;
  errors: DeepMap<Record<string, any>, FieldError>;
  rules?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  extraPasswordRules?: { re: RegExp; label: string }[];
  usePasswordPopover?: boolean;
  disableInputPaste?: boolean;
  type?:
    | "multiple"
    | "select"
    | "radio"
    | "number"
    | "time"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "search"
    | "date"
    | "password"
    | "week"
    | "month"
    | "hidden"
    | "datetime-local"
    | "textarea"
    | "idCasilla";
}> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const {
    useWrapper = true,
    usePasswordPopover = true,
    disableInputPaste = false,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const opened = Boolean(anchorEl);

  const controllerInput = () => {
    return (
      <>
        <Controller
          render={({ onChange, value, name }) => {
            switch (props.type) {
              case "select":
                return (
                  <Select
                    radius={8}
                    onChange={onChange}
                    data={
                      props.listValues!.length
                        ? props.listValues!.map((data: IKeyValueData) => ({
                            value: data.id.toString(),
                            label: data.nombre,
                          }))
                        : []
                    }
                    nothingFound="Sin información"
                    value={value && value.toString()}
                  />
                );
              case "multiple":
                const data = props.listValues!.length
                  ? props.listValues!.map((data: IKeyValueData) => ({
                      value: data.id.toString(),
                      label: data.nombre,
                    }))
                  : [];
                const haveErrors = props.errors[props.name];

                return (
                  <MultiSelect
                    onChange={onChange}
                    data={data}
                    styles={{ input: { borderColor: haveErrors && "#C33149" } }}
                    className={"mantine-select"}
                    radius={8}
                    value={value}
                    placeholder={props.placeholder}
                    searchable={props.searchable}
                  />
                );
              case "radio":
                return (
                  <>
                    {props.listValues!.map((radio: IKeyValueData, key) => {
                      return (
                        <MDBRadio
                          onChange={onChange}
                          checked={radio.id === value}
                          key={key}
                          name={name}
                          value={radio.id}
                          label={radio.nombre}
                          inline
                        />
                      );
                    })}
                  </>
                );
              case "password":
                const requirements: { re: RegExp; label: string }[] = [];

                if (props.extraPasswordRules && opened)
                  requirements.push(...props.extraPasswordRules);

                const getStrength = (password: string) => {
                  if (!password) return 0;
                  let multiplier = password.length > 5 ? 0 : 1;

                  requirements.forEach((requirement) => {
                    if (requirement?.re && !requirement.re.test(password)) {
                      multiplier += 1;
                    }
                  });

                  return Math.max(
                    100 - (100 / (requirements.length + 1)) * multiplier,
                    10
                  );
                };

                const checks =
                  opened &&
                  requirements.map(
                    (requirement, index) =>
                      requirement.re && (
                        <PasswordRequirement
                          key={index}
                          label={requirement.label}
                          meets={requirement.re.test(value)}
                        />
                      )
                  );

                const strength = opened ? getStrength(value) : 0;
                const color =
                  strength === 100
                    ? "#18BA95"
                    : strength > 50
                    ? "yellow"
                    : "#C33149";

                return (
                  <>
                    <div className="row d-flex align-items mb-0">
                      <MDBCol size="10">
                        {usePasswordPopover ? (
                          <>
                            <input
                              id={props.name}
                              type={showPassword ? "password" : "text"}
                              placeholder={props.placeholder}
                              value={value}
                              onFocusCapture={handlePopoverOpen}
                              onBlurCapture={handlePopoverClose}
                              onChange={onChange}
                              maxLength={props.maxLength}
                              onPaste={(e) =>
                                disableInputPaste && e.preventDefault()
                              }
                            />

                            <Popover
                              sx={{ padding: 1000 }}
                              transitionDuration={{ exit: 0, enter: 300 }}
                              id={"popover"}
                              open={opened}
                              disableAutoFocus
                              anchorEl={anchorEl}
                              onClose={handlePopoverClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <Box m={1} sx={{ borderRadius: 10 }}>
                                <Progress
                                  color={color}
                                  value={strength}
                                  size={5}
                                  style={{ marginBottom: 10 }}
                                />
                                {checks}
                              </Box>
                            </Popover>
                          </>
                        ) : (
                          <input
                            id={props.name}
                            type={showPassword ? "password" : "text"}
                            placeholder={props.placeholder}
                            value={value}
                            onChange={onChange}
                            maxLength={props.maxLength}
                            onPaste={(e) =>
                              disableInputPaste && e.preventDefault()
                            }
                          />
                        )}
                      </MDBCol>
                      <MDBCol size="2" style={{ textAlign: "center" }}>
                        <MDBIcon
                          color="dark"
                          onClick={() => setShowPassword(!showPassword)}
                          icon={showPassword ? "eye" : "eye-slash"}
                        />
                      </MDBCol>
                    </div>
                  </>
                );
              case "textarea":
                return (
                  <textarea
                    id={props.name}
                    placeholder={props.placeholder}
                    value={value}
                    onChange={onChange}
                    maxLength={props.maxLength}
                  />
                );
              case "hidden":
                return (
                  <input
                    type={"hidden"}
                    id={props.name}
                    value={value}
                    onChange={onChange}
                  />
                );
              case "idCasilla":
                const allowOnlyLowercase = (value: string) =>
                  value.replace(/[^a-z0-9]/g, "");

                return (
                  <div className="row d-flex align-items mb-0">
                    <MDBCol size={4}>
                      <input
                        id={props.name}
                        type="text"
                        placeholder={props.placeholder}
                        value={value}
                        onChange={(e) =>
                          onChange(allowOnlyLowercase(e.target.value))
                        }
                        maxLength={props.maxLength}
                      />
                    </MDBCol>
                    <MDBCol>{props.extraMessage}</MDBCol>
                  </div>
                );
              default:
                const allowOnlyNumber = (value: string) =>
                  value.replace(/[^0-9]/g, "");

                return (
                  <input
                    id={props.name}
                    type={
                      !props.type || props.type === "number"
                        ? "text"
                        : props.type
                    }
                    placeholder={props.placeholder}
                    value={value}
                    onChange={(e) =>
                      onChange(
                        props.type === "number"
                          ? allowOnlyNumber(e.target.value)
                          : e
                      )
                    }
                    maxLength={props.maxLength}
                  />
                );
            }
          }}
          control={props.control}
          name={props.name}
          rules={{
            required: props.message,
            ...props.rules,
          }}
        />

        <ErrorMessage
          errors={props.errors}
          name={props.name}
          render={({ message }) => {
            return (
              <ErrorMessageForm id={`error_${props.name}`} message={message} />
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      {useWrapper ? (
        <MDBRow className="d-flex align-items">
          <MDBCol size={props.size || "8"}>
            <div
              className={`row input-content mg-bttn16 ${
                props.errors[props.name] && "error"
              }`}
            >
              <div className="col">{props.label}</div>
              <div className="col">{controllerInput()}</div>
            </div>
          </MDBCol>
        </MDBRow>
      ) : (
        <div
          className={`input-content mg-bttn16 ${
            props.errors[props.name] && "error"
          }`}
        >
          {controllerInput()}
        </div>
      )}
    </>
  );
};
