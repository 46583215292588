import dayjs from "dayjs";
import { MDBBtn } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { usePagination } from "../hooks/usePagination";
import { IModalConfirm, ITransmision } from "../interfaces";
import { approveTransmission, downloadTransmissionFile } from "../utils/middleware";
import { notify } from "../utils/notifications";
import Loading from "./Loading";
import { ModalConfirm } from "./ModalConfirm";
import Paginator from "./Paginator";

export const TransmissionTable: React.FC<{
	transmissions: ITransmision[],
	currentPage: number,
	transmissionType: string,
	countPerPage: number,
	onChangeCurrentPage: (page: number) => void;
	onChangeCounterPerPage: (number: number) => void;
	onSelectTransmission: (id: number) => void;
	handleApproveResponse: () => void;
}> = (props) => {
	const { transmissions, transmissionType, handleApproveResponse, onSelectTransmission, onChangeCurrentPage, onChangeCounterPerPage, currentPage, countPerPage } = props;
	const [transmissionsPaginate, setTransmissionsPaginate] = useState<ITransmision[]>([]);
	const [modalConfirm, setModalConfirm] = useState<IModalConfirm | null>();
	const [downloadingFile, setDownloadingFile] = useState<string>("");

	const handleClick = (page: number) => {
		onChangeCurrentPage(page);
		setTransmissionsPaginate(transmissionsPaginate);
	};

	// Logic for displaying page numbers
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(transmissions.length / countPerPage); i++) {
		pageNumbers.push(i);
	}

	useEffect(() => {
		const indexOfLastTransmition = currentPage * countPerPage;
		const indexOfFirstTransmition = indexOfLastTransmition - countPerPage;

		if (indexOfFirstTransmition > transmissions.length) {
			setTransmissionsPaginate(transmissions);
			onChangeCurrentPage(1);
		}
		else {
			const currentTransmissions = transmissions.slice(indexOfFirstTransmition, indexOfLastTransmition);
			setTransmissionsPaginate(currentTransmissions);
		}

	}, [currentPage, transmissions, countPerPage, onChangeCurrentPage])

	const paginationRange = usePagination({
		currentPage,
		totalCount: transmissions.length,
		pageSize: countPerPage
	});

	const handleApprove = async (transmission: ITransmision) => {
		if (transmission === null) {
			notify({
				title: "Ha ocurrido un error",
				message: "No se puede acceder al Servicio",
				notificationType: "error"
			})
		}
		else {
			const id = transmission.idTransmision ?? transmission.id;
			const transmissions = await approveTransmission(id.toString());
			if (transmissions === null) {
				notify({
					title: "Ha ocurrido un error",
					message: "No se puede acceder al Servicio",
					notificationType: "error"
				})
			}
			else {
				if (transmissions.isOk) {
					handleApproveResponse();
				}
				else {
					if (!transmissions.notify)
						notify({
							title: "Ha ocurrido un error",
							message: transmissions.errorMessage ?? "",
							notificationType: "error"
						})
				}
			}
		}
		//RMM
		return { isOk: true }
	}

	const handleShowConfirm = (modalType: string, data: ITransmision) => {
		let modalProps: any = { show: true };
		if (modalType === "approveTransmision") {
			modalProps = {
				title: <>Aprobar transmisión</>,
				body: <>¿Desea aprobar la siguiente transmisión? <p />Nombre archivo:  <b>{`${data.archivoLogico || data.archivoDato}`}</b></>,
				handleFunction: async () => await handleApprove(data)
			}
		}

		setModalConfirm({
			...modalProps,
			show: true,
			onConfirm: () => {
				modalProps.handleFunction && modalProps.handleFunction();
				setModalConfirm(null);
			}
		});

	}

	const handleDownload = async (transmision: ITransmision, isWeb: boolean) => {
		setDownloadingFile(transmision.idTransmision?.toString()!);

		const response = await downloadTransmissionFile(transmision, isWeb);

		if (!response?.isOk) {
			notify({
				title: "Ha ocurrido un error",
				message: response?.errorMessage ?? "",
				notificationType: "error"
			})
		}
		else response.responseData?.click();

		setDownloadingFile("");
	}

	return (
		<>
			{/* {selectedTransmision &&
				<ModalConfirm
					title={<>"¿Desea aprobar la siguiente transmisión?"</>}
					body={<>Nombre archivo:  <b>{`${selectedTransmision.archivoLogico || selectedTransmision.archivoDato}`}</b></>}
					showConfirm={showConfirm}
					onClose={() => setShowConfirm(false)}
					onConfirm={handleApprove}
				/>
			} */}

			{modalConfirm &&
				<ModalConfirm
					title={modalConfirm.title}
					body={modalConfirm.body}
					showConfirm={modalConfirm.show}
					onClose={() => setModalConfirm(null)}
					onConfirm={modalConfirm.onConfirm}
				/>
			}
			<table className="table">
				<thead>
					<tr>
						<th scope="col">Nombre archivo</th>
						{transmissionType === "porAprobar" ?
							<>
								<th scope="col">Peso</th>
								<th scope="col">Fecha de Solicitud</th>
								<th scope="col">Casilla Destino</th>
								<th scope="col">Solicitante</th>
								<th scope="col">Observación</th>
								<th scope="col" className="text-center">Acciones</th>
							</>
							:
							<>
								<th scope="col">Canal</th>
								<th scope="col">Peso</th>
								<th scope="col">Fecha {transmissionType === "origen" ? "Envío" : "Recepción"}</th>
								<th scope="col">Casilla {transmissionType === "origen" ? "Destino" : "Origen"}</th>
								<th scope="col">Fecha de Eliminación</th>
								<th scope="col">Estado</th>
							</>
						}
					</tr>
				</thead>
				<tbody>
					{
						transmissionsPaginate.length > 0 ?
							transmissionsPaginate.map((transmission: ITransmision) => {
								const {
									tamanoArchivo,
									fechaInicio,
									idCanal,
									idTransmision,
									archivoDato,
									idCasillaDestino,
									idCasillaOrigen,
									fechaEliminacion,
									fechaSolicitud,
									usuarioSolicitante,
									observacion,
									archivoLogico,
									id } = transmission;
								const fileSize =
									tamanoArchivo === undefined
										? "Sin información"
										: tamanoArchivo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " bytes";

								const estadoTransmision =
									transmission.estadoTransmision === 0
										? "exitoso"
										: "fallido";

								return transmissionType === "porAprobar" ?
									<tr key={id}>
										<th scope="row">{archivoLogico}</th>
										<td>{fileSize}</td>
										<td>{fechaSolicitud && dayjs(fechaSolicitud).format('DD/MM/YYYY HH:mm:ss')}</td>
										<td>{idCasillaDestino}</td>
										<td>{usuarioSolicitante}</td>
										<td>{observacion}</td>
										<td className='text-center'>
											<div className="actions">
												<div className="ico-action">
													<a href="#/" onClick={() => handleShowConfirm("approveTransmision", transmission)} className="reenvio-on" title="Aprobar">&nbsp;</a>
												</div>
												<div className="ico-action text-center">
													{downloadingFile === transmission.id?.toString()
														? <Loading />
														: <a href="#/" onClick={() => handleDownload(transmission, true)} className="descarga-on" title="Descargar">&nbsp;</a>

													}
												</div>
											</div>
										</td>
									</tr>
									:
									<tr key={idTransmision}>
										<th scope="row">
											<MDBBtn
												onClick={() => onSelectTransmission(idTransmision)}
												color='none'
												tag={'a'}
												className='forget'>
												{archivoDato}
											</MDBBtn>
										</th>
										<td>{idCanal}</td>
										<td>{fileSize}</td>
										<td>{fechaInicio && dayjs(fechaInicio).format('DD/MM/YYYY HH:mm:ss')}</td>
										<td>{transmissionType === "origen" ? idCasillaDestino : idCasillaOrigen}</td>
										<td>{fechaEliminacion && dayjs(fechaEliminacion).format('DD/MM/YYYY HH:mm:ss')}</td>
										<td>
											<span className={`estado ${estadoTransmision !== "exitoso" ? "eliminado" : "activo"}`}>
												{estadoTransmision.toUpperCase()}
											</span>
										</td>
									</tr>
							})
							:
							<tr>
								<td colSpan={7}>
									<div className="text-center mt-5">Sin información</div>
								</td>
							</tr>
					}
				</tbody>
			</table>
			{
				transmissions.length > 0 &&
				paginationRange && <Paginator
					countPerPageOptions={[5, 10, 20, 50]}
					allData={transmissions}
					onChangeCounterPerPage={onChangeCounterPerPage}
					onChangePage={handleClick}
					currentPage={currentPage}
					pageNumbers={pageNumbers}
					countPerPage={countPerPage}
					paginationRange={paginationRange} />
			}
		</>
	);
}