import dayjs from "dayjs";
import { IBoxData, INoticeData, ITransmision, IUserData, IUserFormInput, TUserBox } from "../interfaces";
import { APIRequest } from "./APIRequest";
import { Config } from "./config";
import { NotificationPropsApp, notify } from "./notifications";
import { encryptData } from "./functions";

const MESSAGE_OK = "OK";
export const PROFILE_REDBANC = 'CONSULTA-RBC';

interface AppResponse {
    isOk: boolean;
    errorMessage?: string;
    responseData?: any;
    responseStatus?: number;
    notify?: (props: NotificationPropsApp) => void;
}

const handlerApiResponse = (response: any, isDownloadResponse: boolean = false, fileName?: string): AppResponse => {
    // TODO:  debería tener un control para retornar solamente la data

    // Para casos de descarga de archivos
    if (isDownloadResponse && fileName) {
        if (response.status !== 200) {
            return { isOk: false, errorMessage: response.data.message || "" };
        }

        const url = window.URL.createObjectURL(new Blob([response.data], { type: "octet/stream" }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        // document.body.removeChild(link);
        return { isOk: true, responseData: link };
    }

    // Primera validación
    if (response.data.status !== 200) {
        return { isOk: false, errorMessage: "Servicio no se encuentra disponible" };
    }

    // Segunda validación
    const response2 = JSON.parse(response.data.result);
    if (response2.status !== 200 ||
        response2.message !== MESSAGE_OK) {
        if (response2.status === 401) {
            localStorage.clear();
            const notification = notify({
                title: "Sesión expirada",
                message: "Debe volver a iniciar sesión para continuar",
                notificationType: "error",
                autoClose: 3000,
                onClose: () => { window.location.replace("/") }
            });

            return { isOk: false, notify: () => notification };
        }
        if (response2.status === 509) {
            localStorage.clear();
            return { isOk: false, responseStatus: response2.status };
        }
        if (response2.status === 510) {
            localStorage.clear();
            return {
                isOk: false,
                responseData: response2.result,
                responseStatus: response2.status
            };
        }
        else if (response2.status === 404) {
            const notification = notify({
                message: response2.message,
                notificationType: "error",
                autoClose: 3000,
            });

            return { isOk: false, notify: () => notification, responseStatus: response2.status };
        }

        return { isOk: false, errorMessage: response2.message || "" };
    }

    // TODO: unificar mensajes de error, hay veces en que retorna NOK y no el mensaje
    return { isOk: true, responseData: response2.result };
}

export const login = async (username: string, password: string, token?: string) => {
    try {
        const encryptText = await encryptData(password)
        const headers = { "CSRFP466": encryptText.encryptedData, "CSRFIv": encryptText.iv }

        const response = await APIRequest.post({
            path: `${Config.API_URL_USUARIO}/usuario/login?pus3rN4m3=${username}`,
            headers
        });

        return handlerApiResponse(response);
    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}


export const checkGoogleAuthCode = async (username: string, code: string, password: string,) => {
    try {
        const encryptText = await encryptData(password)
        const headers = { "CSRFC0d160": code,"CSRFP466": encryptText.encryptedData, "CSRFIv": encryptText.iv }

        const response = await APIRequest.post({
            path: `${Config.API_URL_USUARIO}/usuario/login/token?pus3rN4m3=${username}`,
            headers
        });

        return handlerApiResponse(response);
    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const forgotPassword = async (username: string, code: string) => {
    try {
        const headers = {
            "CSRFC0d160": code
        };

        const response = await APIRequest.post({
            path: `${Config.API_URL_USUARIO}/usuario/correo/olvido/clave?pus3rN4m3=${username}`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };

    }
}

export const logout = async () => {
    try {
        const userData = JSON.parse(getStorage("user")!);

        if (userData !== null && userData.email) {
            await APIRequest.post({
                path: `${Config.API_URL_USUARIO}/usuario/logout?userName=${userData?.email}`
            });
        }

        localStorage.clear();
    } catch (error) {
        console.error("~ error in Logout", error);
    }
}

export const getStorage = (item: string) => {
    try {
        return localStorage.getItem(item);
    } catch (error) {
        console.error("~ error in getStorage", error);
    }
}

export type TransmissionTypes = 'origen' | 'destino' | 'porAprobar';
export const getTransmission = async (type: TransmissionTypes) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_TRANSMISION}/transmision/listar/home/${type}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export type TransmissionFilters = {
    casillaOrigen: string,
    casillaDestino: string,
    fechaDesde: string,
    fechaHasta: string,
    idEstado: string,
    nombreArchivo: string
}
export const getFilteredTransmission = async (filters: TransmissionFilters) => {
    try {
        const headers = { ...filters };

        const response = await APIRequest.get({
            path: `${Config.API_URL_TRANSMISION}/transmision/consultar`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getConfigBox = async () => {
    try {

        const response = await APIRequest.get({
            path: `${Config.API_URL_CONFIGURATION}/casilla/configuracion`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getDirectories = async () => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_CONFIGURATION}/casilla/listar/archivos`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getUsersByBox = async (boxId: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/usuario/casilla/consultar?id_casilla=${boxId}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getUsersByInstitution = async (institutionId: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/usuario/listar?id_institucion=${institutionId}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const createInstitution = async (id: string, name: string) => {
    try {
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/institucion/ingresar?id_institucion=${id}&nombre=${name}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getInstitutions = async () => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/institucion/listar`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getProfiles = async () => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/perfil/listar`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}


export type UploadData = {
    usuario_origen: string;
    casilla_origen: string;
    casilla_destino: string;
    file: File;
    observacion?: string;
}

export const uploadFile = async (data: UploadData): Promise<AppResponse> => {
    try {
        const headers = { "Content-Type": "multipart/form-data" }

        let formData = new FormData();
        formData.append('usuario_origen', data.usuario_origen);
        formData.append('casilla_origen', data.casilla_origen);
        formData.append('casilla_destino', data.casilla_destino);
        formData.append('file', data.file);
        formData.append('observacion', data.observacion || "");

        const response = await APIRequest.post({
            path: `${Config.API_URL_TRANSMISION}/transmision/web/upload`,
            headers,
            data: formData
        });

        const isUploadResponse = true;
        return handlerApiResponse(response, isUploadResponse);
    } catch (error) {
        console.error(`Error in uploadFile: ${error}`);
        return { isOk: false };
    }
}

export const approveTransmission = async (transmissionId: string) => {
    try {
        const headers = { "Content-Type": "multipart/form-data" }

        let formData = new FormData();
        formData.append('id_transmision', transmissionId);

        const response = await APIRequest.post({
            path: `${Config.API_URL_TRANSMISION}/transmision/autorizar`,
            headers,
            data: formData
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getPricing = async (sDate: Date | undefined, eDate: Date | undefined) => {
    const startDate = dayjs(sDate).format('DD/MM/YYYY');
    const endDate = dayjs(eDate).format('DD/MM/YYYY');

    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_TRANSMISION}/tarificacion/listar?fechaDesde=${startDate}&fechaHasta=${endDate}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const downloadFile = async (fileName: string, directory: string) => {

    try {
        const headers = { 'dir': directory }

        const response = await APIRequest.get({
            path: `${Config.API_URL_TRANSMISION}/transmision/web/download?archivo=${fileName}`,
            headers,
            responseType: "blob"
        });

        return handlerApiResponse(response, true, fileName);

    } catch (error) {
        console.error("error in downloadFile", error)
        return { isOk: false };
    }
}

export const downloadTransmissionFile = async (transmision: ITransmision, isWeb: boolean) => {
    try {
        const headers = isWeb
            ? { "id-transmision-web": transmision.id }
            : { "id-transmision": transmision.idTransmision };

        const path = `${Config.API_URL_TRANSMISION}/transmision/${isWeb ? "web/" : ""}download/file`;

        const response = await APIRequest.get({
            path,
            headers,
            responseType: "blob"
        });

        return handlerApiResponse(response, true, isWeb ? transmision.archivoLogico : transmision.archivoDato);

    } catch (error) {
        console.error("error in downloadTransmissionFile", error)
        return { isOk: false };
    }
}

export interface IPage {
    id?: number
    ipPerfil?: string
    pag: string
    titulo?: string
    orden?: number
    muestraMenuCasilla?: boolean

}

export const getUserAuthorizedPages = (user: IUserData) => {
    let pages: IPage[] = [];

    if (!user || !user?.username) return pages;
    else if (!user?.token || !user?.profile || !user.password) return [{ pag: "login" }];
    else if (!user.userAuthorizedPages?.length) return pages;

    return user.userAuthorizedPages;
}

export const createUser = async (userData: IUserFormInput, institutionId: string, jsonCasillas: { "casillas": TUserBox[] }) => {
    try {
        const headers = { "Content-Type": "multipart/form-data" }

        let formData = new FormData();
        formData.append('nombre', userData.name);
        formData.append('usuario', userData.userName);
        formData.append('perfil', userData.profile);
        formData.append('id_institucion', institutionId);
        formData.append('correo', userData.correo);
        formData.append('jsonCasillas', JSON.stringify(jsonCasillas));

        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/agregar`,
            headers,
            data: formData
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const editUser = async (userData: IUserFormInput, jsonCasillas: { "casillas": TUserBox[] }) => {
    try {
        const headers = { "Content-Type": "multipart/form-data" }

        let formData = new FormData();
        formData.append('nombre', userData.name);
        formData.append('usuario', userData.userName);
        formData.append('perfil', userData.profile);
        formData.append('correo', userData.correo);
        formData.append('jsonCasillas', JSON.stringify(jsonCasillas));


        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/actualizar`,
            headers,
            data: formData
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getBoxsByInstitution = async (institutionId: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/casilla/listar?id_institucion=${institutionId}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getBoxsByUser = async (userName: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/usuario/casillas/listar?usuario=${userName}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const updateUserStatus = async (userEmail: string, status: number) => {
    try {
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/estado/cambiar?usuario=${userEmail}&habilitado=${status}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const updateStatusField = async (idCasilla: string, field: string, status: number) => {
    const path = `${Config.API_URL_ADMIN}/adm/casilla/${field}/cambiar?csll=${idCasilla}&habilitado=${status}`;
    try {
        const response = await APIRequest.post({
            path
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}


export const sendUserEmail = async (userEmail: string) => {
    try {
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/correo/activacion/enviar?usuario=${userEmail}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const deleteUser = async (userEmail: string) => {
    try {
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/eliminar?usuario=${userEmail}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getBoxbyId = async (boxId: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/casilla/obtener?csll=${boxId}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const createBox = async (boxData: IBoxData, institutionId: string) => {
    try {
        const headers = { "Content-Type": "multipart/form-data" }

        let formData = new FormData();
        formData.append('csll', boxData.idCasilla);
        formData.append('id_institucion', institutionId);
        formData.append('id_dominio', boxData.idDominio);
        formData.append('formato_registro_entrada', boxData.formatoRegistroEntrada);
        formData.append('delimitador_casilla', boxData.delimitadorCasilla);
        formData.append('delimitador_archivo', boxData.delimitadorArchivo);
        formData.append('migrada', boxData.migrada);
        formData.append('tipo_casilla', "S");
        formData.append('time_to_live', boxData.timeToLive.toString());
        formData.append('scan_file', boxData.scanFile.toString());

        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/casilla/crear`,
            headers,
            data: formData
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const editBox = async (boxData: IBoxData, institutionId: string) => {
    try {
        const headers = { "Content-Type": "multipart/form-data" }

        let formData = new FormData();
        formData.append('csll', boxData.idCasilla);
        formData.append('id_institucion', institutionId);
        formData.append('id_dominio', boxData.idDominio);
        formData.append('id_estado', boxData.idEstado);
        formData.append('tipo_casilla', "S");

        formData.append('migrada', boxData.migrada);
        formData.append('formato_registro_entrada', boxData.formatoRegistroEntrada);
        formData.append('delimitador_archivo', boxData.delimitadorArchivo);
        formData.append('delimitador_casilla', boxData.delimitadorCasilla);
        formData.append('time_to_live', boxData.timeToLive?.toString());
        formData.append('scan_file', boxData.scanFile);


        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/casilla/editar`,
            headers,
            data: formData
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}


export const getNoticesByBox = async (idCasilla: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/casilla/avisos/listar?csll=${idCasilla}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getUserQRCode = async (userName: string, userToken: string) => {
    try {
        const headers = {
            "pus3rN4m3": userName,
            "CSRFTokenMail": userToken
        };
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/nuevo/qr`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const validateTempToken = async (userName: string, userToken: string) => {
    try {
        const headers = {
            "pus3rN4m3": userName,
            "CSRFTokenMail": userToken,
        };
        const response = await APIRequest.post({
            path: `${Config.API_URL_USUARIO}/usuario/acceso/validar`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const validateUserToken = async (userName: string, userToken: string) => {
    // Validación de token para usuarios activos
    try {
        const headers = {
            "pus3rN4m3": userName,
            "CSRFTokenMail": userToken,
        };
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/nuevo/validar`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const activateUser = async (userName: string, userToken: string, password: string, code: string) => {
    try {
        const encryptText = await encryptData(password)
        const headers = {
            "pus3rN4m3": userName,
            "CSRFTokenMail": userToken,
            "CSRFPassNueva": encryptText.encryptedData,
            "CSRFIv": encryptText.iv,
            "CSRFCodigo": code
        };

        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/nuevo/activar`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getDomains = async () => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/dominio/listar`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const generateToken = async (idCasilla: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/seguridad/token/obtener?csll=${idCasilla}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const processAuthorizedBox = async (box: string, authorizedBox: string, action: BoxActions) => {
    try {
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/casilla/casilla-autorizada/${action}?csll=${box}&id_casilla_autorizada=${authorizedBox}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}


export type BoxActions = 'eliminar' | 'ingresar';
export const processNoticeBox = async (box: string, notice: INoticeData, action: BoxActions) => {
    try {
        notice.tipoAviso = '2';
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/casilla/aviso/${action}?csll=${box}&tipo_aviso=${notice.tipoAviso}&dato_aviso=${notice.datoAviso}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const processIPBox = async (box: string, ip: string, action: BoxActions) => {
    try {
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/casilla/ip-autorizada/${action}?csll=${box}&ip=${ip}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getIpsByBox = async (idCasilla: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/casilla/ip/listar?csll=${idCasilla}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}


export const getAuthorizeBoxs = async (idCasilla: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/casilla/autorizadas/listar?csll=${idCasilla}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}


export const processBoxGroup = async (idGrupo: string, idCasilla: string, action: BoxActions) => {
    try {
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/casilla/grupo/${action}?csll=${idCasilla}&id_grupo=${idGrupo}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getBoxsGroup = async (idCasilla: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_ADMIN}/adm/casilla/grupo/listar?id_grupo=${idCasilla}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const changeBoxPassword = async (idCasilla: string, password: string) => {
    try {
        const encryptText = await encryptData(password)
        const headers = {
            "CSRFP466": encryptText.encryptedData,
            "CSRFIv": encryptText.iv,
        };

        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/casilla/clave/set?csll=${idCasilla}`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const getBoxPublicKeys = async (idCasilla: string) => {
    try {
        const response = await APIRequest.get({
            path: `${Config.API_URL_PUBLIC_KEY}/llave/casilla/listar?csll=${idCasilla}`,
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}


export const saveBoxPublicKey = async (idCasilla: string, title: string, key: string) => {
    try {
        const encodeKey = encodeURIComponent(key).replaceAll('%20', '+');
        const response = await APIRequest.post({
            path: `${Config.API_URL_PUBLIC_KEY}/llave/casilla/agregar?csll=${idCasilla}&titulo=${title}&llave=${encodeKey}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const deleteBoxPublicKey = async (idCasilla: string, idKey: number) => {
    try {
        const response = await APIRequest.post({
            path: `${Config.API_URL_PUBLIC_KEY}/llave/casilla/eliminar?csll=${idCasilla}&id_llave=${idKey}`
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const updateUserPassword = async (userName: string, currentPassword: string, newPassword: string, code: string) => {
    try {
        const encryptTextCurrentPassword = await encryptData(currentPassword)
        const encryptTextNewPassword = await encryptData(newPassword)
        
        const headers = {
            "CSRFPassAnterior": encryptTextCurrentPassword.encryptedData,
            "CSRFIv": encryptTextCurrentPassword.iv,
            "CSRFPassNueva": encryptTextNewPassword.encryptedData,
            "CSRFIv2": encryptTextNewPassword.iv,
            "CSRFC0d160": code,
        };

        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/p4ssw0rd/logeado/cambiar?usuario=${userName}`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const recoveryUserPassword = async (userName: string, userToken: string, newPassword: string, code: string) => {
    try {
        const encryptText = await encryptData(newPassword)
        const headers = {
            "CSRFTokenMail": userToken,
            "CSRFC0d160": code,
            "CSRFPassNueva": encryptText.encryptedData,
            "CSRFIv": encryptText.iv,
        };

        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/p4ssw0rd/nologeado/cambiar?usuario=${userName}`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const enabledUser = async (userName: string, userToken: string, currentPassword: string, newPassword: string, code: string) => {
    try {
        const encryptTextCurrentPassword = await encryptData(currentPassword)
        const encryptTextNewPassword = await encryptData(newPassword)
        
        const headers = {
            "CSRFPassAnterior": encryptTextCurrentPassword.encryptedData,
            "CSRFIv": encryptTextCurrentPassword.iv,
            "CSRFPassNueva": encryptTextNewPassword.encryptedData,
            "CSRFIv2": encryptTextNewPassword.iv,
            "CSRFC0d160": code,
            "CSRFTokenMail": userToken,
        };
        
        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/p4ssw0rd/bloqueado/cambiar?usuario=${userName}`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const unlockUser = async (userName: string, userToken: string) => {
    try {
        const headers = {
            "CSRFTokenMail": userToken
        };

        const response = await APIRequest.post({
            path: `${Config.API_URL_USUARIO}/usuario/activar?pus3rN4m3=${userName}`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}

export const updateUserPasswordExpirated = async (userName: string, currentPassword: string, newPassword: string, code: string) => {
    try {
        const encryptTextCurrentPassword = await encryptData(currentPassword)
        const encryptTextNewPassword = await encryptData(newPassword)
        
        const headers = {
            "CSRFPassAnterior": encryptTextCurrentPassword.encryptedData,
            "CSRFIv": encryptTextCurrentPassword.iv,
            "CSRFPassNueva": encryptTextNewPassword.encryptedData,
            "CSRFIv2": encryptTextNewPassword.iv,
            "CSRFC0d160": code,
            "userName": userName
        };       

        const response = await APIRequest.post({
            path: `${Config.API_URL_ADMIN}/adm/usuario/p4ssw0rd/expirar`,
            headers
        });

        return handlerApiResponse(response);

    } catch (error) {
        console.error(error)
        return { isOk: false };
    }
}