import { Container, Grid, Paper, Select } from '@mantine/core';
import 'dayjs/locale/es';
import { MDBBtn, MDBCol, MDBIcon, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { FormInput } from '../components/FormInput';
import Loading from '../components/Loading';
import { ModalConfirm } from '../components/ModalConfirm';
import { IAutorizedBoxData, IBoxData, IGroupdBoxData, IIPData, IKeyValueData, IModalConfirm, INoticeData } from '../interfaces';
import { getStatusEnabled } from '../utils/checkValues';
import { BoxActions, createBox, editBox, getAuthorizeBoxs, getBoxbyId, getBoxsByInstitution, getBoxsGroup, getDomains, getIpsByBox, getNoticesByBox, processAuthorizedBox, processBoxGroup, processIPBox, processNoticeBox } from '../utils/middleware';
import { notify } from '../utils/notifications';
import { REGEX } from './AdminUsers';

const boxDelimiterValues: IKeyValueData[] = [
    { id: "S", nombre: "Si" },
    { id: "N", nombre: "No" },
];

const fileDelimiterValues: IKeyValueData[] = [
    { id: ".", nombre: ". (punto)" },
    { id: "-", nombre: "- (guión medio)" },
    { id: "_", nombre: "_ (guión bajo)" }
];

const registerFormatValues: IKeyValueData[] = [
    { id: "A", nombre: "ASCII" },
    { id: "E", nombre: "EBCDIC" },
];

const migratedTypesValues: IKeyValueData[] = [
    { id: "S", nombre: "Migrada" },
    { id: "N", nombre: "No Migrada" }
];

const boxScanFileValues: IKeyValueData[] = [
    { id: "0", nombre: "No Revisa" },
    { id: "1", nombre: "Revisa" },
]

export const AdminBoxs: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { actionPage } = useParams();

    const [domainsValues, setDomainsValues] = useState<IKeyValueData[]>([]);
    const [boxsValues, setBoxsValues] = useState<any[]>([]);
    const [currentTab, setCurrentTab] = useState("information");
    const [loading, setLoading] = useState(false);
    const [boxSelected, setBoxSelected] = useState<IBoxData | null>(null);
    const [noticeData, setNoticeData] = useState<INoticeData[]>();
    const [ipData, setIpData] = useState<IIPData[]>();

    // TODO: por definir Interfaces de authorizeData - groupBoxData
    const [authorizeData, setAuthorizeData] = useState<IAutorizedBoxData[]>();
    const [groupBoxData, setGroupBoxData] = useState<IGroupdBoxData[]>();

    const [modalConfirm, setModalConfirm] = useState<IModalConfirm | null>();

    const state = location.state as { institution: IKeyValueData, boxData: IBoxData };
    const [boxData, setBoxData] = useState<IBoxData>(state?.boxData);

    const institution = state?.institution;

    const {
        handleSubmit,
        reset,
        watch,
        errors,
        formState: { isSubmitting }, control } = useForm({
            defaultValues: {
                idCasilla: boxData?.idCasilla || "",
                idDominio: boxData?.idDominio.toString() || "",
                idEstado: boxData?.idEstado || "",
                migrada: boxData?.migrada || "",
                formatoRegistroEntrada: boxData?.formatoRegistroEntrada || "",
                delimitadorArchivo: boxData?.delimitadorArchivo || "",
                delimitadorCasilla: boxData?.delimitadorCasilla || "",
                timeToLive: boxData?.timeToLive || "",
                tipoCasilla: boxData?.tipoCasilla || "S",
                scanFile: boxData?.scanFile.toString() || ""
            }
        });

    const {
        reset: resetFieldNotice,
        handleSubmit: handleSubmitFormNotice,
        control: controlFromNotice,
        formState: { errors: errorsFromNotice } } = useForm({
            defaultValues: {
                datoAviso: ""
            }
        });

    const {
        reset: resetFieldIP,
        handleSubmit: handleSubmitFormAuthorizedIP,
        control: controlFromAuthorizeIP,
        formState: { errors: errorsFromAuthorizeIP } } = useForm({
            defaultValues: {
                ip: ""
            }
        });


    const onSubmit = async (formData: IBoxData) => {
        let response;
        const idCasilla = actionPage === "editar" ? boxData.idCasilla : formData.idCasilla;
        const migrada = formData?.migrada || boxData?.migrada;

        if (actionPage === "editar")
            response = await editBox({
                ...formData,
                migrada,
                idCasilla: boxData.idCasilla,
                idEstado: boxData.idEstado,
                tipoCasilla: boxData.tipoCasilla
            }, institution.id);
        else {
            response = await createBox({ ...formData, migrada: "-" }, institution.id);
        }

        if (response.isOk) {
            if (actionPage === "crear") {
                notify({
                    message: <>Casilla <b>{idCasilla}</b> se ha creado exitosamente.</>,
                    autoClose: 5000
                })

                const boxData = await getBoxData(idCasilla);
                setBoxData(boxData);

                navigate(
                    "/admin/casillas/editar",
                    { state: { "institution": institution, "boxData": boxData } }
                )
            }
            else {
                notify({
                    message: <>Casilla <b>{idCasilla}</b> ha sido actualizada exitosamente.</>
                })
            }
        }
        else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
    };

    const goToBack = () => {
        navigate("/admin/casillas", { state: { "institution": institution } });
    }

    const FormRowValue = (props: { label: string, value: string, customClass?: boolean, size?: number }) => {
        return <MDBRow className='d-flex align-items'>
            <MDBCol size={props.size || '8'}>
                <div className="row" style={{ padding: "5px 0px" }}>
                    <div className="col">{props.label}</div>
                    <div className={`col ${props.customClass ? "file-status" : ""} `}>{props.value}</div>
                </div>
            </MDBCol>
        </MDBRow>
    }

    const handleSelectBox = (boxId: string) => {
        const boxSelected = boxsValues.find((box: IBoxData) => box.idCasilla === boxId)
        setBoxSelected(boxSelected);
    }

    // TODO: refactorizar estas llamadas por mucha duplicidad de código
    const getBoxsValuesData = useCallback(async () => {
        const responseBoxs = await getBoxsByInstitution("0");
        if (responseBoxs === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede obtener listado de Instituciones",
                notificationType: "error"
            })
        } else {
            if (responseBoxs.isOk) {
                let boxValues = responseBoxs?.responseData ? JSON.parse(responseBoxs.responseData) : [];

                // Filter by simple box
                if (currentTab === "boxsGroup") {
                    boxValues = boxValues.filter((box: IBoxData) => box.tipoCasilla === "S");
                    boxValues = groupBoxData && boxValues.filter((box: IBoxData) => !groupBoxData?.some((element: IGroupdBoxData) => element.idCasilla === box.idCasilla));
                }

                // // Filter by autorized boxs used 
                if (currentTab === "authorizedBoxs")
                    boxValues = authorizeData && boxValues.filter((box: IBoxData) => !authorizeData?.some((element: IAutorizedBoxData) => element.idCasillaAutorizada === box.idCasilla));

                setBoxsValues(boxValues);
            } else {
                if (!responseBoxs.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: responseBoxs.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
    }, [authorizeData, groupBoxData, currentTab])


    const getAuthorizeBoxsData = async (idCasilla: string) => {
        const response = await getAuthorizeBoxs(idCasilla);
        if (response.isOk) {
            const authorizeData: IAutorizedBoxData[] = response.responseData ? JSON.parse(response.responseData) : [];
            setAuthorizeData(authorizeData)
        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        //RMM
        return { isOk: true }
    }

    const getBoxData = async (idCasilla: string) => {
        const response = await getBoxbyId(idCasilla);
        if (response.isOk) {
            const boxData = response.responseData ? JSON.parse(response.responseData) : [];
            return boxData;
            // setBoxData(boxData)
        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        //RMM
        return { isOk: true }
    }

    const getBoxsGroupData = async (idCasilla: string) => {
        const response = await getBoxsGroup(idCasilla);
        if (response.isOk) {
            let groupBoxData = response.responseData ? JSON.parse(response.responseData) : [];
            setGroupBoxData(groupBoxData)
        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        //RMM
        return { isOk: true }
    }

    const getIpBoxsData = async (idCasilla: string) => {
        const response = await getIpsByBox(idCasilla);
        if (response.isOk) {
            let ipData = response.responseData ? JSON.parse(response.responseData) : [];
            setIpData(ipData)
        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        //RMM
        return { isOk: true }
    }

    const getNoticeBoxsData = async (idCasilla: string) => {
        const response = await getNoticesByBox(idCasilla);
        if (response.isOk) {
            let noticeData = response.responseData ? JSON.parse(response.responseData) : [];
            setNoticeData(noticeData)
        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        //RMM
        return { isOk: true }
    }

    const handleSelectTab = async (tabName: string) => {
        setCurrentTab(tabName);
        if (boxData) {
            if (tabName === "authorizedBoxs") {
                setBoxSelected(null)
                await getAuthorizeBoxsData(boxData.idCasilla);
            }

            if (tabName === "notices") {
                await getNoticeBoxsData(boxData.idCasilla);
            }

            if (tabName === "authorizedIP") {
                await getIpBoxsData(boxData.idCasilla);
            }

            if (tabName === "boxsGroup") {
                setBoxSelected(null)
                await getBoxsGroupData(boxData.idCasilla);
            }
        }

    }

    const getDomainsData = useCallback(async () => {
        // TODO: Falta incorporar el servicio que obtiene listado de dominios
        setLoading(true);
        const response = await getDomains();
        if (response.isOk) {
            let domainList = response.responseData ? JSON.parse(response.responseData) : [];
            // TODO: corregir cuando el servicio responde id: nombre (actualmente responde id, descripcion)
            setDomainsValues(domainList.map((dom: { id: string, descripcion: string }) => ({ id: dom.id, nombre: dom.descripcion })));
        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }


        setLoading(false);

    }, [])

    const handleAuthorizedBox = async (idCasilla: string, idCasillaAutorizada: string, action: BoxActions) => {
        const response = await processAuthorizedBox(idCasilla, idCasillaAutorizada, action);
        if (response.isOk) {
            setBoxSelected(null);
            notify({
                message: <>Casilla  {action === "ingresar" ? "agregada" : "eliminada"}  correctamente</>
            });
            await getAuthorizeBoxsData(idCasilla);

        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        //RMM
        return { isOk: true }
    }

    const handleNoticeBox = async (idCasilla: string, notice: INoticeData, action: BoxActions) => {
        const response = await processNoticeBox(idCasilla, notice, action);
        if (response.isOk) {
            notify({
                message: <>Aviso {action === "ingresar" ? "agregado" : "eliminado"} correctamente</>
            });

            const response = await getNoticesByBox(idCasilla);
            if (response.isOk) {
                let noticeData = response.responseData ? JSON.parse(response.responseData) : [];
                setNoticeData(noticeData)
            }

            resetFieldNotice()

        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        //RMM
        return { isOk: true }
    }

    const handleBoxGroup = async (idCasilla: string, idGrupo: string, action: BoxActions) => {
        const response = await processBoxGroup(idGrupo, idCasilla, action);
        if (response.isOk) {
            setBoxSelected(null);
            notify({
                message: <>Casilla  {action === "ingresar" ? "agregada" : "eliminada"}  correctamente</>
            });
            await getBoxsGroupData(idGrupo!);

        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        //RMM
        return { isOk: true }
    }

    const handleIPBox = async (idCasilla: string, ip: string, action: BoxActions) => {
        const response = await processIPBox(idCasilla, ip, action);
        if (response.isOk) {
            notify({
                message: <>IP {action === "ingresar" ? "agregada" : "eliminada"} correctamente</>
            });

            const response = await getIpsByBox(idCasilla);
            if (response.isOk) {
                let ipData = response.responseData ? JSON.parse(response.responseData) : [];
                setIpData(ipData)
            }

            resetFieldIP()

        } else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        //RMM
        return { isOk: true }
    }

    const handleShowConfirm = (modalType: string, data: any) => {
        let modalProps: any = { show: true };

        if (modalType === "addAuthorizedBox") {
            modalProps = {
                title: "Agregar casilla",
                body: <>¿Desea agregar la casilla <b>{data}</b>?</>,
                handleFunction: async () => await handleAuthorizedBox(boxData.idCasilla, data, "ingresar")
            }
        }
        else if (modalType === "deleteAuthorizedBox") {
            modalProps = {
                title: "Eliminar casilla",
                body: <>¿Desea eliminar la casilla <b>{data.idCasillaAutorizada}</b>?</>,
                handleFunction: async () => await handleAuthorizedBox(boxData.idCasilla, data.idCasillaAutorizada, "eliminar")
            }
        }
        else if (modalType === "addNoticeData") {
            modalProps = {
                title: "Agregar email de aviso",
                body: <>¿Desea agregar el email de aviso?</>,
                handleFunction: async () => await handleNoticeBox(boxData.idCasilla, data, "ingresar")
            }
        }
        else if (modalType === "deleteNotice") {
            modalProps = {
                title: "Eliminar email de aviso",
                body: <>¿Desea eliminar el email de aviso?</>,
                handleFunction: async () => await handleNoticeBox(boxData.idCasilla, data, "eliminar")
            }
        }
        else if (modalType === "addIPData") {
            modalProps = {
                title: "Agregar IP",
                body: <>¿Desea agregar la IP <b>{data.ip}</b>?</>,
                handleFunction: async () => await handleIPBox(boxData.idCasilla, data.ip, "ingresar")
            }
        }
        else if (modalType === "deleteIPData") {
            modalProps = {
                title: "Eliminar IP",
                body: <>¿Desea eliminar la IP <b>{data.ip}</b>?</>,
                handleFunction: async () => await handleIPBox(boxData.idCasilla, data.ip, "eliminar")
            }
        }
        else if (modalType === "addBoxGroupData") {
            modalProps = {
                title: "Agregar casilla",
                body: <>¿Desea agregar la casilla  <b>{data}</b>?</>,
                handleFunction: async () => await handleBoxGroup(data, boxData.idCasilla, "ingresar")
            }
        }
        else if (modalType === "deleteBoxGroupData") {
            modalProps = {
                title: "Eliminar casilla",
                body: <>¿Desea eliminar la casilla <b>{data}</b>?</>,
                handleFunction: async () => await handleBoxGroup(data, boxData.idCasilla, "eliminar")
            }
        }

        setModalConfirm({
            ...modalProps,
            show: true,
            onConfirm: async () => {
                modalProps.handleFunction && await modalProps.handleFunction();
                setModalConfirm(null);
            }
        });
        //RMM
        return { isOk: true }
    }

    useEffect(() => {
        if (boxData) {
            reset({
                idCasilla: boxData?.idCasilla,
                idDominio: boxData?.idDominio.toString(),
                idEstado: boxData?.idEstado,
                migrada: boxData?.migrada,
                formatoRegistroEntrada: boxData?.formatoRegistroEntrada,
                delimitadorArchivo: boxData?.delimitadorArchivo,
                delimitadorCasilla: boxData?.delimitadorCasilla,
                timeToLive: boxData?.timeToLive,
                tipoCasilla: boxData?.tipoCasilla,
                scanFile: boxData?.scanFile.toString()
            })
        }

    }, [reset, boxData]);

    useEffect(() => {
        if (!institution)
            navigate("/admin/casillas", { state: null });

    }, [navigate, institution])

    useEffect(() => {
        getDomainsData();
    }, [navigate, getDomainsData])


    useEffect(() => {
        // Hook que se llama cada vez que se actualiza una casilla autorizada
        // necesario porque hay que actualizar la lista que se muestra en el seleccionador de casillas
        if (currentTab === "authorizedBoxs")
            authorizeData && getBoxsValuesData()

        if (currentTab === "boxsGroup")
            groupBoxData && getBoxsValuesData();


    }, [authorizeData, groupBoxData, currentTab, getBoxsValuesData])

    const FormButtons = (props: { isSubmitting?: boolean, onlyBack?: boolean }) =>
        <MDBRow className='my-5 d-flex align-items' >
            {!props.onlyBack &&
                <MDBCol size='6' style={{ textAlign: 'end' }}>
                    <MDBBtn
                        color='success'
                        style={{ display: 'inline' }}
                        className='px-5 btn-prim-download'
                        rounded
                        type='submit'
                        disabled={props!.isSubmitting}>
                        {props!.isSubmitting ? <Loading color='#fff' size='sm' /> : <><MDBIcon fas icon="check" /> Aceptar</>}
                    </MDBBtn>


                </MDBCol>
            }
            <MDBCol size='6' style={{ textAlign: props.onlyBack ? 'end' : 'start' }}>
                <MDBBtn onClick={goToBack}
                    className='px-5'
                    color='success'
                    outline
                    type="button"
                >
                    <MDBIcon fas icon="arrow-left" className='px-1' />
                    Volver
                </MDBBtn>
            </MDBCol>
        </MDBRow>

    return (
        <div className="bg-white">
            <div className="container-fluid">
                <div className="grilla-content">
                    <div className="title-grilla">
                        {actionPage === "crear"
                            ? <>
                                <h1>Crear Casilla</h1>
                                <Container>
                                    <Paper withBorder className='info-header' p="lg" radius="lg">
                                        <Grid>
                                            <Grid.Col xs={2}><b>Institución</b></Grid.Col>
                                            <Grid.Col xs={8}>{institution.nombre}</Grid.Col>
                                        </Grid>
                                    </Paper>
                                </Container>
                            </>

                            : <>
                                <h1>Editar Casilla</h1>
                                <Container>
                                    <Paper withBorder className='info-header' p="lg" radius="lg">
                                        <Grid>
                                            <Grid.Col xs={2}><b>Casilla</b></Grid.Col>
                                            <Grid.Col xs={8}>{boxData?.idCasilla}</Grid.Col>
                                        </Grid>
                                        <Grid>
                                            <Grid.Col xs={2}><b>Institución</b></Grid.Col>
                                            <Grid.Col xs={8}>{institution?.nombre}</Grid.Col>
                                        </Grid>
                                    </Paper>
                                </Container>

                            </>}
                    </div>

                    {modalConfirm &&
                        <ModalConfirm
                            title={modalConfirm.title}
                            body={modalConfirm.body}
                            showConfirm={modalConfirm.show}
                            onClose={() => setModalConfirm(null)}
                            onConfirm={modalConfirm.onConfirm}
                        />
                    }
                    <MDBTabs className='mb-3'>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleSelectTab('information')} active={currentTab === 'information'}>
                                Información
                            </MDBTabsLink>
                        </MDBTabsItem>

                        {actionPage === "editar" &&
                            <>
                                {boxData?.tipoCasilla === "S" ?
                                    <>
                                        <MDBTabsItem>
                                            <MDBTabsLink onClick={() => handleSelectTab('authorizedBoxs')} active={currentTab === 'authorizedBoxs'}>
                                                Casillas Autorizadas
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                        <MDBTabsItem>
                                            <MDBTabsLink onClick={() => handleSelectTab('notices')} active={currentTab === 'notices'}>
                                                Avisos
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                        <MDBTabsItem>
                                            <MDBTabsLink onClick={() => handleSelectTab('authorizedIP')} active={currentTab === 'authorizedIP'}>
                                                IP Autorizadas
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                    </>
                                    :
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => handleSelectTab('boxsGroup')} active={currentTab === 'boxsGroup'}>
                                            Grupo de Casillas
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                }
                            </>
                        }
                    </MDBTabs>
                    {loading ? <Loading /> :
                        <MDBTabsContent>
                            <MDBTabsPane show={currentTab === 'information'}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <MDBTabsContent className='mt-3'>
                                        <MDBRow className='my-5 d-flex align-items' >
                                            <MDBCol>
                                                <div className="row">
                                                    <div className="col col-details">
                                                        {actionPage === "crear" &&
                                                            <FormInput
                                                                size={12}
                                                                errors={errors}
                                                                control={control}
                                                                name='idCasilla'
                                                                message='Nombre es requerido'
                                                                extraMessage={<>* Solo se permite ingresar minúsculas y números</>}
                                                                type='idCasilla'
                                                                label='Nombre'
                                                                maxLength={20}
                                                                rules={{
                                                                    validate: {
                                                                        hasCharacter: (value) => /[a-z]/.test(value) || 'Debe contener al menos un carácter',
                                                                        max: (value) => /^[a-zA-Z0-9_.-]{0,20}$/.test(value) || 'Deben ser máximo 20 caracteres',
                                                                        min: (value) => /^[a-zA-Z0-9_.-]{5,}$/.test(value) || 'Deben ser mínimo 5 caracteres'
                                                                    },
                                                                    pattern: {
                                                                        value: /^[a-z0-9]*$/,
                                                                        message:
                                                                        "Solo se permite ingresar minúsculas y números",
                                                                    },
                                                                }}
                                                            />
                                                        }

                                                        <FormInput
                                                            size={5}
                                                            errors={errors}
                                                            control={control}
                                                            name='idDominio'
                                                            message='Dominio es requerido'
                                                            label='Dominio'
                                                            type='select'
                                                            listValues={domainsValues}
                                                        />

                                                        {watch("tipoCasilla") === "S" &&
                                                            <>

                                                                {actionPage === "editar" &&
                                                                    <>
                                                                        <FormRowValue label="Estado" value={getStatusEnabled(boxData?.idEstado?.toString())} />

                                                                        {boxData?.migrada === "-" ?
                                                                            <FormRowValue label="Tipo" value={"Nueva"} />
                                                                            :
                                                                            <FormInput
                                                                                errors={errors}
                                                                                control={control}
                                                                                name='migrada'
                                                                                message='Tipo es requerido'
                                                                                label='Tipo'
                                                                                type='select'
                                                                                listValues={migratedTypesValues}
                                                                            />

                                                                        }
                                                                    </>
                                                                }

                                                                <FormInput
                                                                    errors={errors}
                                                                    control={control}
                                                                    name='formatoRegistroEntrada'
                                                                    message='Formato Registro es requerido'
                                                                    label='Formato Registro'
                                                                    type='radio'
                                                                    listValues={registerFormatValues}
                                                                />

                                                                <FormInput
                                                                    size={5}
                                                                    errors={errors}
                                                                    control={control}
                                                                    name='delimitadorArchivo'
                                                                    message='Delimitador Archivos es requerido'
                                                                    label='Delimitador de Archivos'
                                                                    type='select'
                                                                    listValues={fileDelimiterValues}

                                                                />

                                                                <FormInput
                                                                    size={5}
                                                                    errors={errors}
                                                                    control={control}
                                                                    name='delimitadorCasilla'
                                                                    message='Delimitador de Casillas es requerido'
                                                                    label='Delimitador de Casillas'
                                                                    type='radio'
                                                                    listValues={boxDelimiterValues}

                                                                />

                                                                <FormInput
                                                                    size={5}
                                                                    errors={errors}
                                                                    control={control}
                                                                    name='timeToLive'
                                                                    message='Tiempo duración Transmisiones es requerido'
                                                                    label='Tiempo duración Transmisiones'
                                                                    type='number'
                                                                    maxLength={3}
                                                                    rules={{
                                                                        min: {
                                                                            value: 1,
                                                                            message: "Debe ser un número mayor a 0"
                                                                        },
                                                                        max: {
                                                                            value: 999,
                                                                            message: "Debe ser un número menor a 999"
                                                                        }
                                                                    }}
                                                                />



                                                                <FormInput
                                                                    errors={errors}
                                                                    control={control}
                                                                    name='scanFile'
                                                                    message='Scan File es requerido'
                                                                    label='Scan File'
                                                                    type='radio'
                                                                    listValues={boxScanFileValues}
                                                                />
                                                                {boxData !== null &&
                                                                    <>
                                                                        {actionPage === "editar" &&
                                                                            <FormRowValue label="Fecha Creación" value={boxData?.fechaCreacion ? boxData.fechaCreacion : ""} />
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                            </MDBCol>
                                        </MDBRow>
                                        <FormButtons isSubmitting={isSubmitting} />
                                    </MDBTabsContent>
                                </form>

                            </MDBTabsPane>
                            <MDBTabsPane show={currentTab === 'authorizedBoxs'}>
                                <MDBTabsContent className='mt-3'>
                                    <MDBRow className='my-5 d-flex align-items' >
                                        <MDBCol size='8'>
                                            <div className="row">
                                                <div className="col col-details">
                                                    <div className="row input-content mg-bttn16">
                                                        <div className="col">Casillas</div>
                                                        <div className="col">
                                                            <Select
                                                                radius={8}
                                                                onChange={handleSelectBox}
                                                                data={boxsValues?.length ? boxsValues.map(i => i.idCasilla) : []}
                                                                searchable
                                                                nothingFound="Sin información"
                                                                value={boxSelected?.idCasilla || null}
                                                            />
                                                        </div>
                                                        <MDBBtn
                                                            color="success"
                                                            className='px-3'
                                                            type='button'
                                                            onClick={() => boxSelected !== null && handleShowConfirm('addAuthorizedBox', boxSelected.idCasilla)}
                                                            disabled={boxSelected ? false : true}
                                                        >
                                                            <MDBIcon fas icon="plus" className='mx-2' />
                                                            Agregar
                                                        </MDBBtn>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <p />
                                                <div className="col">
                                                    <table className="table" style={{ width: "710px" }}>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Casilla</th>
                                                                <th scope="col" className="text-center">Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {authorizeData?.map((data: IAutorizedBoxData, key: number) =>
                                                                <tr key={key}>
                                                                    <th scope="row">{data.idCasillaAutorizada}</th>
                                                                    <td className='text-center'>
                                                                        <div className="actions">
                                                                            <div className="ico-action">
                                                                                {data.habilitado
                                                                                    ? <MDBBtn
                                                                                        onClick={() => handleShowConfirm('deleteAuthorizedBox', data)}
                                                                                        color='none'
                                                                                        tag={'a'}
                                                                                        className='eliminar-on'>
                                                                                        &nbsp;
                                                                                    </MDBBtn>
                                                                                    : <span className="eliminar-off"></span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <FormButtons isSubmitting={isSubmitting} onlyBack />
                                </MDBTabsContent>
                            </MDBTabsPane>
                            <MDBTabsPane show={currentTab === 'notices'}>
                                <form onSubmit={handleSubmitFormNotice(async (data) => handleShowConfirm('addNoticeData', data))}>
                                    <MDBTabsContent className='mt-3'>
                                        <MDBRow className='my-5 d-flex align-items' >
                                            <MDBCol size='8'>
                                                <div className="row">
                                                    <div className="col col-details">
                                                        <MDBRow className='d-flex align-items'>
                                                            <MDBCol size='8'>
                                                                {
                                                                    <FormInput
                                                                        errors={errorsFromNotice}
                                                                        control={controlFromNotice}
                                                                        name='datoAviso'
                                                                        message='Email de aviso es requerido'
                                                                        label='Email de aviso'
                                                                        rules={{
                                                                            pattern: {
                                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                                message: "Email es inválido"
                                                                            },
                                                                            validate: {
                                                                                words: (value) => REGEX.test(value) || 'Existen palabras no permitidas'
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            </MDBCol>
                                                            <MDBCol size='2'>
                                                                <div className={`row mg-bttn16`} style={{ marginBottom: 30 }}>
                                                                    <MDBBtn
                                                                        color='success'
                                                                        rounded
                                                                        type='submit'>
                                                                        <MDBIcon fas icon="plus" className='mx-2' />
                                                                        Agregar
                                                                    </MDBBtn>
                                                                </div>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <p />
                                                    <div className="col">
                                                        <table className="table" style={{ width: "710px" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Email de aviso</th>
                                                                    <th scope="col" className="text-center">Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {noticeData?.map((notice: INoticeData, key: number) =>
                                                                    <tr key={key}>
                                                                        <td>{notice.datoAviso}</td>
                                                                        <td className='text-center'>
                                                                            <div className="actions">
                                                                                <div className="ico-action">
                                                                                    {notice.habilitado
                                                                                        ? <MDBBtn
                                                                                            onClick={() => handleShowConfirm('deleteNotice', notice)}
                                                                                            color='none'
                                                                                            tag={'a'}
                                                                                            className='eliminar-on'>
                                                                                            &nbsp;
                                                                                        </MDBBtn>
                                                                                        : <span className="eliminar-off"></span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </MDBCol>
                                        </MDBRow>
                                        <FormButtons isSubmitting={isSubmitting} onlyBack />
                                    </MDBTabsContent>
                                </form>
                            </MDBTabsPane>
                            <MDBTabsPane show={currentTab === 'authorizedIP'}>
                                <form onSubmit={handleSubmitFormAuthorizedIP(async (data) => handleShowConfirm('addIPData', data))}>
                                    <MDBTabsContent className='mt-3'>
                                        <MDBRow className='my-5 d-flex align-items' >
                                            <MDBCol size='8'>
                                                <div className="row">
                                                    <div className="col col-details">
                                                        <MDBRow className='d-flex align-items'>
                                                            <MDBCol size='8'>
                                                                <FormInput
                                                                    errors={errorsFromAuthorizeIP}
                                                                    control={controlFromAuthorizeIP}
                                                                    name='ip'
                                                                    message='IP es requerido'
                                                                    label='Ingrese IP'
                                                                    rules={{
                                                                        pattern: {
                                                                            value: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i,
                                                                            message: "IP es inválida"
                                                                        }
                                                                    }}
                                                                />
                                                            </MDBCol>
                                                            <MDBCol size='2'>
                                                                <div className={`row mg-bttn16`} style={{ marginBottom: 30 }}>
                                                                    <MDBBtn
                                                                        color='success'
                                                                        rounded
                                                                        type='submit'>
                                                                        <MDBIcon fas icon="plus" className='mx-2' />
                                                                        Agregar
                                                                    </MDBBtn>
                                                                </div>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <p />
                                                    <div className="col">
                                                        <table className="table" style={{ width: "710px" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">IP Autorizada</th>
                                                                    <th scope="col" className="text-center">Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ipData?.map((data: IIPData, key: number) =>
                                                                    <tr key={key}>
                                                                        <th scope="row">{data.ip}</th>
                                                                        <td className='text-center'>
                                                                            <div className="actions">
                                                                                <div className="ico-action">
                                                                                    {data.habilitado
                                                                                        ? <MDBBtn
                                                                                            onClick={() => handleShowConfirm('deleteIPData', data)}
                                                                                            color='none'
                                                                                            tag={'a'}
                                                                                            className='eliminar-on'>
                                                                                            &nbsp;
                                                                                        </MDBBtn>
                                                                                        : <span className="eliminar-off"></span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </MDBCol>
                                        </MDBRow>
                                        <FormButtons isSubmitting={isSubmitting} onlyBack />
                                    </MDBTabsContent>
                                </form>
                            </MDBTabsPane>
                            <MDBTabsPane show={currentTab === 'boxsGroup'}>
                                <MDBTabsContent className='mt-3'>
                                    <MDBRow className='my-5 d-flex align-items' >
                                        <MDBCol size='8'>
                                            <div className="row">
                                                <div className="col col-details">
                                                    <div className="row input-content mg-bttn16">
                                                        <div className="col">Casillas</div>
                                                        <div className="col">
                                                            <Select
                                                                radius={8}
                                                                onChange={handleSelectBox}
                                                                data={boxsValues?.length ? boxsValues.map(i => i.idCasilla) : []}
                                                                searchable
                                                                nothingFound="Sin información"
                                                                value={boxSelected?.idCasilla || null}
                                                            />
                                                        </div>
                                                        <MDBBtn
                                                            color={boxSelected ? 'none' : 'white'}
                                                            className='px-3'
                                                            type='button'
                                                            onClick={() => boxSelected !== null && handleShowConfirm('addBoxGroupData', boxSelected.idCasilla)}
                                                            disabled={boxSelected ? false : true}
                                                        >
                                                            <MDBIcon fas icon="search" className='m-2' />
                                                            Agregar
                                                        </MDBBtn>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <p />
                                                <div className="col">
                                                    <table className="table" style={{ width: "710px" }}>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Casilla</th>
                                                                <th scope="col" className="text-center">Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {groupBoxData?.map((data: IGroupdBoxData, key: number) =>
                                                                <tr key={key}>
                                                                    <th scope="row">{data.idCasilla}</th>
                                                                    <td className='text-center'>
                                                                        <div className="actions">
                                                                            <div className="ico-action">
                                                                                {data.habilitado
                                                                                    ? <MDBBtn
                                                                                        onClick={() => handleShowConfirm('deleteBoxGroupData', data.idCasilla)}
                                                                                        color='none'
                                                                                        tag={'a'}
                                                                                        className='eliminar-on'>
                                                                                        &nbsp;
                                                                                    </MDBBtn>
                                                                                    : <span className="eliminar-off"></span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <FormButtons isSubmitting={isSubmitting} onlyBack />
                                </MDBTabsContent>
                            </MDBTabsPane>
                        </MDBTabsContent>
                    }
                </div>
            </div>
        </div>
    );
}