import { Box } from "@mantine/core";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBRow,
  MDBTabsContent,
  MDBTooltip,
} from "mdb-react-ui-kit";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  deleteBoxPublicKey,
  getBoxPublicKeys,
  saveBoxPublicKey,
} from "../utils/middleware";
import { notify } from "../utils/notifications";
import { FormInput } from "./FormInput";
import Loading from "./Loading";

const maxLengthInputCharacter = 2492;

export const ModalFormKeys: React.FC<{
  title?: ReactElement | string;
  data?: any;
  showConfirm: boolean;
  subTitle?: ReactElement | string;
  onClose: () => void;
}> = (props) => {
  const theme = useTheme();
  const [publicBoxKeys, setPublicBoxKeys] = useState([]);
  const [loadingId, setLoadingId] = useState<null | number>(null);
  const { onClose, title, subTitle, showConfirm, data } = props;
  const [showForm, setShowForm] = useState(false);
  const {
    handleSubmit,
    errors,
    formState: { isSubmitting },
    control,
  } = useForm({
    defaultValues: {
      title: "",
      key: "",
    },
  });

  const handleDeleteKey = async (idCasilla: string, idKey: number) => {
    setLoadingId(idKey);
    const response = await deleteBoxPublicKey(idCasilla, idKey);
    if (response === null) {
      notify({
        title: "Ha ocurrido un error",
        message: "No se puede acceder al Servicio",
        notificationType: "error",
      });
    } else {
      setLoadingId(null);
      if (response.isOk) {
        notify({ message: <>LLave eliminada correctamente</> });
        getPublicKeys(data.idCasilla);
      } else {
        notify({
          title: "Ha ocurrido un error",
          message: response.errorMessage ?? "",
          notificationType: "error",
        });
      }
    }
  };

  const handleNewPublicKey = async (formData: {
    title: string;
    key: string;
  }) => {
    const response = await saveBoxPublicKey(
      data.idCasilla,
      formData.title,
      formData.key
    );
    if (response === null) {
      notify({
        title: "Ha ocurrido un error",
        message: "No se puede acceder al Servicio",
        notificationType: "error",
      });
    } else {
      if (response.isOk) {
        getPublicKeys(data.idCasilla);
        setShowForm(false);
        notify({ message: <>LLave agregada correctamente</> });
      } else {
        notify({
          title: "Ha ocurrido un error",
          message: response.errorMessage ?? "",
          notificationType: "error",
        });
      }
    }
  };

  const getPublicKeys = useCallback(async (idCasilla: string) => {
    const response = await getBoxPublicKeys(idCasilla);
    if (response.isOk) {
      const publicKeysData = response.responseData
        ? JSON.parse(response.responseData)
        : [];
      setPublicBoxKeys(publicKeysData);
    } else {
      if (!response.notify)
        notify({
          title: "Ha ocurrido un error",
          message: response.errorMessage ?? "",
          notificationType: "error",
        });
    }
  }, []);

  useEffect(() => {
    getPublicKeys(data.idCasilla);
  }, [data.idCasilla, getPublicKeys]);

  return (
    <>
      <MDBModal show={showConfirm} staticBackdrop>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{title}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody className="text-center mt-1">
              <MDBTabsContent className="mt-1">
                <MDBRow>
                  <MDBCol size="8" style={{ marginBottom: 0 }}>
                    {subTitle}
                  </MDBCol>
                  <MDBCol size="4" style={{ marginBottom: 0 }}>
                    <IconButton
                      sx={{
                        border: `1px solid ${theme.palette.primary.main}`,
                        color: theme.palette.primary.main,
                        bgcolor: theme.palette.common.white,
                      }}
                      aria-label="keys"
                      size="small"
                      onClick={() => setShowForm(!showForm)}
                    >
                      <Box p={2}>
                        {showForm ? (
                          <>
                            <FormatListBulletedIcon /> Ver listado
                          </>
                        ) : (
                          <>
                            <PlaylistAddIcon /> Agregar nueva llave
                          </>
                        )}
                      </Box>
                    </IconButton>
                  </MDBCol>

                  <MDBCol size="12">
                    {showForm ? (
                      <form>
                        <div className="row">
                          <div
                            className="col col-details"
                            style={{ textAlign: "start", marginTop: 10 }}
                          >
                            <FormInput
                              size={10}
                              errors={errors}
                              control={control}
                              name="title"
                              message="Título es requerido"
                              label="Título"
                              maxLength={50}
                            />
                            <FormInput
                              size={10}
                              maxLength={maxLengthInputCharacter}
                              errors={errors}
                              control={control}
                              name="key"
                              type="textarea"
                              message="Clave es requerida"
                              label="Clave"
                              rules={{
                                validate: {                                  
                                  lineBreak: (value) =>
                                    !/\r?\n/.test(value) ||
                                    "No se permiten saltos de línea",
                                  max: (value) =>
                                    new RegExp(
                                      `^.{0,${maxLengthInputCharacter}}$`
                                    ).test(value) ||
                                    `Deben ser máximo ${maxLengthInputCharacter} caracteres, (${value.length} caracteres ingresados)`,
                                },
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    ) : (
                      <table className="table" style={{ tableLayout: "fixed" }}>
                        <thead>
                          <tr>
                            <th scope="col">Fecha creación</th>
                            <td style={{ textAlign: "left" }}>Título</td>
                            <td style={{ textAlign: "left" }}>LLave</td>
                            <td>Acciones</td>
                          </tr>
                        </thead>
                        <tbody>
                          {publicBoxKeys.length ? (
                            publicBoxKeys.map((key: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <th>
                                    {key?.fechaCreacion &&
                                      dayjs(key.fechaCreacion).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                  </th>
                                  <td
                                    style={{
                                      textAlign: "left",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <MDBTooltip tag="span" title={key.titulo}>
                                      {key.titulo.slice(0, 50)}
                                    </MDBTooltip>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "left",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <MDBTooltip tag="span" title={key.key}>
                                      {key.key.slice(0, 50)}
                                    </MDBTooltip>
                                  </td>
                                  <td>
                                    <div className="actions">
                                      <div className="ico-action">
                                        {loadingId === key.id ? (
                                          <Loading />
                                        ) : (
                                          <MDBPopover
                                            placement="left-start"
                                            size="sm"
                                            style={{
                                              color: "red",
                                              background: "white",
                                            }}
                                            btnChildren={
                                              <MDBIcon
                                                id={`btn-${key.id}`}
                                                far
                                                icon="trash-alt"
                                              />
                                            }
                                          >
                                            <MDBPopoverHeader>
                                              {key.titulo}
                                            </MDBPopoverHeader>
                                            <MDBPopoverBody>
                                              <MDBRow>
                                                <MDBCol
                                                  size="12"
                                                  className="mb-3"
                                                >
                                                  ¿Desea eliminar la llave
                                                  seleccionada?
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <MDBCol
                                                  size="6"
                                                  style={{ marginBottom: 0 }}
                                                >
                                                  <MDBBtn
                                                    color="success"
                                                    onClick={() =>
                                                      handleDeleteKey(
                                                        key.idCasilla,
                                                        key.id
                                                      )
                                                    }
                                                  >
                                                    Aceptar
                                                  </MDBBtn>
                                                </MDBCol>
                                                <MDBCol
                                                  size="6"
                                                  style={{ marginBottom: 0 }}
                                                >
                                                  <MDBBtn
                                                    color="muted"
                                                    onClick={() => {
                                                      document
                                                        .getElementById(
                                                          `btn-${key.id}`
                                                        )
                                                        ?.click();
                                                    }}
                                                  >
                                                    Cancelar
                                                  </MDBBtn>
                                                </MDBCol>
                                              </MDBRow>
                                            </MDBPopoverBody>
                                          </MDBPopover>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td style={{ border: "none" }} colSpan={4}>
                                Sin información
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBTabsContent>
            </MDBModalBody>
            <MDBModalFooter style={{ justifyContent: "center" }}>
              {!showForm ? (
                <MDBBtn color="white" type="button" onClick={onClose}>
                  Cerrar
                </MDBBtn>
              ) : isSubmitting ? (
                <div className="text-center">
                  <Loading />
                </div>
              ) : (
                <>
                  <MDBBtn color="white" type="button" onClick={onClose}>
                    Cancelar
                  </MDBBtn>
                  <MDBBtn
                    style={{ background: "#18BA95" }}
                    onClick={handleSubmit(handleNewPublicKey)}
                  >
                    Aceptar
                  </MDBBtn>
                </>
              )}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
