// import './../css/style.css';
import { Select, TextInput } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { Chip, Link, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { MDBBtn, MDBCol, MDBDropdown, MDBDropdownToggle, MDBIcon, MDBRow, MDBTabsContent } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { DataTable } from '../components/DataTable';
import Loading from '../components/Loading';
import { IBoxData, IKeyValueData, ITransmision } from '../interfaces';
import { getBoxsByInstitution, getFilteredTransmission } from '../utils/middleware';
import { notify } from '../utils/notifications';
import { TransmissionDetail } from './TransmissionDetail';

const DEFAULT_SELECT_VALUE = { id: '', nombre: 'Todas' };

export const RedbancSearch: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [searchStartDate, setSearchStartDate] = useState<Date | null>(dayjs().startOf('month').toDate());
    const [searchStartTime, setSearchStartTime] = useState(new Date(new Date().setHours(0, 0, 0)));
    const [searchEndDate, setSearchEndDate] = useState<Date | null>(new Date(new Date().setHours(0, 0, 0)));
    const [searchEndTime, setSearchEndTime] = useState(new Date(new Date().setHours(23, 59, 59)));
    const [boxsValues, setBoxsValues] = useState<IKeyValueData[]>([]);
    const [searchBoxSelectedFrom, setSearchBoxSelectedFrom] = useState<IKeyValueData>(DEFAULT_SELECT_VALUE);
    const [searchBoxSelectedTo, setSearchBoxSelectedTo] = useState<IKeyValueData>(DEFAULT_SELECT_VALUE);
    const [searchBoxStatus, setSearchBoxStatus] = useState<string | null>('');
    const [searchNameValue, setSearchNameValue] = useState('');
    const [transmissions, setTransmissions] = useState<ITransmision[]>([]);
    const [transmissionSelected, setTransmissionSelected] = useState<ITransmision | null>(null);
    const theme = useTheme();

    const handleStartDate = (date: Date) => {
        setSearchStartDate(date);
        if (searchEndDate && (searchEndDate < date))
            setSearchEndDate(null);
    }

    const handleSearch = async () => {
        const startDate = dayjs(searchStartDate)
            .add(searchStartTime.getHours(), "hours")
            .add(searchStartTime.getMinutes(), "minutes")
            .format('DD/MM/YYYY HH:mm:ss');

        const endDate = dayjs(searchEndDate)
            .add(searchEndTime.getHours(), "hours")
            .add(searchEndTime.getMinutes(), "minutes")
            .format('DD/MM/YYYY HH:mm:ss');

        const filters = {
            casillaOrigen: searchBoxSelectedFrom?.id || "",
            casillaDestino: searchBoxSelectedTo?.id || "",
            fechaDesde: startDate,
            fechaHasta: endDate,
            idEstado: searchBoxStatus?.toString() || "",
            nombreArchivo: searchNameValue
        }
        setLoading(true);
        const response = await getFilteredTransmission({ ...filters });
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                const transmissionsData = response.responseData ? JSON.parse(response.responseData) : [];
                setTransmissions(transmissionsData);
            }
            else {
                if (!response.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: response.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
        setLoading(false);
        //RMM
        return { isOk: true }

    }

    const handleSelectTransmission = (idTransmission: number) => {
        const transmission: any = transmissions.filter((t: ITransmision) => t.idTransmision === idTransmission);
        setTransmissionSelected(transmission[0]);
    };

    const getAllBoxsData = useCallback(async () => {
        setLoading(true);
        const response = await getBoxsByInstitution('0');
        const boxList = response.responseData ? JSON.parse(response.responseData) : [];
        const boxsValuesList = boxList.map((box: IBoxData) => ({ id: box.idCasilla, nombre: box.idCasilla }));
        setBoxsValues(boxsValuesList.concat(DEFAULT_SELECT_VALUE));
        setLoading(false);
    }, [])

    const getDownloadData = (transmissions: ITransmision[]) => {
        return transmissions.map((t: ITransmision) => {
            const fileSize = t.tamanoArchivo === undefined
                ? "Sin información"
                : t.tamanoArchivo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " bytes";

            const fileSizeDestination = t.pesoDestino === undefined
                ? "Sin información"
                : t.pesoDestino.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " bytes";

            const fechaInicioFormat = t.fechaInicio && dayjs(t.fechaInicio).format('DD/MM/YYYY HH:mm:ss');
            const fechaTerminoFormat = t.fechaTermino && dayjs(t.fechaTermino).format('DD/MM/YYYY HH:mm:ss');
            const fechaEliminacionFormat = t.fechaEliminacion && dayjs(t.fechaEliminacion).format('DD/MM/YYYY HH:mm:ss');
            const conversion = `${t.codificacionOrigen || ""}${t.formatoRegistroOrigen || ""}/${t.codificacionDestino || ""}${t.formatoRegistroDestino || ""}`;

            return {
                'Canal': t.idCanal,
                'Server': t.server,
                'Archivo': t.archivoDato,
                'Fecha Envío': fechaInicioFormat,
                'Fecha Término': fechaTerminoFormat,
                'Origen': t.idCasillaOrigen,
                'Tamaño': fileSize,
                'Destino': t.idCasillaDestino,
                'Tamaño Destino': fileSizeDestination,
                'Conversion': conversion,
                'Estado': t.estadoTransmision === 0 ? "exitoso" : "fallido",
                'Fecha de Eliminación': fechaEliminacionFormat,
            }
        })
    }

    const datePickerPricing = (id: string, value: Date, onChange: (date: Date) => void) => {
        return <>
            <MDBRow className='d-flex align-items-end'>
                <MDBCol size='8' style={{ paddingRight: 4 }}>
                    <DatePicker
                        minDate={id === "searchEndDate" && searchStartDate ? dayjs(searchStartDate).toDate() : undefined}
                        inputFormat="DD/MM/YYYY"
                        labelFormat="DD/MM/YYYY"
                        value={value}
                        onChange={onChange}
                        icon={<MDBIcon far icon="calendar-alt" />}
                        locale="es"
                        styles={() => ({
                            day: { width: "38px !important" },
                        })}
                        label={id === "searchEndDate" ? "Fecha hasta" : "Fecha desde"} />
                </MDBCol>
                <MDBCol size='4' style={{ paddingLeft: 0 }}>
                    <TimeInput
                        label="Hora"
                        value={id === "searchStartDate" ? searchStartTime : searchEndTime}
                        onChange={id === "searchStartDate" ? setSearchStartTime : setSearchEndTime}
                    />
                </MDBCol>
            </MDBRow>
        </>
    }

    useEffect(() => {
        getAllBoxsData();
    }, [getAllBoxsData])

    return (
        <div className="bg-white">
            <div className="container-fluid">
                <div className="grilla-content">
                    <div className="title-grilla">
                        <h1>Consulta de Transmisiones</h1>
                    </div>

                    <MDBRow className='mt-5 d-flex align-items-end' >
                        <MDBCol size='2'>
                            <Select
                                radius={8}
                                label="Casilla origen"
                                onChange={(b) => setSearchBoxSelectedFrom(boxsValues[boxsValues.findIndex(box => box.nombre === b)])}
                                data={boxsValues.length ? boxsValues.map(i => i.nombre) : []}
                                value={searchBoxSelectedFrom?.nombre}
                                searchable
                                defaultValue={searchBoxSelectedFrom?.nombre}
                                nothingFound="Sin información"
                            />
                        </MDBCol>
                        <MDBCol size='2'>
                            <Select
                                radius={8}
                                label="Casilla destino"
                                onChange={(b) => setSearchBoxSelectedTo(boxsValues[boxsValues.findIndex(box => box.nombre === b)])}
                                data={boxsValues.length ? boxsValues.map(i => i.nombre) : []}
                                value={searchBoxSelectedTo?.nombre}
                                searchable
                                nothingFound="Sin información"
                            />
                        </MDBCol>
                        <MDBCol size='2'>
                            <TextInput
                                value={searchNameValue}
                                onChange={(event) => setSearchNameValue(event.currentTarget.value)}
                                label="Nombre Archivo" />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='mt-2 d-flex align-items-end' >
                        <MDBCol size='2'>
                            <Select
                                radius={8}
                                onChange={setSearchBoxStatus}
                                data={[{ value: '0', label: 'Exitoso' }, { value: '1', label: 'Fallido' }, { value: '', label: 'Todos' }]}
                                label="Estado"
                                nothingFound="Sin información"
                                value={searchBoxStatus} />
                        </MDBCol>
                        <MDBCol size='2'>
                            {datePickerPricing("searchStartDate", searchStartDate!, handleStartDate)}
                        </MDBCol>
                        <MDBCol size='2'>
                            {datePickerPricing("searchEndDate", searchEndDate!, setSearchEndDate)}
                        </MDBCol>
                        <MDBCol size='2'>
                            <MDBBtn color='white' className='px-4 mx-3' onClick={handleSearch} disabled={!searchStartDate || !searchEndDate}>
                                Buscar <MDBIcon fas icon="search" />
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                    {
                        transmissionSelected
                            ? <div className="pt-5">
                                <div className="bg-white">
                                    <div className="container-fluid">
                                        <TransmissionDetail onReturn={() => setTransmissionSelected(null)} transmission={transmissionSelected} />
                                    </div>
                                </div>
                            </div>
                            : <div className="text-center pt-5">
                                {loading ? <Loading /> :
                                    <MDBTabsContent className='mt-3'>
                                        <DataTable
                                            rows={transmissions.map((transmission: ITransmision) => {
                                                const {
                                                    tamanoArchivo,
                                                    fechaInicio,
                                                    fechaEliminacion,
                                                    fechaTermino,
                                                    codificacionOrigen,
                                                    formatoRegistroOrigen,
                                                    codificacionDestino,
                                                    formatoRegistroDestino,
                                                    pesoDestino,
                                                    server
                                                } = transmission;

                                                const fileSize =
                                                    tamanoArchivo === undefined
                                                        ? "Sin información"
                                                        : tamanoArchivo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " bytes";

                                                const fileSizeDestination =
                                                    pesoDestino === undefined
                                                        ? "Sin información"
                                                        : pesoDestino.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " bytes";

                                                const estadoTransmision =
                                                    transmission.estadoTransmision === 0
                                                        ? "exitoso"
                                                        : "fallido";


                                                const fechaInicioFormat = fechaInicio && dayjs(fechaInicio).format('DD/MM/YYYY HH:mm:ss');
                                                const fechaTerminoFormat = fechaTermino && dayjs(fechaTermino).format('DD/MM/YYYY HH:mm:ss');
                                                const fechaEliminacionFormat = fechaEliminacion && dayjs(fechaEliminacion).format('DD/MM/YYYY HH:mm:ss');
                                                const conversion = `${codificacionOrigen || ""}${formatoRegistroOrigen || ""}/${codificacionDestino || ""}${formatoRegistroDestino || ""}`;

                                                return {
                                                    ...transmission,
                                                    fechaInicioFormat,
                                                    fechaTerminoFormat,
                                                    fechaEliminacionFormat,
                                                    conversion,
                                                    fileSize,
                                                    fileSizeDestination,
                                                    server,
                                                    estadoTransmision,
                                                    id: transmission.idTransmision
                                                }

                                            })}

                                            columns={[
                                                { field: 'idCanal', headerName: 'Canal', minWidth: 80 },
                                                { field: 'server', headerName: 'Server', minWidth: 100 },
                                                {
                                                    field: 'archivoDato', headerName: 'Archivo', minWidth: 200,
                                                    renderCell: (params) => (
                                                        <Link
                                                            color={theme.palette.primary.main}
                                                            component="button"
                                                            variant="body2"
                                                            onClick={() => handleSelectTransmission(params.row.idTransmision)}
                                                        >
                                                            {params.row.archivoDato}
                                                        </Link>
                                                    )
                                                },
                                                { field: 'fechaInicioFormat', headerName: 'Fecha Envío', minWidth: 160 },
                                                { field: 'fechaTerminoFormat', headerName: 'Fecha Término', minWidth: 160 },
                                                { field: 'idCasillaOrigen', headerName: 'Origen', minWidth: 200 },
                                                { field: 'fileSize', headerName: 'Tamaño', minWidth: 125 },
                                                { field: 'idCasillaDestino', headerName: 'Destino', minWidth: 200 },
                                                { field: 'fileSizeDestination', headerName: 'Tamaño Destino', minWidth: 125 },
                                                { field: 'conversion', headerName: 'Conversión', minWidth: 100 },
                                                {
                                                    field: 'estadoTransmision', headerName: 'Estado', minWidth: 100,
                                                    align: "center",
                                                    headerAlign: 'center',
                                                    renderCell: (params) => {
                                                        return <Chip
                                                            size='small'
                                                            label={params.row.estadoTransmision.toUpperCase()}
                                                            color={
                                                                params.row.estadoTransmision === 'exitoso'
                                                                    ? 'success'
                                                                    : 'warning'}
                                                            variant="outlined"
                                                        />
                                                    }
                                                },
                                                { field: 'fechaEliminacionFormat', headerName: 'Fecha de Eliminación', minWidth: 160 },

                                            ]} />

                                        <MDBRow className='d-flex align-items' >
                                            {transmissions.length > 0 &&
                                                <MDBCol size='2'>
                                                    <div className="paginador">
                                                        <nav>
                                                            <ul className="pagination">
                                                                <MDBDropdown style={{ background: 'none' }} className="dropdown-remove-arrow">
                                                                    <MDBDropdownToggle className="px-3 " style={{ minWidth: "max-content" }} color='none'>
                                                                        <CsvDownloader
                                                                            filename="transmisiones_sti"
                                                                            extension=".csv"
                                                                            separator=","
                                                                            datas={getDownloadData(transmissions) as any}>
                                                                            <span className='px-1'>Descargar</span> <MDBIcon size='1x' fas icon="file-csv" />
                                                                        </CsvDownloader>
                                                                    </MDBDropdownToggle>
                                                                </MDBDropdown>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </MDBCol>
                                            }
                                        </MDBRow>
                                    </MDBTabsContent>
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}