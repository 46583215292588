import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBIcon } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import './../css/style.css';

const Unauthorized: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="d-flex align-items-center justify-content-center text-center">
            <MDBCard>
                <MDBCardBody>
                    <MDBIcon fas icon="user-lock" size='5x' className='py-5' />
                    <MDBCardTitle>No puedes acceder a esta página</MDBCardTitle>
                    <MDBCardText>
                        Presiona el siguiente botón para volver.
                    </MDBCardText>
                    <MDBBtn color='none' onClick={() => navigate("/")} className="btn-prim-index px-5">Volver</MDBBtn>
                </MDBCardBody>
            </MDBCard>
        </div>
    );
}

export default Unauthorized;