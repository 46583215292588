import { Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CardLogoSti from '../components/CardLogoSti';
import { FormInput, PASSWORD_RULES } from '../components/FormInput';
import InputGoogleAuthenticator from '../components/InputGoogleAutheticator';
import InstructionsGA from '../components/InstructionsGoogleAuthenticator';
import Loading from '../components/Loading';
import { ModalConfirm } from '../components/ModalConfirm';
import { IModalConfirm } from '../interfaces';
import { createPasswordRules, REGEX, validateUserOnText } from '../utils/functions';
import { activateUser, getUserQRCode, validateUserToken } from '../utils/middleware';
import { notify } from '../utils/notifications';
import './../css/style.css';

const STEP_ERROR = 99;
type FormInputData = {
    password: string;
    passwordConfirm: string;
    code: string[];
};

const ActivateUser: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const userToken = searchParams.get('_tkn');
    const userName = searchParams.get('_ce');

    const [step, setStep] = useState(0);
    const [userPassword, setUserPassword] = useState("");
    const [userQRCode, setUserQRCode] = useState("");
    const [showSuccess, setShowSuccess] = useState<boolean>(false);


    const [loading, setLoading] = useState<boolean>(true);
    const [modalConfirm, setModalConfirm] = useState<IModalConfirm | null>();


    

    const { handleSubmit, errors, formState: { isSubmitting }, control, watch, setValue } = useForm({
        defaultValues: {
            password: "",
            passwordConfirm: "",
            code: []
        }
    });

    const handleShowModal = () => {
        setModalConfirm({
            body: <InstructionsGA userQRCode={userQRCode} />,
            title: "Instrucciones para instalar Google Authenticator",
            show: !modalConfirm,
            onConfirm: () => {
                setModalConfirm(null);
            }
        });
    }

    const onSubmitStep1 = async (data: FormInputData) => {
        setUserPassword(data.password);
        setStep(2);
    }

    const onSubmitStep2 = async () => {
        const code = watch('code').join("");
        const response = await activateUser(userName!, userToken!, userPassword, code)
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede realizar la operación",
                notificationType: "error"
            })
        } else {
			if (!response.isOk) {
				notify({
					title: "Ha ocurrido un error",
					message: response?.errorMessage ?? "",
					notificationType: "error"
				})
			}
			else setShowSuccess(true);
		}
    };

    const goToLogin = () => {
        navigate('/');
    }

    useEffect(() => {
        if (step === 1) {
            setValue('password', userPassword);
            setValue('passwordConfirm', userPassword);
        }
    }, [step, setValue, userPassword])

    useEffect(() => {
        (async () => {
            if (userToken && userName) {
                const isValidToken = await validateUserToken(userName, userToken);

                if (!isValidToken.isOk) setStep(STEP_ERROR);
                else {
                    const qrCode = await getUserQRCode(userName, userToken);

                    if (!qrCode.isOk || !qrCode.responseData) {
                        setStep(STEP_ERROR);
                    }
                    else {
                        setUserQRCode(`data:image/png;base64,${qrCode.responseData}`);
                        setStep(1);
                    }
                }
                setLoading(false);
            }

            else {
                // Si no hay token ni user, entonces se muestra mensaje de error
                setStep(STEP_ERROR);
                setLoading(false);
            }
        })();
    }, [userToken, userName])


    return (
        <div className='bg-degree1'>
            <div className="login-content">
                <div className="block bg-white">
                    {modalConfirm &&
                        <ModalConfirm
                            title={modalConfirm.title}
                            body={modalConfirm.body}
                            showConfirm={modalConfirm.show}
                            cancelButtonName="Aceptar"
                            onClose={() => setModalConfirm(null)}
                        />
                    }

                    {loading ?
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Loading />
                        </div>
                        : step === STEP_ERROR
                            ? <div className="title">
                                <h1>Ha ocurrido un problema</h1>
                                <MDBIcon fas icon="user-lock" size='5x' className='py-5' />
                                <p />
                                No pudimos validar tu acceso, debes ponerte en contacto con el Administrador.
                            </div>
                            : showSuccess
                                ? <div className="title">
                                    <MDBIcon fas icon="check" size='5x' className='py-3' />
                                    <p />
                                    ¡Usuario ha sido activado con éxito!

                                    <div className="content-int" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <MDBBtn color='none' onClick={goToLogin} className='btn-prim-index px-5'>
                                            Iniciar Sesión
                                        </MDBBtn>
                                    </div>
                                </div>
                                : <form onSubmit={step === 1 ? handleSubmit(onSubmitStep1) : handleSubmit(onSubmitStep2)}>
                                    {step === 1 && (
                                        <>
                                            <div className="mg-bttn16" >
                                                <h4 style={{ textAlign: 'center' }}>Registro de contraseña </h4>
                                                <label className="txt-bajada pt-3" style={{ marginBottom: "2px" }}>
                                                    Favor ingresa y confirma tu contraseña.
                                                </label>
                                            </div>

                                            <div className="row">
                                                <div className="col col-details" style={{ textAlign: 'start' }}>
                                                    <FormInput
                                                        useWrapper={false}
                                                        size={12}
                                                        errors={errors}
                                                        control={control}
                                                        name='password'
                                                        message='Contraseña es requerida'
                                                        placeholder='Contraseña'
                                                        type='password'
                                                        maxLength={16}
                                                        extraPasswordRules={PASSWORD_RULES}
                                                        rules={{
                                                            validate: {
                                                                ...createPasswordRules(PASSWORD_RULES),
                                                                words: (value) => REGEX.test(value) || 'Existen palabras no permitidas',
                                                                username: validateUserOnText(userName!)
                                                            }

                                                        }}
                                                    />
                                                    <FormInput
                                                        useWrapper={false}
                                                        size={12}
                                                        errors={errors}
                                                        control={control}
                                                        name='passwordConfirm'
                                                        message='Repetir Contraseña es requerida'
                                                        placeholder='Repetir Contraseña'
                                                        type='password'
                                                        maxLength={16}
                                                        extraPasswordRules={PASSWORD_RULES}
                                                        rules={{
                                                            validate: {
                                                                ...createPasswordRules(PASSWORD_RULES),
                                                                words: (value) => REGEX.test(value) || 'Existen palabras no permitidas',
                                                                username: validateUserOnText(userName!),
                                                                samePass: value => watch('password') === value || "Las contraseñas deben ser iguales"
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>


                                            <div className="content-int" style={{ display: 'flex', justifyContent: 'center' }}>
                                                <MDBBtn color='none' type='submit' className='btn-prim-index px-5' disabled={isSubmitting}>
                                                    {isSubmitting ? <Loading color='#fff' size='sm' /> : <>Continuar</>}
                                                </MDBBtn>
                                            </div>
                                        </>
                                    )}

                                    {step === 2 && (
                                        <>
                                            <div className="mg-bttn16" >
                                                <h4 className="mb-5" style={{ textAlign: 'center' }}>
                                                    Autorización con Google Autenticator
                                                </h4>
                                                <span>
                                                    Para configurar la autenticación de dos factores sigue las {' '}
                                                    <Link
                                                        color={theme.palette.primary.main}
                                                        component="span"
                                                        variant="body1"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={handleShowModal}
                                                    >
                                                        siguientes instrucciones
                                                    </Link>
                                                    {' '} y luego ingresa el código generado.
                                                </span>

                                            </div>
                                            <div className="row">
                                                <div className="col col-details mt-3" style={{ textAlign: 'start' }}>
                                                    <InputGoogleAuthenticator
                                                        setValue={setValue}
                                                        errors={errors}
                                                        control={control} />
                                                </div>
                                            </div>

                                            <div className="content-int" style={{ display: 'flex', justifyContent: 'center', width: 'auto' }}>
                                                <MDBBtn color='none' type='submit' disabled={isSubmitting} className='btn-prim-index px-5'>
                                                    {isSubmitting
                                                        ? <Loading color='#fff' size='sm' />
                                                        : <>Activar</>
                                                    }
                                                </MDBBtn>

                                            </div>
                                            <div className="input-content mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <MDBBtn onClick={() => setStep(1)} color='none' tag={'a'} className='forget'>
                                                    Volver
                                                </MDBBtn>
                                            </div>
                                        </>

                                    )}
                                </form>
                    }
                </div>
                <CardLogoSti />
            </div>
        </div>
    );
}

export default ActivateUser;