import { MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useLocalstorageState } from 'rooks';
import Loading from '../components/Loading';
import { TransmissionTable } from '../components/TransmissionTable';
import { ITransmision, IUserData } from "../interfaces";
import { getTransmission, TransmissionTypes } from '../utils/middleware';
import { notify } from '../utils/notifications';
import './../css/style.css';
import { TransmissionDetail } from './TransmissionDetail';

const transmissionTypes = new Map<string, TransmissionTypes>([
    ["sent", "origen"],
    ["received", "destino"],
    ["approve", "porAprobar"]
]);

const AUTHORIZING_PROFILE = "autorizador";
const INIT_COUNT_PER_PAGE = 10;

export const Transmission: React.FC = () => {
    const [tabSelected, setTabSelected] = useState('sent');
    const [transmissions, setTransmissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [transmissionSelected, setTransmissionSelected] = useState<ITransmision | null>(null);
    const [user] = useLocalstorageState<IUserData>("user", {});
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [countPerPage, setCountPerPage] = useState<number>(INIT_COUNT_PER_PAGE);

    const handleSelectTransmission = (idTransmission: number) => {
        const transmission: any = transmissions.filter((t: ITransmision) => t.idTransmision === idTransmission);
        setTransmissionSelected(transmission[0]);
    };

    const handleTabs = async (value: string) => {
        setCountPerPage(INIT_COUNT_PER_PAGE);
        setTabSelected(value);
    };

    const getTransmissionData = useCallback(async (type: TransmissionTypes) => {
        setLoading(true);
        const transmissions = await getTransmission(type);
        if (transmissions.isOk) {
            const transmissionsData = transmissions.responseData ? JSON.parse(transmissions.responseData) : [];
            setTransmissions(transmissionsData);
        }
        else {
            if (!transmissions.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: transmissions.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        setLoading(false);
    }, [])

    const handleApproveResponse = async () => {
        notify({
            message: "Transmisión aprobada correctamente"
        });
        await getTransmissionData("porAprobar")
    }

    const TransmissionDetails = (props: { tabName: string }) => {
        const { tabName } = props;
        const transmissionType = transmissionTypes.get(tabName);
        return <MDBTabsPane show={true}>
            {loading ?
                <div className='text-center'>
                    <Loading />
                </div>
                :
                transmissionType && <TransmissionTable
                    handleApproveResponse={handleApproveResponse}
                    onSelectTransmission={handleSelectTransmission}
                    transmissions={transmissions}
                    transmissionType={transmissionType}
                    currentPage={currentPage}
                    onChangeCurrentPage={setCurrentPage}
                    onChangeCounterPerPage={setCountPerPage}
                    countPerPage={countPerPage}
                />
            }
        </MDBTabsPane>
    }

    useEffect(() => {
        const transmissionType = transmissionTypes.get(tabSelected);
        getTransmissionData(transmissionType!);

    }, [user.csll, tabSelected, getTransmissionData])

    return (
        <div className="bg-white">
            <div className="container-fluid">
                {/* <Header /> */}
                {
                    transmissionSelected
                        ? <div className="bg-white">
                            <div className="container-fluid">
                                <div className="grilla-content">
                                    <TransmissionDetail onReturn={() => setTransmissionSelected(null)} transmission={transmissionSelected} />
                                </div>
                            </div>
                        </div>

                        : <div className="grilla-content">
                            <div className="title-grilla">
                                <h1>Consulta de Transmisiones</h1>
                            </div>
                            <div>
                                <MDBTabs className='nav-item'>
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => handleTabs('sent')} active={tabSelected === 'sent'}>
                                            Transmisiones Realizadas
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => handleTabs('received')} active={tabSelected === 'received'}>
                                            Transmisiones Recibidas
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                    {user.profile?.toLowerCase() === AUTHORIZING_PROFILE &&
                                        <MDBTabsItem>
                                            <MDBTabsLink id="tab-approve" onClick={() => handleTabs('approve')} active={tabSelected === 'approve'}>
                                                Transmisiones por Aprobar
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                    }
                                </MDBTabs>

                                <MDBTabsContent className='mt-3'>
                                    <TransmissionDetails tabName={tabSelected} />
                                </MDBTabsContent>
                            </div>
                        </div>
                }

            </div>
        </div>
    );
}