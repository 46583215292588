import { MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownLink, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";

const Paginator: React.FC<{
    paginationRange: any[],
    currentPage: number,
    pageNumbers: number[],
    countPerPage: number,
    allData: any[],
    countPerPageOptions: number[],
    onChangePage: (page: number) => void;
    onChangeCounterPerPage: (count: number) => void;
}> = (props) => {
    const { paginationRange, currentPage, countPerPage, pageNumbers, allData, countPerPageOptions, onChangePage, onChangeCounterPerPage } = props;

    const handleClick = (page: number) => {
        onChangePage(page);
    };

    return (
        paginationRange &&
        <div className="paginador">
            <nav>
                <ul className="pagination">
                    {currentPage > 1 &&
                        <li className="page-item">
                            <MDBBtn color="none" onClick={() => handleClick(currentPage - 1)} tag={'a'} className='page-link' aria-label="Anterior">
                                <span aria-hidden="true">&laquo;</span>
                            </MDBBtn>
                        </li>
                    }

                    {
                        paginationRange.map((page: string, index: number) => {
                            if (page === "...") {
                                return <li className="page-item" key={index}>&#8230;</li>;
                            }

                            return (
                                <li className="page-item" key={index}>
                                    <MDBBtn
                                        id={page.toString()}
                                        color="none"
                                        onClick={() => handleClick(parseInt(page))}
                                        tag={'a'}
                                        className={`page-link ${currentPage === parseInt(page) && "activo"}`}
                                    >{page}</MDBBtn>
                                </li>

                            );
                        })
                    }
                    {currentPage < pageNumbers[pageNumbers.length - 1] &&
                        <li className="page-item">
                            <MDBBtn color="none" onClick={() => handleClick(currentPage + 1)} tag={'a'} className='page-link' aria-label="Siguiente">
                                <span aria-hidden="true">&raquo;</span>
                            </MDBBtn>
                        </li>
                    }

                    <MDBDropdown style={{ background: 'none' }}>
                        <MDBDropdownToggle className="px-3" style={{ minWidth: "max-content" }} color='none'>
                            Cantidad por páginas: {`${countPerPage === allData.length ? "Todos" : countPerPage}`}
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            {[...countPerPageOptions].map((count, key: number) =>
                                count !== allData.length && count < allData.length &&
                                <MDBDropdownItem
                                    key={key}
                                    style={{ background: count === countPerPage ? "#eee" : "transparent" }}
                                >
                                    <>
                                        <MDBDropdownLink tag="div" onClick={() => onChangeCounterPerPage(count)}>
                                            {count}
                                        </MDBDropdownLink>
                                    </>
                                </MDBDropdownItem>

                            )}
                            <MDBDropdownItem
                                style={{ background: allData.length === countPerPage ? "#eee" : "transparent" }}
                            >
                                <MDBDropdownLink tag="div" onClick={() => onChangeCounterPerPage(allData.length)}>
                                    Todos
                                </MDBDropdownLink>
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </ul>
            </nav>
        </div>
    )
}


export default Paginator;