import {
    MDBBtn,
    MDBCol, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader,
    MDBModalTitle,
    MDBRow,
    MDBTabsContent
} from 'mdb-react-ui-kit';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { createInstitution } from '../utils/middleware';
import { notify } from '../utils/notifications';
import { FormInput } from './FormInput';
import Loading from './Loading';

export const ModalFormInstitution: React.FC<{
    title?: ReactElement | string,
    show: boolean,
    onClose: () => void;
    onSuccess: () => void;

}> = (props) => {
    const { onClose, onSuccess, title, show } = props;
    const { handleSubmit, errors, formState: { isSubmitting }, control } = useForm({
        defaultValues: {
            id: "",
            name: ""
        }
    });

    const handleSave = async (data: { id: string, name: string }) => {
        const { id, name } = data;
        const response = await createInstitution(id, name);
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                notify({ message: <>Institución creada correctamente</> })
                onSuccess();
            }
            else {
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
            }
        }
        
    }

    return (
        <>
            <MDBModal show={show} staticBackdrop>
                <MDBModalDialog size='lg'>
                    <MDBModalContent>
                        <form onSubmit={handleSubmit(handleSave)}>
                            <MDBModalHeader>
                                <MDBModalTitle>
                                    {title}
                                </MDBModalTitle>
                            </MDBModalHeader>
                            <MDBModalBody className="text-center mt-1">
                                <MDBTabsContent className='mt-1'>                                   
                                    <MDBRow className='d-flex align-items' >
                                        <MDBCol size='12'>
                                            <div className="row">
                                                <div className="col col-details" style={{ textAlign: 'start' }}>
                                                    <FormInput
                                                        size={12}
                                                        errors={errors}
                                                        control={control}
                                                        name='id'
                                                        message='Id es requerido'
                                                        label='Id'
                                                        maxLength={6}
                                                        type='number'
                                                        rules={{
                                                            validate: {
                                                                max: (value) => /^.{0,6}$/.test(value) || 'Deben ser máximo 6 números'
                                                            }
                                                        }}
                                                    />
                                                    <FormInput
                                                        size={12}
                                                        errors={errors}
                                                        control={control}
                                                        name='name'
                                                        message='Nombre es requerido'
                                                        label='Nombre'
                                                        maxLength={80}
                                                        rules={{
                                                            validate: {
                                                                max: (value) => /^.{0,80}$/.test(value) || 'Deben ser máximo 80 caracteres'
                                                            }
                                                        }}

                                                    />
                                                </div>
                                            </div>

                                        </MDBCol>
                                    </MDBRow>

                                </MDBTabsContent>

                            </MDBModalBody>

                            <MDBModalFooter style={{ justifyContent: 'center' }}>

                                {isSubmitting
                                    ? <div className='text-center'><Loading /></div>
                                    :
                                    <>
                                        <MDBBtn color='white' type='button' onClick={onClose}>
                                            Cancelar
                                        </MDBBtn>
                                        <MDBBtn style={{ background: '#18BA95' }} type='submit'>
                                            Aceptar
                                        </MDBBtn>

                                    </>
                                }

                            </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

