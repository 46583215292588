import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import CardLogoSti from '../components/CardLogoSti';
import { FormInput, PASSWORD_RULES } from '../components/FormInput';
import InputGoogleAuthenticator from '../components/InputGoogleAutheticator';
import Loading from '../components/Loading';
import { ModalConfirm } from '../components/ModalConfirm';
import { IModalConfirm } from '../interfaces';
import { createPasswordRules, REGEX, validateUserOnText } from '../utils/functions';
import { updateUserPasswordExpirated } from '../utils/middleware';
import { notify } from '../utils/notifications';
import './../css/style.css';

const STEP_ERROR = 99;
type FormInputData = {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
    code: string[];
};

const ForceUpdatePassword: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userName = location.state?.userName;
    const [step, setStep] = useState(1);
    const [modalConfirm, setModalConfirm] = useState<IModalConfirm | null>();
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const { handleSubmit, errors, formState: { isSubmitting }, control, watch, setValue } = useForm({
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            code: []
        }
    });

    const onSubmit = async (data: FormInputData) => {
        const { currentPassword, newPassword } = data;
        const code = watch('code').join("");

        const response = await updateUserPasswordExpirated(userName!, currentPassword, newPassword, code);
		if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede realizar la operación",
                notificationType: "error"
            })
        } else {
			if (!response.isOk) {
                if (response.responseStatus === 510) {
                    navigate("/");
                    return;
                }
				if (!response.notify)
					notify({
						title: "Ha ocurrido un error",
						message: response?.errorMessage ?? "",
						notificationType: "error"
					})
			}
			else setShowSuccess(true);
		}
    };

    const goToLogin = () => {
        navigate('/');
    }

    useEffect(() => {
        const userName = location?.state?.userName;
        if (!userName) setStep(STEP_ERROR);
    }, [location.state])


    return (
        <div className='bg-degree1'>
            <div className="login-content">
                <div className="block bg-white">
                    {modalConfirm &&
                        <ModalConfirm
                            title={modalConfirm.title}
                            body={modalConfirm.body}
                            showConfirm={modalConfirm.show}
                            cancelButtonName="Aceptar"
                            onClose={() => setModalConfirm(null)}
                        />
                    }

                    {step === STEP_ERROR
                        ? <div className="title">
                            <h1>Ha ocurrido un problema</h1>
                            <MDBIcon fas icon="user-lock" size='5x' className='py-5' />
                            <p />
                            No pudimos validar tu acceso, debes ponerte en contacto con el Administrador.
                        </div>
                        :
                        !showSuccess
                            ?
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mg-bttn16" >
                                    <h4 style={{ textAlign: 'center' }}>Habilitar Usuario</h4>
                                    <label className="txt-bajada pt-3" style={{ marginBottom: "2px" }}>
                                        Favor ingresa y confirma tus contraseñas.
                                    </label>
                                </div>

                                <div className="row">
                                    <div className="col col-details" style={{ textAlign: 'start' }}>
                                        <FormInput
                                            usePasswordPopover={false}
                                            useWrapper={false}
                                            size={12}
                                            errors={errors}
                                            control={control}
                                            name='currentPassword'
                                            message='Contraseña es requerida'
                                            placeholder='Contraseña actual'
                                            type='password'
                                            maxLength={16}
                                        />
                                        <FormInput
                                            useWrapper={false}
                                            size={12}
                                            errors={errors}
                                            control={control}
                                            name='newPassword'
                                            message='Contraseña es requerida'
                                            placeholder='Contraseña nueva'
                                            type='password'
                                            maxLength={16}
                                            extraPasswordRules={PASSWORD_RULES}
                                            rules={{
                                                validate: {
                                                    ...createPasswordRules(PASSWORD_RULES),
                                                    words: (value) => REGEX.test(value) || 'Existen palabras no permitidas',
                                                    username: validateUserOnText(userName!)
                                                }
                                            }}
                                        />
                                        <FormInput
                                            useWrapper={false}
                                            size={12}
                                            errors={errors}
                                            control={control}
                                            name='newPasswordConfirm'
                                            message='Repetir Contraseña es requerida'
                                            placeholder='Repetir Contraseña nueva'
                                            type='password'
                                            maxLength={16}
                                            extraPasswordRules={PASSWORD_RULES}
                                            rules={{
                                                validate: {
                                                    ...createPasswordRules(PASSWORD_RULES),
                                                    words: (value) => REGEX.test(value) || 'Existen palabras no permitidas',
                                                    username: validateUserOnText(userName!),
                                                    samePass: value => watch('newPassword') === value || "Las contraseñas deben ser iguales"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-details mt-3" style={{ textAlign: 'start' }}>
                                        <InputGoogleAuthenticator
                                            setValue={setValue}
                                            errors={errors}
                                            control={control} />
                                    </div>
                                </div>

                                <div className="content-int" style={{ display: 'flex', justifyContent: 'center', width: 'auto' }}>
                                    <MDBBtn color='none' type='submit' disabled={isSubmitting} className='btn-prim-index px-5'>
                                        {isSubmitting
                                            ? <Loading color='#fff' size='sm' />
                                            : <>Actualizar</>
                                        }
                                    </MDBBtn>

                                </div>
                                <div className="input-content mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <MDBBtn onClick={goToLogin} color='none' tag={'a'} className='forget'>
                                        Volver
                                    </MDBBtn>
                                </div>
                            </form>
                            : <>
                                <div className="title">
                                    <MDBIcon fas icon="check" size='5x' className='py-3' />
                                    <p />
                                    ¡Tu contraseña ha sido cambiada con éxito!

                                    <div className="content-int" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <MDBBtn color='none' onClick={goToLogin} className='btn-prim-index px-5'>
                                            Iniciar Sesión
                                        </MDBBtn>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                <CardLogoSti />
            </div>
        </div>
    );
}

export default ForceUpdatePassword;