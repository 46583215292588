import { MDBRow, MDBCol, MDBCardImage, MDBCardBody, MDBTypography, MDBIcon } from "mdb-react-ui-kit"
import imageGoogleAuthenticator from '../../src/img/ga.png';

const InstructionsGA: React.FC<{ userQRCode: string }> = (props) => {
    const { userQRCode } = props;

    return (
        <>
            <MDBRow className='g-0' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <MDBCol md='3'>
                    <MDBCardImage src={imageGoogleAuthenticator} alt='...' fluid />
                </MDBCol>
                <MDBCol md='9'>
                    <MDBCardBody>
                        <MDBTypography variant='h6' style={{ textAlign: 'justify' }}>
                            <strong>Google Authenticator:</strong> es una aplicación que te ayuda a proteger tus cuentas en línea con una capa adicional de seguridad llamada "autenticación de dos factores".
                            Cuando habilitas la autenticación de dos factores en una cuenta en línea, no solo necesitarás una contraseña para iniciar sesión, sino que también necesitarás un código de verificación adicional que cambia cada 30 segundos. Este código se genera en la aplicación Google Authenticator en tu dispositivo móvil, lo que significa que alguien que tenga tu contraseña no podrá acceder a tu cuenta a menos que también tenga acceso físico a tu dispositivo móvil.
                        </MDBTypography>
                    </MDBCardBody>
                </MDBCol>
            </MDBRow>
            <MDBRow className='g-0'>
                <MDBTypography className='mb-3' variant='h5' tag='u' style={{ textAlign: 'center', fontWeight: 800 }}>
                    Instrucciones
                </MDBTypography>
                <MDBCol md='12' style={{
                    textAlign: "start",
                    paddingLeft: 2
                }}>
                    <MDBRow className='g-0'>
                        <MDBCol md='9' className='p-3'>
                            <MDBTypography listUnStyled className='mb-0'>
                                <li className='mb-2'>
                                    <MDBIcon icon='check-circle' className='me-2 text-success text-start' />
                                    Descarga e instala la aplicación "Google Authenticator" (App Store en iOS o Google Play Store en Android) en tu teléfono o tablet.
                                </li>
                                <li className='mb-2'>
                                    <MDBIcon icon='check-circle' className='me-2 text-success' />
                                    Una vez instalada, abre la aplicación "Google Authenticator" y selecciona el botón "+" para agregar una cuenta.
                                </li>
                                <li className='mb-2'>
                                    <MDBIcon icon='check-circle' className='me-2 text-success' />
                                    Sigue las instrucciones que se muestran en pantalla y escanea el siguiente código QR.
                                </li>
                                <li className='mb-2'>
                                    <MDBIcon icon='check-circle' className='me-2 text-success' />
                                    Una vez agregada la cuenta, verás un código de 6 dígitos en la aplicación. Este código cambia cada 30 segundos y es el que te solicitamos para verificar tu identidad.
                                </li>
                                <li className='mb-2'>
                                    <MDBIcon icon='check-circle' className='me-2 text-success' />
                                    ¡Listo! Ahora puedes utilizar Google Authenticator para proteger tus cuentas con autenticación de dos factores.
                                </li>
                            </MDBTypography>
                        </MDBCol>
                        <MDBCol md='3' className='p-3 text-center'>
                            <MDBCardImage src={userQRCode} alt='...' fluid />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        </>
    )
}

export default InstructionsGA;