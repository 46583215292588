import {
    MDBBtn,
    MDBCol, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader,
    MDBModalTitle,
    MDBRow,
    MDBTabsContent
} from 'mdb-react-ui-kit';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { changeBoxPassword } from '../utils/middleware';
import { notify } from '../utils/notifications';
import { FormInput } from './FormInput';
import Loading from './Loading';

export const ModalFormPassword: React.FC<{
    title?: ReactElement | string,
    data?: any,
    showConfirm: boolean,
    subTitle?: ReactElement | string,
    onClose: () => void;
    onSubmit: () => void;

}> = (props) => {
    const { onClose, onSubmit, title, subTitle, showConfirm, data } = props;
    const { handleSubmit, errors, formState: { isSubmitting }, control, watch } = useForm({
        defaultValues: {
            password: "",
            passwordConfirm: ""
        }
    });

    const handleChangePassword = async (formData: { password: string, passwordConfirm: string }) => {
        const response = await changeBoxPassword(data.idCasilla, formData.password);
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                notify({ message: <>Contraseña actualizada correctamente</> })
            }
            else {
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
            }
        }

        onSubmit();
    }


    return (
        <>
            <MDBModal show={showConfirm} staticBackdrop>
                <MDBModalDialog size='lg'>
                    <MDBModalContent>
                        <form onSubmit={handleSubmit(handleChangePassword)}>
                            <MDBModalHeader>
                                <MDBModalTitle>
                                    {title}
                                </MDBModalTitle>
                            </MDBModalHeader>
                            <MDBModalBody className="text-center mt-1">
                                <MDBTabsContent className='mt-1'>
                                    {subTitle}
                                    <MDBRow className='d-flex align-items' >
                                        <MDBCol size='12'>
                                            <div className="row">
                                                <div className="col col-details" style={{ textAlign: 'start' }}>
                                                    <FormInput
                                                        size={12}
                                                        errors={errors}
                                                        control={control}
                                                        name='password'
                                                        message='Contraseña es requerida'
                                                        label='Contraseña'
                                                        type='password'
                                                        disableInputPaste
                                                        maxLength={16}
                                                        extraPasswordRules={[
                                                            { re: /^.{8,16}$/, label: 'Debe ingresar entre 8 y 16 caracteres' }                                                            
                                                        ]}
                                                        rules={{
                                                            validate: {
                                                                f1: (value) => /^.{8,16}$/.test(value) || 'Debe ingresar entre 8 y 16 caracteres',                                                                
                                                                f2: (value) => /^[A-Za-z0-9:!@$%^&*()\-_=+[\]{}|;:",.<>#'?/]+$/.test(value) || `Solo se permite un valor alfanumérico además de los siguientes caracteres (!@$%^&*()-_=+[]{}|;:",.<>#'?/)`


                                                            }
                                                        }}
                                                    />
                                                    <FormInput
                                                        size={12}
                                                        errors={errors}
                                                        control={control}
                                                        name='passwordConfirm'
                                                        message='Repetir Contraseña es requerida'
                                                        label='Repetir Contraseña'
                                                        type='password'
                                                        disableInputPaste
                                                        maxLength={16}
                                                        extraPasswordRules={[
                                                            { re: /^.{8,16}$/, label: 'Debe ingresar entre 8 y 16 caracteres' }                                                            
                                                        ]}
                                                        rules={{
                                                            validate: {
                                                                f1: (value) => /^.{8,16}$/.test(value) || 'Debe ingresar entre 8 y 16 caracteres',
                                                                f2: (value) => /^[A-Za-z0-9:!@$%^&*()\-_=+[\]{}|;:",.<>#'?/]+$/.test(value) || `Solo se permite un valor alfanumérico además de los siguientes caracteres (!@$%^&*()-_=+[]{}|;:",.<>#'?/)`,
                                                                f6: value => watch('password') === value || "Las contraseñas deben ser iguales"
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                        </MDBCol>
                                    </MDBRow>

                                </MDBTabsContent>

                            </MDBModalBody>

                            <MDBModalFooter style={{ justifyContent: 'center' }}>

                                {isSubmitting
                                    ? <div className='text-center'><Loading /></div>
                                    :
                                    <>
                                        <MDBBtn color='white' type='button' onClick={onClose}>
                                            Cancelar
                                        </MDBBtn>
                                        <MDBBtn style={{ background: '#18BA95' }} type='submit'>
                                            Aceptar
                                        </MDBBtn>

                                    </>
                                }

                            </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

