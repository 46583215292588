// import './../css/style.css';
import { Box, Select } from '@mantine/core';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import PolicyIcon from '@mui/icons-material/Policy';
import PreviewIcon from '@mui/icons-material/Preview';
import HttpsIcon from '@mui/icons-material/Https';
import { Chip, IconButton, Link } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { MDBBtn, MDBCol, MDBIcon, MDBRow, MDBTabs, MDBTabsItem, MDBTabsLink } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { DataTable } from '../components/DataTable';
import Loading from '../components/Loading';
import { ModalConfirm } from '../components/ModalConfirm';
import { ModalFormInstitution } from '../components/ModalFormInstitution';
import { IBoxData, IKeyValueData, IModalConfirm } from '../interfaces';
import { getMigrateStatus, getRegisterFormat } from '../utils/checkValues';
import { getBoxsByInstitution, getInstitutions, updateStatusField } from '../utils/middleware';
import { notify } from '../utils/notifications';

export const AdminListBoxs: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as { institution: IKeyValueData };
    window.history.replaceState(null, '')

    const [loading, setLoading] = useState(false);
    const [institutionSelected, setInstitutionSelected] = useState<IKeyValueData | null>(null);
    const [institutionsValues, setInstitutionsValues] = useState<IKeyValueData[]>([]);
    const [boxData, setBoxData] = useState([]);
    const [modalConfirm, setModalConfirm] = useState<IModalConfirm | null>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const theme = useTheme();

    const handleSearch = async (institutionSelected: IKeyValueData) => {
        setLoading(true);
        const response = await getBoxsByInstitution(institutionSelected?.id);
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                setBoxData(response?.responseData ? JSON.parse(response?.responseData) : []);
            }
            else {
                if (!response.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: response.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
        setLoading(false);
        //RMM
        return { isOk: true }
    }

    const handleSelectInstitution = async (selectedLabel: string) => {
        if (selectedLabel) {
            const instSelectedIndex = institutionsValues.findIndex((inst: IKeyValueData) => inst.nombre === selectedLabel)
            const institutionSelected = institutionsValues[instSelectedIndex]
            setInstitutionSelected(institutionSelected);
            const response = await handleSearch(institutionSelected)
            if (response === null) {
                notify({
                    title: "Ha ocurrido un error",
                    message: "No se puede acceder al Servicio",
                    notificationType: "error"
                })
            }
        }
        else {
            setInstitutionSelected(null)
            setBoxData([]);
        }
    }

    const handleCreateBox = () => {
        navigate(
            "/admin/casillas/crear",
            { state: { "institution": institutionSelected } }
        )
    }

    const handleEditBox = (boxData: IBoxData) => {
        navigate(
            "/admin/casillas/editar",
            { state: { "institution": institutionSelected, boxData } }
        )
    }

    const handleUpdateStatus = async (data: IBoxData, fieldName: string, fieldValue: string) => {
        const status = fieldValue ? 0 : 1;
        const response = await updateStatusField(data.idCasilla, fieldName, status);
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                const response2 = await handleSearch(institutionSelected!);
                if (response2 === null) {
                    notify({
                        title: "Ha ocurrido un error",
                        message: "No se puede acceder al Servicio",
                        notificationType: "error"
                    })
                } else {
                    notify({
                        message: `Casilla actualizada exitosamente.`,
                        autoClose: 3000,
                    })
                }
            }
            else {
                if (!response.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: response.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
        //RMM
        return { isOk: true }
    }

    const handleShowConfirm = (modalType: string, data: IBoxData) => {
        let modalProps: any = { show: true };
        if (modalType === "updateDebugStatus") {
            modalProps = {
                title: <>Actualizar Debug</>,
                body: <>¿Desea <b>{!data.debug ? "habilitar" : "deshabilitar"} </b> el debug de la casilla <b>{data.idCasilla}</b>?</>,
                handleFunction: async () => await handleUpdateStatus(data, "debug/estado", data.debug)
            }
        }
        else if (modalType === "updateScanFileStatus") {
            modalProps = {
                title: <>Actualizar Scan File</>,
                body: <>¿Desea <b>{!data.scanFile ? "habilitar" : "deshabilitar"} </b> el Scan File de la casilla <b>{data.idCasilla}</b>?</>,
                handleFunction: async () => await handleUpdateStatus(data, "scan/estado", data.scanFile)
            }
        }
        else if (modalType === "updateEncryption") {
            modalProps = {
                title: <>Actualizar Encriptación</>,
                body: <>¿Desea <b>{!data.encripta ? "habilitar" : "deshabilitar"} </b> la encriptación de la casilla <b>{data.idCasilla}</b>?</>,
                handleFunction: async () => await handleUpdateStatus(data, "encripta/estado", data.encripta)
            }
        }
        else if (modalType === "updateBoxStatus") {
            modalProps = {
                title: <>Actualizar Estado</>,
                body: <>¿Desea <b>{!data.idEstado ? "habilitar" : "deshabilitar"} </b> la casilla <b>{data.idCasilla}</b>?</>,
                handleFunction: async () => await handleUpdateStatus(data, "estado", data.idEstado)
            }
        }


        setModalConfirm({
            ...modalProps,
            show: true,
            onConfirm: () => {
                modalProps.handleFunction && modalProps.handleFunction();
                setModalConfirm(null);
            }
        });

    }

    const getInstitutionsData = useCallback(async () => {
        const institutions = await getInstitutions();
        if (institutions.isOk) {
            let institutionList = institutions.responseData ? JSON.parse(institutions.responseData) : [];
            setInstitutionsValues(institutionList.map((data: IKeyValueData) => { return { id: data.id, nombre: data.nombre } }));
            if (state?.institution) {
                setInstitutionSelected(state.institution);
                const response = await handleSearch(state.institution)
                if (response === null) {
                    notify({
                        title: "Ha ocurrido un error",
                        message: "No se puede acceder al Servicio",
                        notificationType: "error"
                    })
                }
            }
        }
        else {
            if (!institutions.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: institutions.errorMessage ?? "",
                    notificationType: "error"
                })
        }

    }, [state])

    const handleSuccessSaveInstitution = () => {
        setShowModal(false)
        getInstitutionsData();
    }

    useEffect(() => {
        getInstitutionsData();
    }, [getInstitutionsData])

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    return (
        <div className="bg-white">
            <div className="container-fluid">
                {
                    <div className="grilla-content">
                        <div className="title-grilla">
                            <h1>Administrador de Casillas</h1>
                        </div>

                        {showModal && (
                            <ModalFormInstitution
                                title={'Crear Institución'}
                                onSuccess={handleSuccessSaveInstitution}
                                show={showModal}
                                onClose={() => setShowModal(false)}
                            />
                        )}

                        {modalConfirm &&
                            <ModalConfirm
                                title={modalConfirm.title}
                                body={modalConfirm.body}
                                showConfirm={modalConfirm.show}
                                onClose={() => setModalConfirm(null)}
                                onConfirm={modalConfirm.onConfirm}
                            />
                        }
                        <MDBRow className='my-5 d-flex align-items-end' >
                            <MDBCol size='8'>
                                <Select
                                    radius={8}
                                    label="Institución"
                                    onChange={handleSelectInstitution}
                                    data={institutionsValues.length ? institutionsValues.map(i => i.nombre) : []}
                                    value={institutionSelected?.nombre}
                                    searchable
                                    nothingFound="Sin información"
                                />
                            </MDBCol>

                            <MDBCol size='auto'>
                                <MDBBtn
                                    color='success'
                                    className='btn-prim-download'
                                    rounded
                                    onClick={() => setShowModal(true)}>
                                    <MDBIcon fas icon="university" className='mx-2' />
                                    Crear Institución
                                </MDBBtn>
                            </MDBCol>
                            <MDBCol size='auto'>
                                <MDBBtn
                                    color='success'
                                    className='btn-prim-download'
                                    rounded
                                    disabled={!institutionSelected}
                                    onClick={handleCreateBox}>
                                    <MDBIcon fas icon="plus" className='mx-2' />
                                    Crear Casilla
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>


                        <div className="text-center">
                            {loading ? <Loading /> :
                                <>
                                    <MDBTabs className='pb-5'>
                                        <MDBTabsItem>
                                            <MDBTabsLink active={true}>
                                                LISTADO DE CASILLAS {institutionSelected && `- ${institutionSelected.nombre.toLocaleUpperCase()}`}
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                    </MDBTabs>

                                    <DataTable
                                        rows={boxData.map((box: IBoxData) => {
                                            const fechaCreacion = box.fechaCreacion && dayjs(box.fechaCreacion).format('DD/MM/YYYY HH:mm:ss')
                                            const tipo = getMigrateStatus(box.migrada ?? "");
                                            const formatoRegistroEntrada = getRegisterFormat(box.formatoRegistroEntrada ?? "");

                                            return {
                                                ...box,
                                                id: box.idCasilla,
                                                fechaCreacion,
                                                tipoLabel: tipo,
                                                formatoRegistroEntradaLabel: formatoRegistroEntrada
                                            }

                                        })}

                                        columns={[
                                            {
                                                field: 'idCasilla', headerName: 'Id Casilla', minWidth: 300,
                                                renderCell: (params) => (
                                                    <Link

                                                        color={theme.palette.primary.main}
                                                        component="button"
                                                        variant="body2"
                                                        onClick={() => handleEditBox(params.row)}
                                                    >
                                                        {params.id}
                                                    </Link>
                                                )
                                            },
                                            { field: 'fechaCreacion', headerName: 'Fecha Creación', minWidth: 200 },
                                            { field: 'formatoRegistroEntradaLabel', headerName: 'Registros', minWidth: 150 },
                                            { field: 'tipoLabel', headerName: 'Tipo', minWidth: 150 },
                                            {
                                                field: 'estado', headerName: 'Estado', minWidth: 150,
                                                align: "center",
                                                headerAlign: 'center',
                                                renderCell: (params) => {
                                                    const estado = params.row.idEstado ? "Habilitado" : "Deshabilitado";
                                                    return <Chip
                                                        size='small'
                                                        label={estado}
                                                        color={
                                                            params.row.idEstado
                                                                ? 'success'
                                                                : 'warning'}
                                                        variant="outlined"
                                                    />
                                                }
                                            },

                                            {
                                                field: 'scanFile', headerName: 'Scan File', minWidth: 150,
                                                align: "center",
                                                headerAlign: 'center',
                                                renderCell: (params) => {
                                                    if (params.row.tipoCasilla === 'G') {
                                                        return <>-</>
                                                    }

                                                    const scanFile = params.row.scanFile ? "Habilitado" : "Deshabilitado";
                                                    return <Chip
                                                        size='small'
                                                        label={scanFile}
                                                        color={
                                                            params.row.scanFile
                                                                ? 'success'
                                                                : 'warning'}
                                                        variant="outlined"
                                                    />
                                                }
                                            },
                                            {
                                                field: 'encripta', headerName: 'Encriptación', minWidth: 150,
                                                align: "center",
                                                headerAlign: 'center',
                                                renderCell: (params) => {
                                                    if (params.row.tipoCasilla === 'G') {
                                                        return <>-</>
                                                    }
                                                    const encripta = params.row.encripta ? "Habilitado" : "Deshabilitado";
                                                    return <Chip
                                                        size='small'
                                                        label={encripta}
                                                        color={
                                                            params.row.encripta
                                                                ? 'success'
                                                                : 'warning'}
                                                        variant="outlined"
                                                    />
                                                }
                                            },
                                            {
                                                field: 'debug', headerName: 'Debug', minWidth: 150,
                                                align: "center",
                                                headerAlign: 'center',
                                                renderCell: (params) => {
                                                    const debug = params.row.debug ? "Habilitado" : "Deshabilitado";
                                                    return <Chip
                                                        label={debug}
                                                        size='small'
                                                        color={
                                                            params.row.debug
                                                                ? 'success'
                                                                : 'warning'}
                                                        variant="outlined"
                                                    />
                                                }
                                            },
                                            {
                                                field: 'acciones',
                                                sortable: false,
                                                headerName: 'Acciones',
                                                align: "center",
                                                headerAlign: 'center',
                                                minWidth: 200,
                                                renderCell: (params) => {
                                                    return (
                                                        <>
                                                            <Box className='px-1'>
                                                                <LightTooltip title="Cambiar estado casilla" placement="bottom">
                                                                    <IconButton
                                                                        sx={{ border: `1px solid ${theme.palette.primary.main}`, color: theme.palette.primary.main }}
                                                                        aria-label="status-box"
                                                                        size="small"
                                                                        onClick={() => handleShowConfirm("updateBoxStatus", params.row)}>
                                                                        <ChangeCircleOutlinedIcon sx={{ color: theme.palette.primary.main }} />
                                                                    </IconButton>
                                                                </LightTooltip>
                                                            </Box>
                                                            <Box className='px-1'>
                                                                {params.row.tipoCasilla === 'G'
                                                                    ? <IconButton
                                                                        disabled={true}
                                                                        aria-label="status-scan"
                                                                        size="small"
                                                                        sx={{ border: `1px solid gray`, color: 'gray' }}
                                                                    >
                                                                        <PolicyIcon sx={{ color: 'gray' }} />
                                                                    </IconButton>
                                                                    : <LightTooltip title="Cambiar estado Scan File" placement="bottom" color='success'>
                                                                        <IconButton
                                                                            sx={{ border: `1px solid ${theme.palette.primary.main}`, color: theme.palette.primary.main }}
                                                                            aria-label="status-scan"
                                                                            size="small"
                                                                            onClick={() => handleShowConfirm("updateScanFileStatus", params.row)}>
                                                                            <PolicyIcon sx={{ color: theme.palette.primary.main }} />
                                                                        </IconButton>
                                                                    </LightTooltip>
                                                                }
                                                            </Box>
                                                            <Box className='px-1'>
                                                                {params.row.tipoCasilla === 'G'
                                                                    ? <IconButton
                                                                        disabled={true}
                                                                        aria-label="status-encryption"
                                                                        size="small"
                                                                        sx={{ border: `1px solid gray`, color: 'gray' }}
                                                                    >
                                                                        <HttpsIcon sx={{ color: 'gray' }} />
                                                                    </IconButton>
                                                                    : <LightTooltip title="Cambiar estado Encriptación" placement="bottom">
                                                                        <IconButton
                                                                            sx={{ border: `1px solid ${theme.palette.primary.main}`, color: theme.palette.primary.main }}
                                                                            aria-label="status-encryption"
                                                                            size="small"
                                                                            onClick={() => handleShowConfirm("updateEncryption", params.row)}>
                                                                            <HttpsIcon sx={{ color: theme.palette.primary.main }} />
                                                                        </IconButton>
                                                                    </LightTooltip>
                                                                }
                                                            </Box>
                                                            <Box className='px-1'>
                                                                <LightTooltip title="Cambiar estado Debug" placement="bottom">
                                                                    <IconButton
                                                                        sx={{ border: `1px solid ${theme.palette.primary.main}`, color: theme.palette.primary.main }}
                                                                        aria-label="status-debug"
                                                                        size="small"
                                                                        onClick={() => handleShowConfirm("updateDebugStatus", params.row)}>
                                                                        <PreviewIcon sx={{ color: theme.palette.primary.main }} />
                                                                    </IconButton>
                                                                </LightTooltip>
                                                            </Box>
                                                        </>
                                                    );
                                                }
                                            },

                                        ]} />
                                </>
                            }
                        </div>
                    </div>
                }
            </div >
        </div >
    );
}