import { Container, Grid, Paper } from '@mantine/core';
import { MDBBadge, MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownLink, MDBDropdownMenu, MDBDropdownToggle, MDBIcon } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocalstorageState } from 'rooks';
import Loading from '../components/Loading';
import { IUserData } from '../interfaces';
import { getConfigBox, uploadFile } from '../utils/middleware';
import { notify } from '../utils/notifications';
import './../css/style.css';
import imgUpload from './../img/upload-cloud-line.svg';
import { Config } from "./../utils/config";


type FormInput = {
    boxTo: string;
    upload: File;
    observation: string;
};


type FileUploaded = {
    wasUploaded: boolean;
    csll?: string;
};

const MAX_CHARACTERS = 1500;
const MAX_FILE_SIZE_IN_BYTES = Config.MAX_FILE_SIZE_IN_BYTES;

const Upload: React.FC = () => {
    const { register, handleSubmit, reset, errors, formState, clearErrors, trigger } = useForm<any>();
    const { isSubmitting } = formState;
    const navigate = useNavigate();
    const [boxData, setBoxData] = useState<any[]>([]);
    const [fileUploaded, setFileUploaded] = useState<FileUploaded>();
    const [boxSelected, setBoxSelected] = useState("");
    const [fileSelected, setFileSelected] = useState<File | null>(null);
    const [user] = useLocalstorageState<IUserData>("user", {});

    const onSubmit = async (data: FormInput) => {
        const response = await uploadFile({
            usuario_origen: user.username!,
            casilla_destino: data.boxTo,
            casilla_origen: user.csll!,
            file: fileSelected!,
            observacion: data.observation
        })

        if (response.isOk) setFileUploaded({ wasUploaded: true, csll: user.csll });
        else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
    };

    const getConfiguration = useCallback(async () => {
        const response = await getConfigBox();
        if (response.isOk) {
            setBoxData(JSON.parse(response.responseData));
        }
        else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
    }, [])

    useEffect(() => {
        getConfiguration();
        if (fileUploaded?.wasUploaded && user.csll !== fileUploaded.csll) {
            window.location.reload();
        }
    }, [getConfiguration, user.csll, fileUploaded])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = (e.target as HTMLInputElement).files![0];
        trigger("upload");
        setFileSelected(file);
    }

    const handleReset = () => {
        setFileSelected(null);
        reset();
    }

    const handleBoxSelected = (boxName: string) => {
        setBoxSelected(boxName);
        clearErrors(["boxTo"]);
    }

    function formatBytes(a: number, b = 2) {
        if (0 === a) return "0 Bytes";
        const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
        return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }


    return (
        <div className='my-5'>
            {/* <Header /> */}
            {fileUploaded ?
                <Container size="sm">
                    <Paper withBorder className='info-header text-center' p="lg" radius="lg" >
                        <MDBIcon far icon="paper-plane" size='7x' className='mb-5'
                            style={{ color: "#18BA95", marginTop: "-50px", backgroundColor: "white" }} />
                        <Grid>
                            <Grid.Col xs={12}>
                                <span>¡Excelente!</span><br />
                                <span>EL archivo adjunto ha sido enviado con éxito.</span><br /><br />
                                <span>Estará disponible durante [{boxData[0]?.timeToLive}] horas para su descarga.</span><br /><br />

                                <MDBBtn
                                    type='button'
                                    color="success"
                                    className='btn-prim-download'
                                    rounded
                                    onClick={() => window.location.reload()}>
                                    <MDBIcon fas icon="paper-plane" className='px-1' />
                                    Quiero enviar otro archivo
                                </MDBBtn>

                                <MDBBtn
                                    type='button'
                                    color="success"
                                    className='mx-4 btn-prim-download'
                                    rounded
                                    outline
                                    onClick={() => navigate("/transmisiones")}>
                                    <MDBIcon fas icon="list-alt" className='px-1' />
                                    Consultar envíos
                                </MDBBtn>
                            </Grid.Col>

                        </Grid>
                    </Paper>
                </Container>
                :
                <div className="files-content">
                    <div className="block bg-white">
                        <div className="form-content">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={`input-content mg-bttn16 ${errors.upload && "error"}`}>
                                    <label htmlFor="upload" className="label-files">
                                        Seleccione un archivo
                                    </label>
                                    <input
                                        ref={register({
                                            required: true,
                                            validate: {
                                                maxFileSize: (files) => files[0]?.size < MAX_FILE_SIZE_IN_BYTES || `Tamaño del archivo supera el máximo. (Tamaño actual: ${formatBytes(files[0]?.size)})`,
                                                minFileSize: (files) => files[0]?.size >= 1 || `Tamaño del archivo debe ser mayor a cero. (Tamaño actual: ${formatBytes(files[0]?.size)})`,
                                            }
                                        })}
                                        type="file"
                                        name="upload"
                                        onChange={onChange}
                                        id="upload" />

                                    <div className="area-files"></div>
                                    {errors.upload && errors.upload.type === "required" && (
                                        <span>Debe seleccionar un archivo</span>
                                    )}
                                    {errors.upload && (
                                        <span>{errors.upload.message}</span>
                                    )}
                                </div>
                                {
                                    fileSelected &&
                                    <table className="table">
                                        <tbody style={{ borderStyle: 'hidden' }}>
                                            <tr>
                                                <td className='message-file-name'>
                                                    {fileSelected.name}
                                                    {!errors.upload &&
                                                        <div className='message-file-name'>
                                                            <h6>
                                                                <MDBBadge color="muted" className='message-file-size'>
                                                                    Tamaño: {formatBytes(fileSelected.size)}
                                                                </MDBBadge>
                                                            </h6>
                                                        </div>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="actions">
                                                        <div className="ico-action">
                                                            <a href="#/" onClick={handleReset} className="eliminar-on" title="Eliminar">&nbsp;</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }

                                <div className="input-content" style={{ display: "flex" }}>
                                    <div className="col-content" style={{ paddingRight: "8px" }}>
                                        <div className="input-content">
                                            <label>Enviado por</label>
                                            <input type="text" name="boxFrom" value={user.csll} disabled />
                                        </div>
                                    </div>

                                    <div className="col-content" style={{ paddingLeft: "8px" }}>
                                        <div className={`input-content ${errors.boxTo && "error"}`}>
                                            <label>Para</label>
                                            <div style={{ marginRight: "0px", lineHeight: "40px" }}>
                                                <input
                                                    type="hidden"
                                                    id="boxTo"
                                                    value={boxSelected}
                                                    name="boxTo"
                                                    ref={register({ required: true })}
                                                />

                                                {/* Para que resulta mas fácil al validación se crea un elemento oculto */}
                                                <MDBDropdown className='dropdown-remove-arrow files-dropdown'>
                                                    <MDBDropdownToggle tag={"div"}>
                                                        {boxSelected || "Seleccione"}
                                                    </MDBDropdownToggle>
                                                    <MDBDropdownMenu className="p-4 text-muted">
                                                        {
                                                            boxData[4]?.map((box: any) =>
                                                                box.habilitado && <MDBDropdownItem
                                                                    key={box.id}
                                                                    style={{ background: box.idCasilla === boxSelected ? "#eee" : "transparent" }}
                                                                >
                                                                    <MDBDropdownLink
                                                                        tag="div"
                                                                        onClick={() => handleBoxSelected(box.idCasilla)}>
                                                                        {box.idCasilla}
                                                                    </MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            )
                                                        }
                                                    </MDBDropdownMenu>
                                                </MDBDropdown>
                                                {errors.boxTo && errors.boxTo.type === "required" && (
                                                    <span>Debe seleccionar un destinatario</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`input-content ${errors.observation && "error"} mt-4`}>
                                    <label style={{ fontSize: 14 }}>Observación </label>
                                    <textarea
                                        onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                        }}
                                        ref={register({
                                            validate: (value) => {
                                                if (value.length > MAX_CHARACTERS) {
                                                    return `Puede agregar hasta ${MAX_CHARACTERS} caracteres (${value.length} agregados)`;
                                                }
                                            }
                                        })}
                                        style={{ resize: 'none' }}
                                        name="observation"
                                        id="observation"
                                    />
                                    {errors.observation && (
                                        <span>{errors.observation.message}</span>
                                    )}
                                </div>


                                <div className='message-file-name'>
                                    Consideraciones: <br />
                                    <ul>
                                        <li>El tamaño del archivo no debe ser superior a {formatBytes(parseInt(MAX_FILE_SIZE_IN_BYTES))}.</li>
                                        <li>El campo observaciones acepta como máximo {MAX_CHARACTERS} caracteres.</li>
                                    </ul>

                                </div>
                                <div className="content-int" style={{ width: "100%" }}>
                                    {isSubmitting ?
                                        <div className='p-3'><Loading /></div>
                                        :
                                        <MDBBtn color='none' type='submit' disabled={isSubmitting} className='btn-prim-index px-5'>
                                            Enviar
                                        </MDBBtn>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="block bg-green-degree">
                        <div className="input-content">
                            <img src={imgUpload} alt={"upload"} />
                        </div>
                    </div>
                </div>
            }
        </div>
        // </div>
    );
}

export default Upload;