import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CardLogoSti from '../components/CardLogoSti';
import { FormInput } from '../components/FormInput';
import InputGoogleAuthenticator from '../components/InputGoogleAutheticator';
import Loading from '../components/Loading';
import { forgotPassword } from '../utils/middleware';
import { notify } from '../utils/notifications';
import './../css/style.css';

type FormInputData = {
    username: string;
    code: string[]
};

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const [successPage, setSuccessPage] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string>("");

    const { handleSubmit, errors, formState: { isSubmitting }, control, setValue } = useForm<FormInputData>({
        defaultValues: {
            username: "",
            code: []
        }
    });

    const onSubmit = async (data: FormInputData) => {
        const { username, code } = data;
        const codeGA = code.join("");

        const response = await forgotPassword(username, codeGA);
		if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede realizar la operación",
                notificationType: "error"
            })
        } else {
			if (!response.isOk) {
				if (!response.notify)
					notify({
						title: "Ha ocurrido un error",
						message: response?.errorMessage ?? "",
						notificationType: "error"
					})
			}
			else {
				const userEmail = response.responseData;
				setUserEmail(userEmail);
				setSuccessPage(true);
			}
		}
    };

    const goToLogin = () => {
        navigate('/');
    }

    return (
        <div className='bg-degree1'>
            <div className="login-content">
                <div className="block bg-white">
                    {successPage
                        ? <div className="title">
                            <MDBIcon far icon="envelope-open" size='5x' className='py-3' />
                            <p />
                            Hemos enviado un mensaje de correo electrónico a:
                            <p />
                            <h6>{userEmail}</h6>
                            <p />
                            Comprueba tu bandeja de entrada y sigue las instrucciones para continuar.
                        </div>
                        : <>
                            <div className="title">
                                <h1>Recupera tu contraseña</h1>
                                <MDBIcon fas icon="unlock" size='2x' />
                            </div>
                            <div className="form-content" style={{ textAlign: 'center' }}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="input-content mg-bttn16">
                                        <label className="txt-bajada pt-4" style={{ marginBottom: "2px" }}>
                                            Ingresa tu nombre de usuario y te enviaremos un enlace con las instrucciones para restablecer la contraseña.
                                        </label>
                                    </div>
                                    <FormInput
                                        useWrapper={false}
                                        errors={errors}
                                        control={control}
                                        placeholder="Nombre de usuario"
                                        name='username'
                                        message='Debe ingresar su usuario'
                                    />
                                    <div className="col-10 pt-1">
                                        <InputGoogleAuthenticator
                                            setValue={setValue}
                                            errors={errors}
                                            control={control} />
                                    </div>

                                    <div className="content-int" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                        <MDBBtn color='none' type='submit' className='btn-prim-index px-5' disabled={isSubmitting}>
                                            {isSubmitting ? <Loading color='#fff' size='sm' /> : <>Continuar</>}
                                        </MDBBtn>
                                        {/* {isSubmitting ?
                                            <div className='pt-3 text-center'><Loading /></div>
                                            :
                                            <MDBBtn color='none' type='submit' className='btn-prim-index px-5'>
                                                Continuar
                                            </MDBBtn>
                                        } */}
                                    </div>
                                    <div className="input-content">
                                        <div className="input-content mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <MDBBtn onClick={goToLogin} color='none' tag={'a'} className='forget'>
                                                Volver
                                            </MDBBtn>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>
                    }
                </div>
                <CardLogoSti />
            </div>
        </div>
    );
}

export default ForgotPassword;