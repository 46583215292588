import dayjs from 'dayjs';
import { Select } from '@mantine/core';
import { Chip } from '@mui/material';
import { MDBCol, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useLocalstorageState } from 'rooks';
import { DataTable } from '../components/DataTable';
import { DirectoriesTable } from '../components/DirectoriesTable';
import Loading from '../components/Loading';
import { IBoxData, IEditUserData, IKeyValueData, IUserData } from '../interfaces';
import { getBoxStatus, getBoxType, getDelimiterInfo, getRegisterFormat, getStatusEnabled, getYesOrNoValue } from '../utils/checkValues';
import { PROFILE_REDBANC, getBoxsByInstitution, getConfigBox, getDirectories, getUsersByBox } from '../utils/middleware';
import { notify } from '../utils/notifications';
import './../css/style.css';

type InstitutionType = {
    id: number;
    nombre: string;
}

type DomainType = {
    id: number;
    descripcion: string;
}

type IPDataType = {
    idCasilla?: string;
    ip: string;
    habilitado: boolean;
    observacion: string;
}

const Box: React.FC = () => {
    const [currentTab, setCurrentTab] = useState("configuration");
    const [loading, setLoading] = useState(false);
    const [boxData, setBoxData] = useState<any[]>([]);
    const [usersData, setUsersData] = useState<any[]>([]);
    const [directoriesData, setDirectoriesData] = useState<any>(null);
    const [user, setUser] = useLocalstorageState<IUserData>("user", {});
    const [boxsValues, setBoxsValues] = useState<IKeyValueData[]>([]);
    const [boxSelected, setBoxSelected] = useState<IKeyValueData | null>(user.csll ? { id: user.csll, nombre: user.csll } : null);

    const getDomain = (code: number) => {
        const domainData = boxData[1];
        const domains: DomainType[] = Array.isArray(domainData) ? domainData : [domainData];
        const domain = domains.find(domain => domain.id === code);
        return domain?.descripcion ?? "Sin información";
    }

    const getInstitution = (code: number) => {
        const institutionData = boxData[2];
        if (!institutionData) return "Sin información";
        const institutions: InstitutionType[] = Array.isArray(institutionData) ? institutionData : [institutionData];
        const institution = institutions.find(inst => inst.id === code);
        return institution?.nombre ?? "Sin información";
    }

    const getUsersData = async (boxid: string) => {
        setLoading(true);
        const response = await getUsersByBox(boxid);
        if (response.isOk) {
            setUsersData(JSON.parse(response.responseData));
        }
        else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        setLoading(false);
        //RMM
        return { isOk: true }
    }

    const getDirectoriesData = async () => {
        setLoading(true);
        const response = await getDirectories();
        if (response.isOk) {
            setDirectoriesData(JSON.parse(response.responseData));
        }
        else {
            if (!response.notify)
                notify({
                    title: "Ha ocurrido un error",
                    message: response.errorMessage ?? "",
                    notificationType: "error"
                })
        }
        setLoading(false);
        //RMM
        return { isOk: true }
    }

    const getConfigurationData = async () => {
        setLoading(true);
        const response = await getConfigBox();
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                setBoxData(JSON.parse(response.responseData));
            }
            else {
                if (!response.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: response.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
        setLoading(false);
        //RMM
        return { isOk: true }
    }

    const setSelectedTab = useCallback(async (csll: string) => {
        const tabs = {
            'configuration': async () => await getConfigurationData(),
            'authorizedBoxes': async () => { await getConfigurationData() },
            'authorizedIPs': async () => { await getConfigurationData() },
            'directories': async () => await getDirectoriesData(),
            'users': async () => await getUsersData(csll),
            'unknown': () => {
                notify({
                    message: "Ha ocurrido un error",
                    notificationType: "error"
                })
            }
        };

        (tabs[currentTab as keyof typeof tabs] || tabs['unknown'])();

    }, [currentTab])

    const handleSelectBox = (boxId: string) => {
        setUser({ ...user, csll: boxId })
        setBoxSelected({ id: boxId, nombre: boxId });
    }

    const getAllBoxsData = useCallback(async () => {
        setLoading(true);
        const response = await getBoxsByInstitution('0');
        const boxList = response.responseData ? JSON.parse(response.responseData) : [];
        const boxsValuesList = boxList.filter((box: IBoxData) => box.tipoCasilla === 'S')
            .map((box: IBoxData) => ({ id: box.idCasilla, nombre: box.idCasilla }))
        setBoxsValues(boxsValuesList);
        setLoading(false);
    }, [])

    useEffect(() => {
        if (user.csll) {
            setSelectedTab(user.csll);
        }
    }, [setSelectedTab, user.csll])

    useEffect(() => {
        if (user.profile === PROFILE_REDBANC) {
            getAllBoxsData();
        }

    }, [getAllBoxsData, user.profile])

    return (
        <div className="bg-white">
            <div className="container-fluid">
                <div className="grilla-content">
                    <div className="title-grilla">
                        <h1>{`Información de Casilla ${user.csll ? ': ' + user.csll.toUpperCase() : ''}`}</h1>
                    </div>
                    {user.profile === PROFILE_REDBANC &&
                        <MDBRow className='my-5 d-flex align-items-end' >
                            <MDBCol size='8'>
                                <Select
                                    clearable
                                    label='Casilla'
                                    radius={8}
                                    onChange={handleSelectBox}
                                    data={boxsValues?.length ? boxsValues.map(i => i.id) : []}
                                    searchable
                                    nothingFound="Sin información"
                                    value={boxSelected?.id}
                                />
                            </MDBCol>
                        </MDBRow>
                    }
                    <div>
                        {user.csll &&
                            <>
                                <MDBTabs className='mb-3'>
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => setCurrentTab('configuration')} active={currentTab === 'configuration'}>
                                            Configuración
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => setCurrentTab('authorizedBoxes')} active={currentTab === 'authorizedBoxes'}>
                                            Casillas Autorizadas
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => setCurrentTab('authorizedIPs')} active={currentTab === 'authorizedIPs'}>
                                            Ips Autorizadas
                                        </MDBTabsLink>
                                    </MDBTabsItem>

                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => setCurrentTab('directories')} active={currentTab === 'directories'}>
                                            Directorios
                                        </MDBTabsLink>
                                    </MDBTabsItem>

                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => setCurrentTab('users')} active={currentTab === 'users'}>
                                            Usuarios
                                        </MDBTabsLink>
                                    </MDBTabsItem>

                                </MDBTabs>
                                {loading ? <Loading /> :
                                    <MDBTabsContent>
                                        <MDBTabsPane show={currentTab === 'configuration'}>
                                            <div className="row">
                                                <div className="col col-details" style={{ minWidth: '300px' }}>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Dominio</div>
                                                        {/* <div className="col file-status">{boxData[1]?.descripcion}</div> */}
                                                        <div className="col file-status">{boxData.length && getDomain(boxData[0]?.idDominio)}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Institución</div>
                                                        <div className="col file-status">{boxData.length && (`${boxData[0]?.idInstitucion} - ${getInstitution(boxData[0]?.idInstitucion)}`)}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Estado</div>
                                                        <div className="col">{getBoxStatus(boxData[0]?.idEstado ?? "")}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Fecha Creación</div>
                                                        <div className="col">
                                                            {
                                                                boxData[0]?.fechaCreacion
                                                                    ? dayjs(boxData[0]?.fechaCreacion).format('DD/MM/YYYY HH:mm:ss')
                                                                    : "Sin información"
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Tipo Casilla</div>
                                                        <div className="col">{getBoxType(boxData[0]?.tipoCasilla ?? "")}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Tiempo de Duración de Archivos</div>
                                                        <div className="col">{boxData[0]?.timeToLive} horas</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Formato Registro</div>
                                                        <div className="col">{getRegisterFormat(boxData[0]?.formatoRegistroEntrada ?? "")}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Delimitador de Archivo</div>
                                                        <div className="col">{getDelimiterInfo(boxData[0]?.delimitadorArchivo ?? "")}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Delimitador de Casilla</div>
                                                        <div className="col">{boxData[0]?.delimitadorCasilla === "S" ? "Si" : "No"}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Migrada</div>
                                                        <div className="col">{boxData[0]?.migrada === "S" ? "Si" : "No"}</div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Flag de Scan</div>
                                                        <div className="col">{getYesOrNoValue(boxData[0]?.scanFile)}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Flag de Debug</div>
                                                        <div className="col">{getYesOrNoValue(boxData[0]?.debug)}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Flag de Encriptación</div>
                                                        <div className="col">{getYesOrNoValue(boxData[0]?.encripta)}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Fecha de Última Actualización</div>
                                                        <div className="col">{
                                                            boxData[0]?.fechaUltimaActualizacion
                                                                ? dayjs(boxData[0]?.fechaUltimaActualizacion).format('DD/MM/YYYY HH:mm:ss')
                                                                : ""
                                                        }</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Fecha de Último Cambio de Clave</div>
                                                        <div className="col">{
                                                            boxData[0]?.fechaUltimaCambioClave
                                                                ? dayjs(boxData[0]?.fechaUltimaCambioClave).format('DD/MM/YYYY HH:mm:ss')
                                                                : ""
                                                        }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col" style={{ minWidth: '250px' }}>Usuario Cambio de Clave</div>
                                                        <div className="col">{boxData[0]?.usuarioUltCambioClave}</div>
                                                    </div>


                                                </div>
                                            </div>
                                            {/* <div className="row text-center">
                                        <div className="content-int">
                                            <a href="home-privada.html" className="btn-prim-download">Editar</a>
                                        </div>
                                    </div> */}

                                        </MDBTabsPane>
                                        <MDBTabsPane show={currentTab === 'authorizedBoxes'}>
                                            <table className="table" style={{ width: "710px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Casilla Origen</th>
                                                        <th scope="col">Estado</th>
                                                        {/* <th scope="col">Acciones</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        boxData[3]?.map((box: any, index: number) => {
                                                            return <tr key={index}>
                                                                <th scope="row">{box.idCasillaAutorizada}</th>
                                                                <td>{box.habilitado ? "Habilitado" : "Deshabilitado"}</td>
                                                            </tr>
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </MDBTabsPane>
                                        <MDBTabsPane show={currentTab === 'authorizedIPs'}>
                                            <div className="row">
                                                <div className="col">
                                                    <table className="table" style={{ width: "710px" }}>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Ip Autorizada</th>
                                                                <th scope="col">Estado</th>
                                                                {/* <th scope="col">Acciones</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                boxData[5]?.map((ipData: IPDataType, index: number) => {
                                                                    return <tr key={index}>
                                                                        <th scope="row">{ipData.ip}</th>
                                                                        <td>
                                                                            {ipData.habilitado ? "Habilitada" : "Deshabilitada"}
                                                                        </td>
                                                                    </tr>
                                                                })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </MDBTabsPane>
                                        <MDBTabsPane show={currentTab === 'directories'}>
                                            <div className="row">
                                                <p />
                                                <DirectoriesTable title={"/Buzon"} directoriesData={directoriesData?.archivosBuzon} />
                                                <DirectoriesTable title={"/Salida"} directoriesData={directoriesData?.archivosSalida} />
                                            </div>
                                        </MDBTabsPane>
                                        {currentTab === 'users' &&
                                            <div className="text-center">
                                                <DataTable
                                                    rows={usersData.map((user: IEditUserData) => {
                                                        const estado = getStatusEnabled(user.habilitado!.toString());
                                                        const fechaActivacion = user.fechaActivacion && dayjs(user.fechaActivacion).format('DD/MM/YYYY HH:mm:ss')
                                                        const fechaDesactivacion = user.fechaDesactivacion && user.habilitado === 0 ? dayjs(user.fechaDesactivacion).format('DD/MM/YYYY HH:mm:ss') : ""

                                                        return { ...user, id: user.userName, estado, fechaDesactivacion, fechaActivacion }
                                                    })}
                                                    columns={[
                                                        {
                                                            field: 'nombre', headerName: 'Nombre Usuario', minWidth: 300
                                                        },
                                                        {
                                                            field: 'userName',
                                                            headerName: 'Username',
                                                            minWidth: 300
                                                        },

                                                        { field: 'fechaActivacion', headerName: 'Fecha Activación', minWidth: 200 },
                                                        {
                                                            field: 'estado', align: "center", headerAlign: 'center', headerName: 'Estado', minWidth: 150,
                                                            renderCell: (params) => {
                                                                const estado = params.row.estado;
                                                                return (
                                                                    <>
                                                                        <Chip
                                                                            size='small'
                                                                            label={estado}
                                                                            color={
                                                                                params.row.habilitado === 1
                                                                                    ? 'success'
                                                                                    : params.row.habilitado === 0
                                                                                        ? 'default'
                                                                                        : 'warning'} variant="outlined" />
                                                                    </>
                                                                );
                                                            }


                                                        },
                                                        { field: 'fechaDesactivacion', headerName: 'Fecha Desactivación', minWidth: 200 },
                                                        { field: 'perfil', headerName: 'Perfil', minWidth: 150 },


                                                    ]} />
                                            </div>
                                        }
                                    </MDBTabsContent>
                                }
                            </>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Box;