const getEnv = (name: string, dflt?: string | undefined): string => {
	const fullname = `REACT_APP_${name}`;
	const value = process.env[fullname] || dflt;
	if (value === undefined) throw new Error(`Environment variable '${fullname} must be defined`);
	
	return value;
};

export class Config {
	public static readonly API_URL_USUARIO: string = getEnv("API_URL_USUARIO");
	public static readonly API_URL_TRANSMISION: string = getEnv("API_URL_TRANSMISION");
	public static readonly API_URL_CONFIGURATION: string = getEnv("API_URL_CONFIGURATION");
	public static readonly API_URL_ADMIN: string = getEnv("API_URL_ADMIN");
	public static readonly API_URL_PUBLIC_KEY: string = getEnv("API_URL_PUBLIC_KEY");
	public static readonly MAX_FILE_SIZE_IN_BYTES: string = getEnv("MAX_FILE_SIZE_IN_BYTES", "1073741824");
	public static readonly MAX_USER_INACTIVITY_TIME_IN_SECONDS: string = getEnv("MAX_USER_INACTIVITY_TIME_IN_SECONDS", "180000");
	public static readonly TIME_MODAL_DURATION_TIME_BEFORE_LOGOUT: string = getEnv("TIME_MODAL_DURATION_TIME_BEFORE_LOGOUT", "20000");
	public static readonly CAPTCHA_SITE_KEY: string = getEnv("CAPTCHA_SITE_KEY");
    public static readonly PROHIBITED_WORDS_IN_PASS: string = getEnv("PROHIBITED_WORDS_IN_PASS");
    public static readonly PROHIBITED_WORDS_IN_EMAIL: string = getEnv("PROHIBITED_WORDS_IN_EMAIL");
	public static readonly USE_CAPTCHA: string = getEnv("USE_CAPTCHA");
	public static readonly ENCRYPT_SECRET_KEY: string = getEnv("ENCRYPT_SECRET_KEY");
}

