import { Box, Container, Grid, Paper } from '@mantine/core';
import PasswordIcon from '@mui/icons-material/Password';
import KeyIcon from '@mui/icons-material/Key';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useCallback, useEffect, useState } from 'react';
import { useLocalstorageState } from 'rooks';
import { DataTable } from '../components/DataTable';
import Loading from '../components/Loading';
import { ModalFormKeys } from '../components/ModalFormKeys';
import { ModalFormPassword } from '../components/ModalFormPassword';
import { IAutorizedBoxData, IBoxData, IModalForm, IUserData } from '../interfaces';
import { getStatusEnabled } from '../utils/checkValues';
import { getBoxsByUser } from '../utils/middleware';
import { notify } from '../utils/notifications';
import { ModalFormToken } from '../components/ModalFormToken';

export const Security: React.FC = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [user] = useLocalstorageState<IUserData>("user", {});
    const [userBoxs, setUserBoxs] = useState<IAutorizedBoxData[]>([]);
    const [modalForm, setModalForm] = useState<IModalForm | null>();


    const openModal = (data: any, type: string) => {
        const modalProps: IModalForm = {
            show: true,
            type,
            data,
            subTitle: <>
                {type !== 'token' &&
                    <Container size={'xs'} >
                        <Paper withBorder className='info-header mb-5' p="xs" radius="lg" >
                            <Grid>
                                <Grid.Col xs={1}><b>Casilla</b></Grid.Col>
                                <Grid.Col xs={6}>{data.idCasilla}</Grid.Col>
                            </Grid>
                        </Paper>
                    </Container>
                }</>,
            title: type === 'password' ? "Actualizar Contraseña" : type === 'token' ? `Token Acceso - Casilla ${data.idCasilla}` : "Lista de llaves"
        }

        setModalForm(modalProps);
    }

    const handleSubmit = () => {
        setModalForm(null)
        getUserBoxData();
    }

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));


    const getUserBoxData = useCallback(async () => {
        setLoading(true);
        const response = await getBoxsByUser(user.username!);
        if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede acceder al Servicio",
                notificationType: "error"
            })
        }
        else {
            if (response.isOk) {
                setUserBoxs(response?.responseData ? JSON.parse(response?.responseData) : []);

            } else {
                if (!response.notify)
                    notify({
                        title: "Ha ocurrido un error",
                        message: response.errorMessage ?? "",
                        notificationType: "error"
                    })
            }
        }
        setLoading(false);

    }, [user.username])

    useEffect(() => {
        getUserBoxData();
    }, [getUserBoxData])

    return (
        <div className="bg-white">
            <div className="container-fluid">
                {/* <Header /> */}
                {
                    <div className="grilla-content">
                        <div className="title-grilla">
                            <h1>Actualización de Contraseñas</h1>
                        </div>

                        {modalForm && (
                            modalForm.type === 'password'
                                ? <ModalFormPassword
                                    title={modalForm.title}
                                    subTitle={modalForm.subTitle}
                                    showConfirm={modalForm.show}
                                    onClose={() => setModalForm(null)}
                                    onSubmit={handleSubmit}
                                    data={modalForm.data}
                                />
                                :
                                modalForm.type === 'token'
                                    ? <ModalFormToken
                                        title={modalForm.title}
                                        showConfirm={modalForm.show}
                                        onClose={() => setModalForm(null)}
                                        onSubmit={() => getUserBoxData()}
                                        data={modalForm.data}
                                    />
                                    :
                                    <ModalFormKeys
                                        title={modalForm.title}
                                        subTitle={modalForm.subTitle}
                                        showConfirm={modalForm.show}
                                        onClose={handleSubmit}
                                        data={modalForm.data}
                                    />
                        )}


                        <div className="text-center">
                            {loading
                                ? <Loading />
                                : <DataTable
                                    rows={userBoxs.map((userBox: IAutorizedBoxData, index: number) => {
                                        const datosCasilla: IBoxData = userBox === null ? null : userBox.datos_casilla ? JSON.parse(userBox.datos_casilla.toString()) : null;
                                        if (datosCasilla !== null) {
                                            const estado = getStatusEnabled(datosCasilla?.idEstado.toString());
                                            const fechaCreacion = datosCasilla?.fechaCreacion && dayjs(datosCasilla?.fechaCreacion).format('DD/MM/YYYY HH:mm:ss')
                                            const fechaUltimoCambioClave = datosCasilla?.fechaUltimaCambioClave && dayjs(datosCasilla?.fechaUltimaCambioClave).format('DD/MM/YYYY HH:mm:ss')

                                            return { ...userBox, id: userBox.idCasilla, fechaCreacion, fechaUltimoCambioClave, estado }
                                        }
                                        return { id: index }
                                    })}
                                    columns={[
                                        {
                                            field: 'idCasilla',
                                            headerName: 'Id Casilla',
                                            minWidth: 300
                                        },
                                        {
                                            field: 'fechaCreacion',
                                            headerName: 'Fecha creación',
                                            minWidth: 250
                                        },
                                        {
                                            field: 'fechaUltimoCambioClave',
                                            headerName: 'Fecha actualización clave',
                                            minWidth: 250
                                        },
                                        {
                                            field: 'estado',
                                            headerName: 'Estado',
                                            minWidth: 150
                                        },
                                        {
                                            field: 'cantidad_llaves',
                                            headerName: 'Cantidad llaves',
                                            minWidth: 150,
                                            align: "center"
                                        },
                                        {
                                            field: 'acciones',
                                            align: "center",
                                            headerAlign: 'center',
                                            sortable: false,
                                            headerName: 'Acciones',
                                            minWidth: 200,
                                            renderCell: (params) => {
                                                const actionsEnabled = params.row.estado === getStatusEnabled("1");
                                                return (
                                                    <>
                                                        <Box className='px-1'>
                                                            <LightTooltip title="Ver llaves" placement="bottom">
                                                                <span>
                                                                    <IconButton
                                                                        sx={{
                                                                            border: `1px solid ${actionsEnabled ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.26)'}`,
                                                                            color: theme.palette.primary.main,
                                                                            bgcolor: theme.palette.common.white
                                                                        }}
                                                                        aria-label="keys"
                                                                        size="small"
                                                                        disabled={!actionsEnabled}
                                                                        onClick={() => actionsEnabled ? openModal(params.row, 'keys') : false}
                                                                    >
                                                                        <KeyIcon />
                                                                    </IconButton>
                                                                </span>
                                                            </LightTooltip>
                                                        </Box>
                                                        <Box className='px-1'>
                                                            <LightTooltip title="Actualizar password" placement="bottom">
                                                                <span>
                                                                    <IconButton
                                                                        sx={{
                                                                            border: `1px solid ${actionsEnabled ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.26)'}`,
                                                                            color: theme.palette.primary.main,
                                                                            bgcolor: theme.palette.common.white
                                                                        }}
                                                                        aria-label="update password"
                                                                        size="small"
                                                                        disabled={!actionsEnabled}
                                                                        onClick={() => actionsEnabled ? openModal(params.row, 'password') : false}
                                                                    >
                                                                        <SyncAltIcon />
                                                                    </IconButton>
                                                                </span>
                                                            </LightTooltip>
                                                        </Box>
                                                        <Box className='px-1'>
                                                            <LightTooltip title="Ver token" placement="bottom">
                                                                <span>
                                                                    <IconButton
                                                                        sx={{
                                                                            border: `1px solid ${actionsEnabled ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.26)'}`,
                                                                            color: theme.palette.primary.main,
                                                                            bgcolor: theme.palette.common.white
                                                                        }}
                                                                        aria-label="update password"
                                                                        size="small"
                                                                        disabled={!actionsEnabled}
                                                                        onClick={() => actionsEnabled ? openModal(params.row, 'token') : false}
                                                                    >
                                                                        <PasswordIcon />
                                                                    </IconButton>
                                                                </span>
                                                            </LightTooltip>
                                                        </Box>
                                                    </>
                                                );
                                            }
                                        },
                                    ]} />
                            }
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}