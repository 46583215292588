import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Navigate, useLocation } from 'react-router';
import { useLocalstorageState } from 'rooks';
import Header from './components/Header';
import { ModalConfirm } from './components/ModalConfirm';
import useIsMounted from './hooks/useIsMounted';
import { IUserData } from './interfaces';
import { Config } from './utils/config';
import { getUserAuthorizedPages, IPage, logout } from './utils/middleware';

const UPDATE_PASSWORD_PAGE = "/update-user-password";

export function PrivateRoute({
    children,
}: { children: JSX.Element }) {

    const [user] = useLocalstorageState<IUserData>("user", {});
    let location = useLocation();
    const isMounted = useIsMounted()
    // const userAuthorizedPages = user.userAuthorizedPages;
    const [userAuthorizedPages, setUserAuthorizedPages] = useState<IPage[]>();

    const timeout = +Config.MAX_USER_INACTIVITY_TIME_IN_SECONDS;
    const promptBeforeIdle = +Config.TIME_MODAL_DURATION_TIME_BEFORE_LOGOUT;

    // Modal open state
    const [open, setOpen] = useState(false)

    // Time before idle
    const [remaining, setRemaining] = useState(promptBeforeIdle)

    const onPrompt = () => {
        setOpen(true)
    }

    const onIdle = async () => {
        await logout();
        // navigate("/");
        window.location.replace("/");
    }
    const onAction = async () => {
        if (!open)
            handleStillHere();
    }

    const { reset, getRemainingTime } = useIdleTimer({
        onPrompt,
        onAction,
        onIdle,
        timeout,
        promptBeforeIdle,
    })

    const handleStillHere = () => {
        setOpen(false)
        setRemaining(Math.round(getRemainingTime() / 1000))
        reset();
    }
    const handleLogout = async () => {
        await logout();
        // navigate("/");
        window.location.replace("/");
    };


    useEffect(() => {
        let timeoutId: any;
        if (open) {
            timeoutId = setTimeout(
                () => {
                    isMounted() && setRemaining(Math.round(getRemainingTime() / 1000));
                },
                1000,
            );
        }

        return () => {
            timeoutId && clearTimeout(timeoutId);
        }

    }, [open, remaining, isMounted, getRemainingTime]);

    useEffect(() => {
        const pages = getUserAuthorizedPages(user);
        const orderPages = pages.sort((a, b) => Number(b.orden) - Number(a.orden)).map((page: IPage) => page);
        setUserAuthorizedPages(orderPages)

    }, [location.pathname, user])


    if (userAuthorizedPages?.length) {
        let currentPath = location.pathname;
        if (currentPath.includes("editar") || currentPath.includes("crear")) {
            currentPath = currentPath.split("/").slice(0, -1).join("/");
        }

        if (userAuthorizedPages.some((page: IPage) => `/${page.pag}` === currentPath || currentPath === UPDATE_PASSWORD_PAGE)) {
            return <>
                <Header userAuthorizedPages={userAuthorizedPages} />

                <ModalConfirm
                    onClose={handleLogout}
                    title="Aviso de cierre de sesión"
                    body={<>
                        Su sesión está a punto de expirar, le quedan {Math.round(getRemainingTime() / 1000)} segundos.
                        <p />
                        ¿Necesita más tiempo?
                    </>}
                    showConfirm={open}
                    onConfirm={handleStillHere}
                    confirmButtonName={"Mantener sesión activa"}
                    cancelButtonName={"Cerrar sesión"}
                    zIndex={9000}
                />

                {children}
            </>
        }
        else {
            return <Navigate to="/unauthorized" state={{ from: location }} replace />;
        }
    }

    // return <Navigate to="/" state={{ from: location }} replace />;
    return <></>
}