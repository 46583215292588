import { Grid } from '@mui/material';
import 'dayjs/locale/es';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocalstorageState } from 'rooks';
import { FormInput, PASSWORD_RULES } from '../components/FormInput';
import InputGoogleAuthenticator from '../components/InputGoogleAutheticator';
import Loading from '../components/Loading';
import { IUserData } from '../interfaces';
import { createPasswordRules, REGEX, validateUserOnText } from '../utils/functions';
import { getUserAuthorizedPages, updateUserPassword } from '../utils/middleware';
import { notify } from '../utils/notifications';

type FormInputData = {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
    code: string[];
};

const UpdatePassword: React.FC = () => {
    const navigate = useNavigate();
    const [user] = useLocalstorageState<IUserData>("user", {});
    const [errorCount, setErrorCount] = useState(0);

    const { handleSubmit, errors, formState: { isSubmitting }, control, watch, setValue } = useForm({
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            code: []
        }
    });

    const onSubmit = async (data: FormInputData) => {
        const { currentPassword, newPassword } = data;
        const code = watch('code').join("");

        const response = await updateUserPassword(user.username!, currentPassword, newPassword, code);
		if (response === null) {
            notify({
                title: "Ha ocurrido un error",
                message: "No se puede realizar la operación",
                notificationType: "error"
            })
        } else {
			if (!response.isOk) {
                if (response.responseStatus === 510) {
                    navigate("/");
                    return;
                }
				if (response.responseStatus === 404) {
					setErrorCount(errorCount + 1);
				}
				if (!response.notify)
					notify({
						title: "Ha ocurrido un error",
						message: response?.errorMessage ?? "",
						notificationType: "error"
					})
			}
			else {
				notify({
					message: `Contraseña actualizada correctamente.`,
					autoClose: 3000,
				});

				navigateToDefaultPath();
			}
		}
    };

    const navigateToDefaultPath = useCallback(() => {
        const pages = getUserAuthorizedPages(user);
        const path = pages?.length ? `/${pages[0].pag}` : "/";
        navigate(path);
    }, [navigate, user])

    useEffect(() => {
        if (errorCount >= 3) navigateToDefaultPath();
    }, [errorCount, navigateToDefaultPath]);


    return (

        <div className="bg-white">
            <div className="container-fluid">
                <Grid className="grilla-content" sx={{ width: '20%' }}>
                    <div className="title-grilla">
                        <h1>Actualización de contraseña</h1>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container item xs={12}>
                            <FormInput
                                usePasswordPopover={false}
                                useWrapper={false}
                                size={4}
                                errors={errors}
                                control={control}
                                name='currentPassword'
                                message='Contraseña es requerida'
                                placeholder='Contraseña actual'
                                type='password'
                                maxLength={16}
                            />
                            <FormInput
                                useWrapper={false}
                                size={4}
                                errors={errors}
                                control={control}
                                name='newPassword'
                                message='Contraseña es requerida'
                                placeholder='Contraseña nueva'
                                type='password'
                                maxLength={16}
                                extraPasswordRules={PASSWORD_RULES}
                                rules={{
                                    validate: {
                                        ...createPasswordRules(PASSWORD_RULES),
                                        words: (value) => REGEX.test(value) || 'Existen palabras no permitidas',
                                        username: validateUserOnText(user.username!),
                                    }
                                }}
                            />
                            <FormInput
                                useWrapper={false}
                                size={4}
                                errors={errors}
                                control={control}
                                name='newPasswordConfirm'
                                message='Repetir Contraseña es requerida'
                                placeholder='Repetir Contraseña nueva'
                                type='password'
                                maxLength={16}
                                extraPasswordRules={PASSWORD_RULES}
                                rules={{
                                    validate: {
                                        ...createPasswordRules(PASSWORD_RULES),
                                        words: (value) => REGEX.test(value) || 'Existen palabras no permitidas',
                                        username: validateUserOnText(user.username!),
                                        samePass: value => watch('newPassword') === value || "Las contraseñas deben ser iguales"
                                    }
                                }}
                            />

                            <div className="col-10 pt-3">
                                <InputGoogleAuthenticator
                                    setValue={setValue}
                                    errors={errors}
                                    control={control} />
                            </div>

                            <MDBBtn
                                color='success'
                                style={{ display: 'inline' }}
                                className='px-5 my-5 btn-prim-download'
                                rounded
                                type='submit'
                                disabled={isSubmitting}>
                                {isSubmitting ? <Loading color='#fff' size='sm' /> : <><MDBIcon fas icon="check" /> Aceptar</>}
                            </MDBBtn>
                        </Grid>

                    </form>
                </Grid>
            </div>
        </div>
    );
}

export default UpdatePassword;